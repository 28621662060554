import { TextArea } from "@blueprintjs/core";
import moment from "moment";
//@ts-ignore
import trLocale from "moment/locale/tr";

import { useEffect, useState } from "react";
import ts from "typescript";
import { PageHider } from "../components/PageHider";
import { WeeklyPage } from "../components/WeeklyPage";
import {
  query,
  collection,
  where,
  limit,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { fireStore } from "../../../../firebase";
import { beforePreviousFridayNight } from "../util";
import { useChartDateContext } from "../chart-date-context";
const RESISTANCES = [
  0.24, 0.8, 1.2, 2, 2.4, 3.3, 3.96, 4.5, 5.2, 5.8, 6.2, 6.65, 7, 7.4, 7.8, 8.3,
  9,
];
export const Page2 = ({
  onPageDataUpdated,
  weekData,
  pageIndex,
  subIndex,
  registerPageIndex,
  details,
}: any) => {
  const [comment, setComment] = useState(weekData?.page2?.comment ?? "");
  const [bistData, setBistData] = useState<any>(null);
  const [xu30Data, setXu30Data] = useState<any>(null);
  const [closestResistance, setClosestResistance] = useState<any>(
    weekData?.page2?.resistances ?? [null, null, null, null]
  );
  const [viop30Value, setViop30Value] = useState<any>(
    weekData?.page2?.viop30 ?? ""
  );
  const { chartDate } = useChartDateContext();

  useEffect(() => {
    const previousFriday = beforePreviousFridayNight(chartDate);
    const q = query(
      collection(fireStore, "DS1"),
      where("dateTime", "<=", previousFriday.toDate()),
      orderBy("dateTime", "desc"),
      limit(20)
    );

    getDocs(q).then((querySnapshot: any) => {
      const list: any = [];
      const firstDate = moment();
      querySnapshot.forEach((doc: any) => {
        const date = moment(doc.data().dateTime.toDate());
        if (date.isBefore(firstDate)) {
          const dateIndex = date.days();
          if (
            dateIndex === 5 ||
            (chartDate && moment(chartDate).days() == date.days())
          )
            list.push(doc.data());
        }
      });
      setBistData(list);
    });
  }, [chartDate]);
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Sorumluluk Beyanı");
  }, []);

  useEffect(() => {
    const previousFriday = beforePreviousFridayNight(chartDate);

    const q = query(
      collection(fireStore, "NS_XU030_DAILY"),
      orderBy("dateTime", "desc"),
      where("dateTime", "<=", previousFriday.toDate()),

      limit(20)
    );

    getDocs(q).then((querySnapshot: any) => {
      const list: any = [];
      const firstDate = moment();
      querySnapshot.forEach((doc: any) => {
        const date = moment(doc.data().dateTime.toDate());
        if (date.isBefore(firstDate)) {
          const dateIndex = date.days();

          if (dateIndex === 5) {
            list.push(doc.data().close);
          }
        }
      });
      setXu30Data(list);
    });
  }, [chartDate]);

  useEffect(() => {
    if (!bistData) return;
    if (closestResistance.filter((item: any) => item !== null).length > 0)
      return;
    const list: any = [];
    RESISTANCES.forEach((item) => {
      list.push({
        diff: Math.abs(bistData?.[0]?.sentiment - item),
        resistance: item,
      });
    });
    list.sort((a: any, b: any) => {
      return a.diff - b.diff;
    });

    setClosestResistance(
      list
        .slice(0, 4)
        .sort((a: any, b: any) => {
          return b.resistance - a.resistance;
        })
        .map((item: any) => item.resistance)
    );
  }, [bistData]);

  return (
    <WeeklyPage pageId={`page-${pageIndex}-${subIndex}`}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ width: "57%", paddingTop: 40 }}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 40,
              color: "#fff",
              textAlign: "center",
              paddingBottom: 25,
            }}
          >
            SORUMLULUK BEYANI
          </div>
          <div
            style={{
              color: "#fff",
              textAlign: "justify",
              padding: 15,
              lineHeight: "23px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            Bu raporda yer alan her türlü bilgi, değerlendirme, yorum,
            istatistiki şekil ve bilgiler hazırlandığı tarih itibari ile mevcut
            piyasa koşulları ve güvenirliğine inanılan kaynaklardan Sentiment
            Algo sistemi verileri elde edilerek derlenmiştir ve Zephlex Bilgi
            Teknolojileri, Eğitim ve Danışmanlık A.Ş tarafından genel
            bilgilendirme amacı ile hazırlanmıştır. Sunulan bilgilerin doğruluğu
            ve bunların yatırım kararlarına uygunluğu tarafımızca garanti
            edilmemektedir. Bu bilgiler belli bir getirinin sağlanmasına yönelik
            olarak verilmemekte olup alım-satım kararını destekleyebilecek
            yeterli bilgiler burada bulunmayabilir. Herhangi bir yatırım
            aracının alım-satım önerisi ya da getiri vaadi olarak
            yorumlanmamalıdır. Bu görüşler mali durumunuz ile risk ve getiri
            tercihlerinize uygun olmayabilir. Sadece burada yer alan bilgilere
            dayanarak yatırım kararı verilmesi beklentilerinize uygun sonuçlar
            doğurmayabilir. Bu nedenle bu sayfalarda yer alan bilgilerdeki
            hatalardan eksikliklerden ya da bu bilgilere dayanılarak yapılan
            işlemlerden, yorum ve bilgilerin kullanılmasından doğacak her türlü
            maddi/manevi zararlardan ve her ne şekilde olursa olsun üçüncü
            kişilerin uğrayabileceği her türlü doğrudan ve/veya dolaylı
            zararlardan dolayı Zephlex Bilgi Teknolojileri, Eğitim ve
            Danışmanlık A.Ş ile bağlı kuruluşları, çalışanları, yöneticileri ve
            ortakları sorumlu tutulamaz. Burada yer alan yatırım bilgi, yorum ve
            tavsiyeleri yatırım danışmanlığı kapsamında değildir.
          </div>
        </div>
        <div
          style={{
            backgroundColor: "#fff",
            flex: 1,
            height: "100%",
            position: "relative",
            padding: 30,
            display: "flex",
            flexDirection: "column",
            paddingBottom: 0,
          }}
        >
          <div>
            <div
              style={{
                color: "rgb(15,82,156)",
                fontWeight: "bold",
                fontSize: "27px",
                textAlign: "center",
              }}
            >
              HAFTALIK DEĞERLENDİRME
            </div>
            <div>
              <table style={{ fontSize: 16 }} className="weekly-comment-table">
                <tr className="upper-border lower-border">
                  <td>Tarih</td>
                  <td>Sentiment</td>
                  <td>Günlük Sentiment</td>
                  <td>Bist 100</td>
                </tr>
                <tr>
                  <td>
                    {moment(bistData?.[0].dateTime.toDate()).format("DD/MM")}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                        justifyContent: "flex-end",
                      }}
                    >
                      {Math.abs(
                        bistData?.[0].sentiment - bistData?.[1].sentiment
                      ) > 0.009 && (
                        <img
                          height="30"
                          src={
                            bistData?.[0].sentiment < bistData?.[1].sentiment
                              ? "/images/down_arrow.png"
                              : bistData?.[0].sentiment >
                                bistData?.[1].sentiment
                              ? "/images/up_arrow.png"
                              : ""
                          }
                        />
                      )}
                      {bistData?.[0].sentiment.toFixed(2)}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                        justifyContent: "center",
                      }}
                    >
                      {Math.abs(
                        bistData?.[0].sentiment2 - bistData?.[1].sentiment2
                      ) > 0.009 && (
                        <img
                          height="30"
                          src={
                            bistData?.[0].sentiment2 < bistData?.[1].sentiment2
                              ? "/images/down_arrow.png"
                              : bistData?.[0].sentiment2 >
                                bistData?.[1].sentiment2
                              ? "/images/up_arrow.png"
                              : ""
                          }
                        />
                      )}
                      {bistData?.[0].sentiment2.toFixed(1)}/
                      {bistData?.[0].momentum.toFixed(0)}
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 3,
                        justifyContent: "flex-end",
                      }}
                    >
                      {Math.abs(
                        bistData?.[0].index_value - bistData?.[1].index_value
                      ) > 0.009 && (
                        <img
                          height="30"
                          src={
                            bistData?.[0].index_value <
                            bistData?.[1].index_value
                              ? "/images/down_arrow.png"
                              : bistData?.[0].index_value >
                                bistData?.[1].index_value
                              ? "/images/up_arrow.png"
                              : ""
                          }
                        />
                      )}
                      {bistData?.[0].index_value.toFixed(2)}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div style={{ display: "flex" }}>
                      {moment(bistData?.[1].dateTime.toDate()).format("DD/MM")}
                    </div>
                  </td>
                  <td>
                    {" "}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {bistData?.[1].sentiment.toFixed(2)}
                    </div>
                  </td>
                  <td>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {bistData?.[1].sentiment2.toFixed(1)}/
                      {bistData?.[0].momentum.toFixed(0)}
                    </div>
                  </td>
                  <td>
                    {" "}
                    <div
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      {bistData?.[1].index_value.toFixed(2)}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <div>
              <div style={{ display: "flex", gap: "10px" }}>
                <table className="weekly-bist-table">
                  <tr className="upper-border">
                    <td>
                      BİST 100 (
                      {moment(bistData?.[0].dateTime.toDate()).format("DD/MM")})
                    </td>
                    <td>{bistData?.[0].index_value.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      Önceki Hafta (
                      {moment(bistData?.[1].dateTime.toDate()).format("DD/MM")})
                    </td>
                    <td>{bistData?.[1].index_value.toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Haftalık Değişim %</td>
                    <td>
                      %
                      {(
                        ((bistData?.[0].index_value.toFixed(2) -
                          bistData?.[1].index_value.toFixed(2)) *
                          100) /
                        bistData?.[1].index_value.toFixed(2)
                      ).toFixed(2)}
                    </td>
                  </tr>

                  <tr>
                    <td>
                      BİST 30 (
                      {moment(bistData?.[0].dateTime.toDate()).format("DD/MM")})
                    </td>
                    <td>{xu30Data?.[0].toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>
                      Önceki Hafta (
                      {moment(bistData?.[1].dateTime.toDate()).format("DD/MM")})
                    </td>
                    <td>{xu30Data?.[1].toFixed(2)}</td>
                  </tr>
                  <tr>
                    <td>Haftalık Değişim %</td>
                    <td>
                      {" "}
                      %
                      {(
                        ((xu30Data?.[0].toFixed(2) - xu30Data?.[1].toFixed(2)) *
                          100) /
                        xu30Data?.[1].toFixed(2)
                      ).toFixed(2)}
                    </td>
                  </tr>
                  <tr className="lower-border">
                    <td>XU030 Viop</td>
                    <td>
                      <input
                        className="no-print"
                        defaultValue={viop30Value}
                        onChange={(e) => {
                          onPageDataUpdated("page2", "viop30", e.target.value);
                          setViop30Value(e.target.value);
                        }}
                      ></input>
                      <span className="print-only-inline-block">
                        {viop30Value}
                      </span>
                    </td>
                  </tr>
                </table>
                <table className="comment-support-table">
                  <tr style={{ fontWeight: "bold" }}>
                    {" "}
                    <td>SENTİMENT ÖNEMLİ DESTEK DİRENÇ</td>{" "}
                  </tr>

                  {closestResistance?.map((res: number, index: number) => {
                    return (
                      <tr
                        className={
                          index == 0
                            ? "upper-border"
                            : index === closestResistance.length - 1
                            ? "lower-border"
                            : ""
                        }
                      >
                        <td>
                          <input
                            style={{ width: "70px" }}
                            className="no-print"
                            value={res}
                            onChange={(e) => {
                              closestResistance[index] = e.target.value;
                              setClosestResistance([...closestResistance]);
                              onPageDataUpdated(
                                "page2",
                                "resistances",
                                closestResistance
                              );
                            }}
                          ></input>
                          <span className="print-only-inline-block">{res}</span>
                        </td>
                      </tr>
                    );
                  })}
                </table>
              </div>
            </div>
          </div>
          <TextArea
            className="no-print"
            style={{ height: "50%", flex: 1 }}
            placeholder="Haftalık yorum"
            defaultValue={comment}
            onChange={(e) => {
              onPageDataUpdated("page2", "comment", e.target.value);
              setComment(e.target.value);
            }}
          ></TextArea>
          <div
            className="print-only-block"
            style={{
              border: "2px solid rgb(12,160,189)",
              padding: 10,
            }}
          >
            <b>Yorum:</b>{" "}
            {comment.split("\n").map((item: any, index: number) => (
              <div style={{ display: index === 0 ? "inline" : "block" }}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    </WeeklyPage>
  );
};
