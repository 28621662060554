import { useEffect, useState } from "react";
import { WeeklyPage } from "../components/WeeklyPage";
import {
  query,
  collection,
  where,
  limit,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { fireStore } from "../../../../firebase";
import moment from "moment";
import { TextArea } from "@blueprintjs/core";
import { previousFridayRange } from "../util";
import { useChartDateContext } from "../chart-date-context";

export const PageStockEnter = ({
  list,
  data,
  pageIndex,
  subIndex,
  registerPageIndex,
  details,
  save_key,
  weekData,
  onPageDataUpdated,
}: any) => {
  const [header, setHeader] = useState<any>(weekData?.[save_key]?.header ?? "");
  const [exitPerformans, setExitPerformans] = useState<any>({});
  const { chartDate } = useChartDateContext();

  const getLasFridayForStock = (stock: string) => {
    const { previousFriday, range } = previousFridayRange(chartDate);

    const q = query(
      collection(fireStore, "NS_LINES_" + list + "_1W_P99"),
      orderBy("dateTime", "desc"),
      where("dateTime", ">", previousFriday.toDate()),
      where("dateTime", "<", range.toDate()),
      where("symbol", "==", stock),
      limit(1)
    );

    return getDocs(q).then((querySnapshot: any) => {
      const doc = querySnapshot.docs[0];

      return doc.data().price;
    });
  };
  const getPreviousFridayForStock = (stock: string) => {
    const { previousFriday, range } = previousFridayRange(chartDate);

    const q = query(
      collection(fireStore, "NS_LINES_" + list + "_1W_P99"),
      orderBy("dateTime", "desc"),
      where("dateTime", ">", previousFriday.toDate()),
      where("dateTime", "<", range.toDate()),
      where("symbol", "==", stock),
      limit(1)
    );

    return getDocs(q).then((querySnapshot: any) => {
      const doc = querySnapshot.docs[0];

      return doc.data().price;
    });
  };
  const calcExitPerf = async (stock: string) => {
    const lastPrice = await getLasFridayForStock(stock);
    const previousPrice = await getPreviousFridayForStock(stock);
    return (lastPrice - previousPrice) / previousPrice;
  };

  useEffect(() => {
    if (details && data) {
      data?.outgoing?.map(async (stock: string) => {
        const detail = details.find((item: any) => item.symbol === stock);
        if (detail && detail.outgoing) {
          try {
            exitPerformans[stock] = parseFloat(detail.perClose) / 100;
          } catch (e) {}
        }
      });
    }
  }, [details, chartDate]);
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, `Bist ${list} Giriş`);
  }, []);
  return (
    <WeeklyPage pageId={`page-${pageIndex}-${subIndex}`}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ width: "50%" }}>
          <div
            style={{
              textDecoration: "underline",
              color: "#fff",
              fontSize: 40,
              fontWeight: "bold",
              textAlign: "center",
              padding: 40,
            }}
          >
            BIST {list} HAFTALIK YEDİLİ
          </div>
          <div className="weekly-chip-container">
            <div className="weekly-chip-group">
              {data?.basket.map((stock: string) => {
                let backColor = "rgb(236,242,255)";
                let fontColor = "rgb(60,80,210)";

                return (
                  <div
                    className="weekly-chip"
                    style={{
                      backgroundColor: backColor,
                      color: fontColor,
                      borderWidth: 1,
                      borderColor: fontColor,
                      borderStyle: "solid",
                    }}
                  >
                    {stock}
                  </div>
                );
              })}
            </div>
            <div className="weekly-chip-group">
              {data?.incoming.map((stock: string) => {
                let backColor = "rgb(254,255,233)";
                let fontColor = "rgb(68, 137, 86);";
                return (
                  <div
                    className="weekly-chip"
                    style={{
                      backgroundColor: backColor,
                      color: fontColor,
                      borderWidth: 1,
                      borderColor: fontColor,
                      borderStyle: "solid",
                    }}
                  >
                    {stock}{" "}
                  </div>
                );
              })}
              {data?.outgoing.map((stock: string) => {
                let backColor = "rgb(255,238,226)";
                let fontColor = "rgb(217,78,85)";
                return (
                  <div
                    className="weekly-chip"
                    style={{
                      backgroundColor: backColor,
                      color: fontColor,
                      borderWidth: 1,
                      borderColor: fontColor,
                      borderStyle: "solid",
                    }}
                  >
                    {stock}{" "}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50%",
            backgroundColor: "#fff",
            padding: 20,
            fontSize: 22,
          }}
        >
          <div>
            Bu hafta Bist {list} Haftalık Sentiment tarafında{" "}
            {data?.outgoing?.length} hissenin 7'li listeden çıkıp,{" "}
            {data?.incoming?.length} hissenin girdiğini görüyoruz.
            <TextArea
              style={{ width: "100%", height: 100 }}
              defaultValue={header}
              onChange={(e) => {
                setHeader(e.target.value);
                onPageDataUpdated(save_key, "header", e.target.value);
              }}
              className="no-print"
              placeholder="Başlık için eklenti yapabilirsiniz"
            ></TextArea>{" "}
            <span className="print-only-inline">{header ?? null}</span>
          </div>
          <div style={{ marginTop: 40 }}>
            BIST {list} Haftalık Yedilisine {data?.incoming?.join(", ") + " "}{" "}
            girerken, {data?.outgoing?.join(", ") + " "}
            ilk 7 sırasındaki yerini kaybediyor.
          </div>
          <div style={{ marginTop: 40, textAlign: "center" }}>
            Listelerden çıkış performansları aşağıdaki gibidir.
            {data?.outgoing?.map((stock: string) => {
              return (
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    justifyContent: "center",
                    marginTop: 0,
                  }}
                >
                  <span>{stock}</span>
                  <span
                    style={{
                      color: exitPerformans[stock] > 0 ? "green" : "red",
                    }}
                  >
                    %
                    {exitPerformans[stock]
                      ? (exitPerformans[stock] * 100).toFixed(2)
                      : ""}
                  </span>
                </div>
              );
            })}
          </div>
          <div style={{ marginTop: 30, textAlign: "center" }}>
            Geçtiğimiz hafta ilk 7 listesine giren hisselerin performansları:
          </div>
          <div style={{ textAlign: "center", marginTop: 10 }}>
            {details
              ?.filter((stock: any) => {
                return stock.lastIncoming === true;
              })
              .map((stock: any) => (
                <div
                  style={{ display: "flex", gap: 10, justifyContent: "center" }}
                >
                  <span>{stock.symbol}</span>{" "}
                  <span
                    style={{
                      color: parseFloat(stock.perClose) > 0 ? "green" : "red",
                    }}
                  >
                    %{stock.perClose}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </WeeklyPage>
  );
};
