import ReactEchartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  BrushComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import "moment/locale/tr";
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { SentimentEndeksLabel } from "../../ata/imkb";
import {
  query,
  collection,
  orderBy,
  limit,
  getDocs,
  where,
} from "firebase/firestore";
import React from "react";
import { fireStore } from "../../../../firebase";
import { beforePreviousFridayNight } from "../util";
import { useChartDateContext } from "../chart-date-context";
moment.locale("tr");

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  DataZoomSliderComponent,
  GridSimpleComponent,
  DataZoomInsideComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  BrushComponent,
  LegendComponent,
  MarkLineComponent,
  CanvasRenderer,
  LineChart,
  GaugeChart,
  BarChart,
]);

export const DailyInstantECharts = (props: any) => {
  const echartsRef = useRef(null);
  const updateCallbacRef = useRef<((newBar: any) => void) | null>(null);
  const updater = (symbol: any, resol: any, updateCallback: any) => {
    updateCallbacRef.current = updateCallback;
  };
  const [options, setOptions] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [markers, setMarkers] = useState<any>(null);
  const [lastSentiment, setLastSentiment] = useState<any>(null);
  const [lastPrice, setlastPrice] = useState<any>(null);
  const { chartDate } = useChartDateContext();

  const getChartOptions = (forceMarkers?: any) => {
    if (!data) return;
    return {
      animation: false,
      grid: {
        top: 50,
        bottom: 40,
        left: "50",
        right: 50,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross", crossStyle: { color: "#999" } },
      },
      toolbox: {
        show: false,
        feature: {
          //dataView: { show: true, readOnly: false },
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: true },
          saveAsImage: { show: true },
        },
      },
      legend: {
        data: ["Günlük Anlık", "Fiyat"],
        textStyle: {
          color: "#fff",
        },
      },
      xAxis: [
        {
          show: true,
          type: "category",
          axisPointer: { type: "shadow" },
          axisLabel: {
            formatter: (val: any, index: any) => {
              return moment.unix(data.index[index].date).format("MMM DD");
            },
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "#eee",
            },
          },
        },
      ],
      yAxis: [
        {
          position: "right",
          type: "value",
          name: "",
          min: 0,
          max: 10,
          interval: 1,
          splitLine: {
            show: false,
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            color: "rgb(244,231,98)",
            show: true,
            formatter: function (value: any) {
              return value.toFixed(2);
            },
          },
          // axisLabel: { formatter: '{value} °C' }
        },
        {
          position: "left",
          type: "value",
          name: "",
          scale: true,
          splitLine: {
            show: false,
            lineStyle: {
              color: "#eee",
            },
          },
          axisLabel: {
            color: "rgb(244,231,98)",
            show: true,
          },

          // axisLabel: { formatter: '{value} °C' }
        },
      ],
      series: [
        {
          name: "Günlük Anlık",
          type: "line",
          tooltip: {
            valueFormatter: function (value: number) {
              return (value as number) + "";
            },
          },
          data: data.sentiment,
          yAxisIndex: 0,
          symbolSize: 0,
          lineStyle: {
            normal: {
              width: 1,
              color: "#1d9afb",
              //shadowColor: '#000',
              //shadowBlur: 20
            },
          },
          markLine: {
            lineStyle: {
              color: "rgb(253,238,60)",
              width: 1.5,
              type: "solid",
            },
            label: {
              textBorderColor: "transparent",
              color: "#000",
              backgroundColor: "rgb(253,238,60)",
              borderRadius: 0,
              position: "end",
              distance: 5,
              padding: [3, 5, 3, 5],
              fontSize: 11,
              width: 20,
            },
            symbol: "circle",
            symbolSize: 0,
            data: forceMarkers ?? markers,
          },
        },
        {
          name: "Fiyat",
          type: "line",
          tooltip: {
            valueFormatter: function (value: number) {
              return (value as number) + "";
            },
          },
          data: data.index,
          yAxisIndex: 1,
          symbolSize: 0,
          markLine: {
            lineStyle: {
              color: "#f00",
              width: 1,
              type: "solid",
            },
            label: {
              textBorderColor: "transparent",
              color: "#fff",
              backgroundColor: "#40b552",
              borderRadius: 0,
              position: "start",
              distance: 2,
              padding: [3, 5, 3, 5],
              fontSize: 11,
              width: 40,
            },
            symbol: "circle",
            symbolSize: 0,
            data: lastPrice
              ? [
                  {
                    name: "destek",
                    yAxis: lastPrice.value[1],
                    lineStyle: {
                      width: 0,
                    },
                    label: {
                      formatter: markerFormatter,
                      backgroundColor: "#40b552",
                    },
                  },
                ]
              : [],
          },
          lineStyle: {
            normal: {
              width: 1,

              color: "#3dad4a",
              //shadowColor: '#000',
              //shadowBlur: 20
            },
          },
        },
      ],
    };
  };
  const markerFormatter = (value: any) => {
    return parseFloat(value.value).toFixed(2);
  };
  const updateMarkers = () => {
    const newMarkers: any = [];
    if (lastSentiment) {
      newMarkers.push({
        name: "destek",
        yAxis: lastSentiment.value[1],
        lineStyle: {
          width: 0,
        },
        label: {
          formatter: markerFormatter,
          backgroundColor: "#2266f9",
        },
      });
    }
    if (props.resistance1 != null && props.resistance1 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.resistance1,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.resistance2 != null && props.resistance2 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.resistance2,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.resistance3 != null && props.resistance3 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.resistance3,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker4 != null && props.marker4 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker4,
        label: {
          formatter: markerFormatter,
        },
      });

    setMarkers(newMarkers);
    setOptions(getChartOptions());
    setTimeout(() => {
      const echartCurrent: any = echartsRef.current;

      if (echartCurrent) {
        echartCurrent
          .getEchartsInstance()
          .setOption(getChartOptions(newMarkers), true);
      }
    }, 100);
  };
  useEffect(() => {
    updateMarkers();
  }, [
    props.resistance1,
    props.resistance2,
    props.resistance3,
    props.marker4,
    lastSentiment,
  ]);

  useEffect(() => {
    const previousFriday = beforePreviousFridayNight(chartDate);

    const q = query(
      collection(fireStore, "DIS1"),
      orderBy("dateTime", "desc"),
      where("dateTime", "<=", previousFriday.toDate()),
      limit(280)
    );

    getDocs(q).then((querySnapshot: any) => {
      var sentimentData: Object[] = [];
      var indexData: Object[] = [];

      querySnapshot.forEach((doc: any) => {
        const dateVal = moment
          .unix(doc.data().dateTime.seconds)
          .format("YYYY/MM/DD HH:mm");
        sentimentData.push({
          name: "sentiment",
          value: [dateVal, doc.data().sentiment],
          date: doc.data().dateTime.seconds,
        });
        indexData.push({
          name: "index",
          value: [dateVal, doc.data().index_value],
          date: doc.data().dateTime.seconds,
        });
      });
      indexData = indexData.reverse();
      sentimentData = sentimentData.reverse();
      setLastSentiment(sentimentData[sentimentData.length - 1]);
      setlastPrice(indexData[indexData.length - 1]);

      setData({
        sentiment: sentimentData,
        index: indexData,
      });
      setOptions(getChartOptions());
    });
  }, [chartDate]);
  if (!data || !data.sentiment) return null;

  return (
    <ReactEchartsCore
      echarts={echarts}
      ref={echartsRef}
      style={{
        height: "560px",
        width: "100%",
        marginBottom: 0,
        border: "1.5px solid #000",
      }}
      option={options ?? getChartOptions()}
    />
  );
};
