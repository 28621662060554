import { Card, EditableText, Elevation, H5 } from "@blueprintjs/core";
import { useEffect, useState } from "react";

export const Comment = (props: any) => {
  const [comment, setComment] = useState("");
  const [subcomment, setSubcomment] = useState("");
  useEffect(() => {
    if (props.initialData?.comment) {
      setComment(props.initialData?.comment?.data);
      setSubcomment(props.initialData.subcomment?.data);
    }
  }, [props.initialData]);
  return (
    <Card elevation={Elevation.TWO}>
      <H5>Sentiment Yorumu</H5>
      <EditableText
        value={comment}
        onChange={(val) => {
          setComment(val);
          props.updateDocumentForm("comment", "data", val);
        }}
        placeholder="Yorum ekleyin"
        multiline
        minLines={6}
        maxLines={6}
      />
      <EditableText
        value={subcomment}
        onChange={(val) => {
          setSubcomment(val);
          props.updateDocumentForm("subcomment", "data", val);
        }}
        placeholder="Alt yorum ekleyin"
        multiline
        minLines={5}
        maxLines={5}
      />
    </Card>
  );
};
