import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogFooter,
  Icon,
  Intent,
} from "@blueprintjs/core";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, fireStore } from "../firebase";
export const Home = () => {
  const navigate = useNavigate();
  const [bulletins, setBulletins] = useState<any[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [deleteBulletinName, setDeleteBulletinName] = useState<string | null>(
    null
  );
  const readDocs = async () => {
    const q = query(
      collection(fireStore, "NS_BULLETINS"),
      orderBy("updatedAt", "desc"),
      limit(20)
    );

    getDocs(q).then((querySnapshot) => {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        list.push({
          name: doc.data().name,
          id: doc.id,
          updatedAt: doc.data().updatedAt,
          lastUpdateUser: doc.data().lastUpdateUser,
        });
      });
      setBulletins(list);
    });
  };
  useEffect(() => {
    readDocs();
  }, []);

  return (
    <div style={{ width: 1024, margin: "auto" }}>
      <Card>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            intent={Intent.PRIMARY}
            icon={<Icon icon="add" />}
            minimal={false}
            onClick={() => {
              addDoc(collection(fireStore, "NS_BULLETINS"), {
                name: moment().format("DD.MM.YYYY"),
                updatedAt: new Date(),
                createdAt: new Date(),
                lastUpdateUser: auth.currentUser?.email,
                lastUpdateUserId: auth.currentUser?.uid,
                content: "{}",
              }).then((docRef: any) => {
                navigate(`/new?id=${docRef.id}`);
              });
            }}
          >
            Yeni Bülten
          </Button>
        </div>
        <div style={{ margin: 20 }}></div>
        <table cellPadding={0} cellSpacing={0} className="bulletin-list">
          <thead>
            <tr>
              <th>Bülten adı</th>
              <th>Güncelleme Tarihi</th>
              <th>Güncelleyen</th>
              <th style={{ width: 100 }}></th>
              <th style={{ width: 100 }}></th>
              <th style={{ width: 100 }}></th>
              <th style={{ width: 100 }}></th>
            </tr>
          </thead>
          <tbody>
          {bulletins.map((bulletin) => (
            <tr key={bulletin.id}>
              <td>{bulletin.name}</td>
              <td>
                {moment(bulletin.updatedAt.toDate()).format("DD.MM.YYYY HH:mm")}
              </td>
              <td>{bulletin.lastUpdateUser ?? ""}</td>
              <td>
                <Button
                  intent={Intent.DANGER}
                  fill={true}
                  onClick={() => {
                    setDeleteId(bulletin.id);
                    setShowDeleteDialog(true);
                    setDeleteBulletinName(bulletin.name);
                  }}
                  icon={<Icon icon="trash" />}
                  minimal
                >
                  Sil
                </Button>
              </td>
              <td>
                <Button
                  fill={true}
                  onClick={() => {
                    const ref = doc(fireStore, "NS_BULLETINS", bulletin.id);
                    getDoc(ref).then((doc) => {
                      addDoc(collection(fireStore, "NS_BULLETINS"), {
                        name: moment().format("DD.MM.YYYY") + " - Kopya",
                        updatedAt: new Date(),
                        createdAt: new Date(),
                        lastUpdateUser: auth.currentUser?.email,
                        lastUpdateUserId: auth.currentUser?.uid,
                        content: doc.data()?.content,
                      }).then((docRef: any) => {
                        navigate(`/new?id=${docRef.id}`);
                      });
                    });
                  }}
                  icon={<Icon icon="multi-select" />}
                  minimal
                >
                  Kopyala
                </Button>
              </td>
              <td>
                <Button
                  fill={true}
                  onClick={() => {
                    navigate(`/new?id=${bulletin.id}`);
                  }}
                  icon={<Icon icon="document-open" />}
                  minimal
                >
                  Aç
                </Button>
              </td>
              <td>
                <Button
                  fill={true}
                  onClick={() => {
                    navigate(`/company/halkbank?id=${bulletin.id}`);
                  }}
                  icon={<Icon icon="print" />}
                  minimal
                >
                  Bülten
                </Button>
              </td>
            </tr>
          
          ))}
            </tbody>
        </table>
      </Card>
      <Dialog
        isOpen={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
          setDeleteId(null);
        }}
        title="Bülten Sil"
      >
        <DialogBody>
          <b>{deleteBulletinName}</b> isimli bülteni silmek üzerisiniz. Devam
          etmek istiyor musunuz?
        </DialogBody>
        <DialogFooter
          actions={
            <>
              <Button
                intent="primary"
                text="Kapat"
                onClick={() => {
                  setShowDeleteDialog(false);
                }}
              />
              <Button
                intent="danger"
                text="Sil"
                onClick={() => {
                  if (deleteId == null) return;
                  deleteDoc(doc(fireStore, "NS_BULLETINS", deleteId)).then(
                    () => {
                      readDocs();
                      setShowDeleteDialog(false);
                    }
                  );
                }}
              />
            </>
          }
        />
      </Dialog>
    </div>
  );
};
