import { useEffect, useRef } from "react";
import Moveable from "react-moveable";

export const MoveableImage = ({
  customAble,
  item,
  updateCssEvent,
  focused,
  setFocused,
}: any) => {
  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref) {
      if (focused) {
        const m: Moveable = ref.current;
        m.getControlBoxElement().style.display = "block";
      } else {
        const m: Moveable = ref.current;
        m.getControlBoxElement().style.display = "none";
      }
    }
  }, [focused]);
  return (
    <>
      <img
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setFocused(item.id);
        }}
        id={item.id}
        className="weekly-moveable-image"
        src={item.src}
        style={{
          width: 50,
          height: 50,
          left: 500,
          top: 300,
          position: "absolute",
          zIndex: 999,
          transform:
            item.csstext?.replace("transform:", "").replace(";", "") ?? "none",
        }}
      ></img>
      <Moveable
        key={item.id}
        ref={(e) => {
          ref.current = e;
          if (e && !focused) e.getControlBoxElement().style.display = "none";
        }}
        onRender={(e) => {
          e.target.style.cssText += e.cssText;
        }}
        keepRatio={item.keepRatio}
        rotatable={true}
        scalable={true}
        draggable={true}
        target={"#" + item.id}
        controlPadding={5}
        hideDefaultLines={true}
        onRotateEnd={(target: any) => {
            if(!target.isRotate)
            return false;
          updateCssEvent(item, target);
        }}
        onDragStart={() => {
          if (ref.current) {
            const m: Moveable = ref.current;
            m.getControlBoxElement().style.display = "block";
            setFocused(item.id);
          }
        }}
        onDragEnd={(target: any) => {
          if (!target.isDrag) return;
          updateCssEvent(item, target);
        }}
        onScaleEnd={(target: any) => {
            if(!target.isScale)
            return false;
          updateCssEvent(item, target);
        }}
        onChangeTargets={(e: any) => {}}
        ables={[customAble]}
        tooltool={true}
      />
    </>
  );
};
