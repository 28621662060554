import ReactEchartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  BrushComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";

import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { collection, getDocs, limit, orderBy, query } from "firebase/firestore";
import { fireStore } from "../../../../firebase";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  DataZoomSliderComponent,
  GridSimpleComponent,
  DataZoomInsideComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  BrushComponent,
  LegendComponent,
  MarkLineComponent,
  CanvasRenderer,
  LineChart,
  GaugeChart,
  BarChart,
]);

export const SectorChart = ({ sector, chartHeight, period }: any) => {
  const echartsRef = useRef(null);

  const [sectorData, setSectorData] = useState<any>([]);
  const getLineIndexes = async () => {
    const q = query(
      collection(
        fireStore,
        `NS_LINES_INDEXES${period === "daily" ? "" : "_1W"}`
      ),
      orderBy("dateTime", "desc"),
      limit(30)
    );

    const querySnapshot = await getDocs(q);

    var linesIndexesData: Object[] = [];
    querySnapshot.forEach(function (doc: any) {
      linesIndexesData.push({
        name: "linesIndexes",
        Index: 1001,
        Symbol: doc.data().symbol,
        Sentiment: doc.data().sentiment,
        Order: doc.data().order,
        Group: doc.data().group,
        ODate:
          doc.data().order <= 5 && doc.data().openDate == ""
            ? moment.unix(doc.data().dateTime.seconds).format("DD.MM.yyyy")
            : doc.data().openDate,
        OPrice:
          doc.data().openPrice != "0"
            ? doc.data().openPrice
            : doc.data().order <= 10
            ? doc.data().price
            : "",
        Price: doc.data().price,
        DailyChange: doc.data().dailyChange,
        Vbts: doc.data().vbts,
        Viop: doc.data().viop,
        PerDay: doc.data().perDay,
        SentimentList: doc.data().sentimentList,
        PriceList: doc.data().priceList,
        ChangeList: doc.data().changeList,
        ReChangeList: doc.data().changeList.slice().reverse(),
        dateTime: doc.data().dateTime.toDate(),
        Time: doc.data().time ?? 0,
        UpdateTime: moment
          .unix(doc.data().dateTime.seconds)
          .format("DD.MM.yy HH:mm"),
        LineDate: doc.data().dateTime.seconds,
        Date: moment.unix(doc.data().dateTime.seconds).format("YYYY/MM/DD"),
        Indexes: [],
        OrderList: doc.data().changeList.map((x: number) => {
          return doc.data().order - x;
        }),
        Today: moment.unix(doc.data().dateTime.seconds).format("DD.MM.yyyy"),
      });
    });
    return linesIndexesData;
  };
  const getChartOptions = () => {
    return {
      grid: {
        top: 30,
        bottom: 20,
        left: 50,
        right: "5%",
      },
      tooltip: {
        trigger: "axis",
        axisPointer: { type: "cross", crossStyle: { color: "#999" } },
      },

      legend: {
        textStyle: {
          color: "#ddd",
        },
      },
      xAxis: [
        {
          type: "category",
          data: sectorData?.DateList,
          axisPointer: { type: "shadow" },
          axisLabel: { formatter: "" },
        },
      ],
      yAxis: [
        {
          position: "right",
          type: "value",
          name: "",
          min: -30,
          max: 30,
          interval: 10,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
          axisLabel: {
            color: "#ddd",
          },
          // axisLabel: { formatter: '{value} °C' }
        },
        {
          type: "value",
          name: "",
          scale: true,
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
          axisLabel: {
            color: "#ddd",
          },
          // axisLabel: { formatter: '{value} °C' }
        },
      ],
      series: [
        {
          name: "Sentiment Değişim",
          type: "bar",
          tooltip: {
            valueFormatter: function (value: number) {
              return (value as number) + "";
            },
          },
          data: sectorData?.ChangeList?.map((x: number) => {
            return {
              value: x,
              itemStyle: { color: x < 0 ? "#ff0000" : "#1d9afb" },
            };
          }),
          yAxisIndex: 0,
          barWidth: "85%",
          itemStyle: {
            barBorderRadius: 0,
            shadowColor: "#000",
          },
        },
        {
          name: "Fiyat",
          type: "line",
          tooltip: {
            valueFormatter: function (value: number) {
              return (value as number) + "";
            },
          },
          data: sectorData?.PriceList,
          yAxisIndex: 1,
          symbolSize: 3,
          lineStyle: {
            normal: {
              width: 3,
              color: "#3dad4a",
            },
          },
        },
      ],
    };
  };
  const loadData = async () => {
    const lineIndexData = await getLineIndexes();
    const data = lineIndexData.find((x: any) => {
      return x.Symbol == sector;
    });
    setSectorData(data);
    const echartCurrent: any = echartsRef.current;


  };
  useEffect(() => {
    loadData();
  }, [sector, period]);
  if (!sectorData) return null;
  return (
    <div
      style={{
        height: chartHeight ?? "210px",
        width: "100%",
        marginBottom: 0,
        backgroundColor: "rgb(19,23,35)",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: 10,
        position: "relative",
      }}
    >
      <div style={{ color: "#fff", textAlign: "center" }}>{sector}</div>
      <div>
        {period === "weekly" && (
          <div className="weekly-chart-title">Haftalık Veriler</div>
        )}{" "}
      </div>
      <ReactEchartsCore
      ref={echartsRef}
        echarts={echarts}
        style={{
          height: "180px",
          width: "100%",
          marginBottom: 0,
        }}
        option={getChartOptions()}
      />
    </div>
  );
};
