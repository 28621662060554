import { TextArea } from "@blueprintjs/core";
import { DailyInstantECharts } from "../charts/daily-instant";
import { useEffect, useState } from "react";
import { DailySentimentECharts } from "../charts/daily-sentiment-mom";
import { PageHider } from "../components/PageHider";
import { HourlySentimentMomentumECharts } from "../charts/hourly-sentiment-mom";
import { Osilator } from "../charts/osilator";
import { WeeklyPage } from "../components/WeeklyPage";
import { ImageContainer } from "../components/ImageContainer";

export const Page7 = ({
  onPageDataUpdated,
  weekData,
  pageIndex,
  subIndex,
  registerPageIndex,
}: any) => {
  const [comments, setComments] = useState<any>(weekData?.page7?.comment ?? "");
  const [focusedImageId, setFocusedImageId] = useState<number>(1);

  const onTitleUpdated = (text: string) => {
    setComments(text);
    onPageDataUpdated("page7", "comment", text);
  };
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Osilatör");
  }, []);

  return (
    <WeeklyPage
      pageId={`page-${pageIndex}-${subIndex}`}
      cropped
      onClick={() => {
        setFocusedImageId((prev) => prev + 1);
      }}
    >
      <div style={{ display: "flex", gap: 10, paddingTop: 60 }}>
        <div style={{ width: "25%" }}>
          <TextArea
            className="no-print"
            style={{ flex: 1, width: "100%", height: "100%" }}
            placeholder="Osilator Değerlendirmesini girebilirisiniz"
            onChange={(e) => onTitleUpdated(e.target.value)}
            defaultValue={comments}
          />
          <div
            className="print-only-block"
            style={{ color: "#fff", fontSize: 20, lineHeight: "30px" }}
          >
            <ul>
              {comments
                ?.split("\n")
                .filter((item: string) => item.trim() !== "")
                .map((item: string) => (
                  <li>{item}</li>
                ))}
            </ul>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "rgb(19,23,35)",
            flex: 1,
            marginRight: 20,
            width: "75%",
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          <Osilator />
          <div
            style={{
              position: "absolute",
              right: 70,
              bottom: 150,
              fontSize: 40,
              color: "#fff",
            }}
          >
            OSİLATOR
          </div>
        </div>
        <ImageContainer
          pageId={"page7"}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData?.page7}
          focusedImageId={focusedImageId}
        />
      </div>
    </WeeklyPage>
  );
};
