import { Spinner } from "@blueprintjs/core";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import React, { Suspense } from "react";
import {
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from "react-router-dom";
import "./App.css";
import { useAuth } from "./auth";
import { BulletinForm } from "./components/form";
import { GlobalLangList } from "./components/global-lang";
import { Home } from "./components/home";
import Login from "./components/login";
import { Speak } from "./components/speak";
import { Templates } from "./components/template";
import Weekly from "./components/templates/weekly";
import { WeeklyList } from "./components/templates/weekly/weekly-list";
import { Layout } from "./layout";
import { WeeklyReportList } from "./components/templates/weekly/weekly-report-list";
import { ChartDateContextProvider } from "./components/templates/weekly/chart-date-context";
const AtaTemplate = React.lazy(() => import("./components/templates/ata"));
const HalkTemplate = React.lazy(
  () => import("./components/templates/halkbank")
);
const WeeklyTemplate = React.lazy(
  () => import("./components/templates/weekly")
);
const NCMTemplate = React.lazy(() => import("./components/templates/ncm/new"));
const NCMLİst = React.lazy(() => import("./components/templates/ncm/list"));
function App() {
  const { isLoadingUser, authUser } = useAuth();
  if (isLoadingUser) return <Spinner />;
  if (authUser === null) return <Login />;
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />}>
        <Route path="home" element={<Home />} />
        <Route path="new" element={<BulletinForm />} />
        <Route path="templates" element={<Templates />} />
        <Route
          path="company/ata"
          element={
            <Suspense fallback={<Spinner />}>
              <AtaTemplate />{" "}
            </Suspense>
          }
        />
        <Route
          path="company/halkbank"
          element={
            <Suspense fallback={<Spinner />}>
              <HalkTemplate />{" "}
            </Suspense>
          }
        />
        <Route path="speak" element={<Speak />} />
        <Route path="global-lang" element={<GlobalLangList />} />
        <Route path="weekly" element={<WeeklyList />} />
        <Route
          path="weekly-report"
          element={
            <ChartDateContextProvider>
              {" "}
              <Weekly />
            </ChartDateContextProvider>
          }
        />
        <Route
          path="ncm-report"
          element={
            <Suspense fallback={<div>Yükleniyor...</div>}>
              <NCMTemplate />
            </Suspense>
          }
        />
        <Route
          path="ncm"
          element={
            <Suspense fallback={<div>Yükleniyor...</div>}>
              <NCMLİst />
            </Suspense>
          }
        />
        <Route
          path="weekly-report-list"
          element={
            <Suspense fallback={<div>Yükleniyor...</div>}>
              <WeeklyReportList />
            </Suspense>
          }
        />
      </Route>
    )
  );
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
