import { useEffect, useState } from "react";
import Moveable from "react-moveable";
import { ImageModal } from "./ImageModal";
import { v4 as uuidv4 } from "uuid";
import { MoveableImage } from "./MoveableImage";

export const ImageContainer = ({
  pageId,
  onPageDataUpdated,
  weekData,
  focusedImageId
}: any) => {
  const [images, setImages] = useState<any>(weekData?.images ?? []);
  const [focusedId,setFocusedId] = useState<any>(null)
  const updateCssEvent = (item: any, target: any) => {
    const newImages = images.map((image: any) => {
      if (image.id === item.id) {
        return {
          ...image,
          csstext: target.lastEvent?.cssText,
        };
      }
      return image;
    });

    setImages(newImages);
    onPageDataUpdated(pageId, "images", newImages);
  };
  const customAble = {
    name: "tooltool",
    render(moveable: any) {
      const { renderPoses } = moveable.state;

      return (
        <img
          id="delete-button"
          onClick={() => {
            const id = moveable?.props.target?.id;
            const newImages = images.filter((image: any) => image.id !== id);

            setImages(newImages);
            onPageDataUpdated(pageId, "images", newImages);
          }}
          style={{
            width: 13,
            height: 13,
            position: "absolute",
            cursor:"pointer",
            transform: `translate(50%, -150%) translate(${renderPoses[1][0]}px, ${renderPoses[1][1]}px) translateZ(-50px)`,
            zIndex: 999999999,
          }}
          
          src="/images/weekly_image/delete.png"
        />
        
      );
    },
  };
  useEffect(()=>{
    setFocusedId(focusedImageId)
  },[focusedImageId])
  return (
    <>
      <ImageModal
        imageSelected={(e: string, keepRatio: boolean) => {
          const newImages = [
            ...images,
            { src: e, keepRatio, id: "image" + uuidv4().replace("-", "") },
          ];
          setImages(newImages);
          setTimeout(() => {
            onPageDataUpdated(pageId, "images", newImages);
          }, 100);
        }}
      />
      {images?.map((item: any) => (
        <MoveableImage setFocused={setFocusedId} focused={focusedId===item.id} item={item} customAble={customAble} updateCssEvent={updateCssEvent}/>
      ))}
    </>
  );
};
