import { TextArea } from "@blueprintjs/core";
import { DailyInstantECharts } from "../charts/daily-instant";
import { useEffect, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { PageHider } from "../components/PageHider";
import { WeeklyPage } from "../components/WeeklyPage";
import { ImageContainer } from "../components/ImageContainer";

export const Page4 = ({
  onPageDataUpdated,
  weekData,
  pageIndex,
  subIndex,
  registerPageIndex,
}: any) => {
  const [comments, setComments] = useState<any>(weekData?.page4?.comment ?? "");
  const [focusedImageId, setFocusedImageId] = useState<number>(1);

  const onTitleUpdated = (text: string) => {
    setComments(text);
    onPageDataUpdated("page4", "comment", text);
  };
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Genel Sentiment Değerlendirmesi");
  }, []);

  return (
    <WeeklyPage
      blue
      pageId={`page-${pageIndex}-${subIndex}`}
      onClick={() => {
        setFocusedImageId((prev) => prev + 1);
      }}
    >
      <div
        style={{
          fontSize: 40,
          color: "#fff",
          textAlign: "center",
          fontWeight: "bold",
          padding: 10,
        }}
      >
        GENEL SENTİMENT DEĞERLENDİRMESİ
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <TextArea
          className="no-print"
          style={{ flex: 1, width: "100%", height: "100%" }}
          placeholder="Genel Sentiment Değerlendirmesini girebilirisiniz"
          onChange={(e) => onTitleUpdated(e.target.value)}
          defaultValue={comments}
        />
        <div
          className="print-only-block"
          style={{ color: "#fff", fontSize: 25, lineHeight: "45px" }}
        >
          <ul>
            {comments
              ?.split("\n")
              .filter((item: string) => item.trim() !== "")
              .map((item: string) => (
                <li>{item}</li>
              ))}
          </ul>
        </div>
      </div>
      <ImageContainer
        pageId={"page4"}
        onPageDataUpdated={onPageDataUpdated}
        weekData={weekData?.page4}
        focusedImageId={focusedImageId}
      />
    </WeeklyPage>
  );
};
