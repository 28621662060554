import { Icon } from "@blueprintjs/core";
import { DataSheetGridRef } from "react-datasheet-grid";

export const Colorizer = (props: any) => {
  const changeColor = (color:string) => {
    props.colorizeData(props.tableSelection.selection,color);
    
  };
  return (
    <div className="colorizer">
      <Icon icon="color-fill" iconSize={20} color="gray" />
      <div
        onClick={() => {
          changeColor("green");
        }}
        className="green-color"
      ></div>
      <div  onClick={() => {
          changeColor("red");
        }} className="red-color"></div>
      <div  onClick={() => {
          changeColor("white");
        }} className="white-color"></div>
    </div>
  );
};
