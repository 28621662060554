import { Button, Card, Icon, OverlayToaster } from "@blueprintjs/core";
import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { fireStore } from "../../../firebase";
import { useNavigate } from "react-router-dom";
import moment from "moment";

export const WeeklyReportList = () => {
  const [reports, setReports] = useState<any[]>([]);
  const navigate = useNavigate();

  const loadReports = () => {
    const q = query(
      collection(fireStore, "NS_USER_BULLETINS"),
      orderBy("dateTime", "desc"),
      limit(100)
    );
    getDocs(q).then((querySnapshot) => {
      const docs: any[] = [];
      querySnapshot.forEach((doc) => {
        docs.push({
          ...doc.data(),
          id: doc.id,
        });
      });
      setReports(docs);
    });
  };
  useEffect(() => {
    loadReports();
  }, []);
  return (
    <div
      style={{
        width: 1024,
        margin: "auto",
      }}
    >
      <Card
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          alignItems: "start",
        }}
      >
        <Button
          icon={<Icon icon="arrow-left" />}
          onClick={() => {
            navigate("/weekly");
          }}
        >
          Geri
        </Button>
        {
          <table className="bulletin-weekly-list">
            {reports.map((report) => (
              <tr>
                <td>{moment(report.dateTime.toDate()).format("DD.MM.YYYY")}</td>
                <td>{report.name}</td>
                <td>
                  <a href={report.url} target="_blank">
                    Raporu Aç
                  </a>
                </td>
                <td>
                  <Button
                    intent="danger"
                    onClick={() => {
                      console.log(report.id);

                      deleteDoc(
                        doc(fireStore, "NS_USER_BULLETINS", report.id)
                      ).then(() => {
                        const myToaster = OverlayToaster.create({
                          position: "top",
                        });
                        myToaster.show({
                          message: "Rapor silindi",
                          intent: "success",
                        });
                        loadReports();
                      });
                    }}
                  >
                    Raporu Sil
                  </Button>
                </td>
              </tr>
            ))}
          </table>
        }
      </Card>
    </div>
  );
};
