import { Icon } from "@blueprintjs/core";

export const PageHider = ({ pageVisible, setPageVisible }: any) => {
  return (
    <div className="hide-page no-print">

      <Icon
        onClick={() => {
          setPageVisible((prev: any) => !prev);
        }}
        icon={pageVisible ? "eye-on" : "eye-off"}
        iconSize={20}
        color="#000"
      />
    </div>
  );
};
