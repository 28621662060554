import { Button } from "@blueprintjs/core";
import {
  Document,
  TextRun,
  Packer,
  Paragraph,
  HeadingLevel,
  AlignmentType,
} from "docx";
import { saveAs } from "file-saver";
import { Children } from "react";
export const ExportToDocx = ({ data }: any) => {
  const bist30Stocks = () => {
    const sections: any = [];
    const bist30Enter: any = [];
    Object.keys(data).forEach((key) => {
      if (key.startsWith("stock_")) {
        const parts = key.split("_");
        try {
          if (parts[1] === "30" && parts[2] === "incoming") {
            bist30Enter.push(key);
          }
        } catch (e) {}
      }
    });
    if (bist30Enter.length > 0) {
      bist30Enter.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 30 Girenler - " + item.split("_")[3],
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data[item]?.["comment"], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    const bist30Exit: any = [];
    Object.keys(data).forEach((key) => {
      if (key.startsWith("stock_")) {
        const parts = key.split("_");
        try {
          if (parts[1] === "30" && parts[2] === "outgoing") {
            bist30Exit.push(key);
          }
        } catch (e) {}
      }
    });
    if (bist30Exit.length > 0) {
      bist30Exit.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 30 Çıkanlar - " + item.split("_")[3],
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data[item]?.["comment"], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    if (data?.continue30) {
      Object.keys(data?.continue30)?.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 30 Devam Edenler - " + item,
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data?.continue30?.[item], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    if (data?.decreasingRed30) {
      Object.keys(data?.decreasingRed30)?.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 30 Kısalan Kırmızı Barlar - " + item,
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data?.decreasingRed30?.[item], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    if (data?.redtoBlue30) {
      Object.keys(data?.redtoBlue30)?.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 30 Kırmızıdan Maviye Dönen - " + item,
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data?.redtoBlue30?.[item], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    return sections;
  };
  const bist70Stocks = () => {
    const sections: any = [];
    const bist30Enter: any = [];
    Object.keys(data).forEach((key) => {
      if (key.startsWith("stock_")) {
        const parts = key.split("_");
        try {
          if (parts[1] === "70" && parts[2] === "incoming") {
            bist30Enter.push(key);
          }
        } catch (e) {}
      }
    });
    if (bist30Enter.length > 0) {
      bist30Enter.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 70 Girenler - " + item.split("_")[3],
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data[item]?.["comment"], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    const bist30Exit: any = [];
    Object.keys(data).forEach((key) => {
      if (key.startsWith("stock_")) {
        const parts = key.split("_");
        try {
          if (parts[1] === "70" && parts[2] === "outgoing") {
            bist30Exit.push(key);
          }
        } catch (e) {}
      }
    });
    if (bist30Exit.length > 0) {
      bist30Exit.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 70 Çıkanlar - " + item.split("_")[3],
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data[item]?.["comment"], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    if (data?.continue70) {
      Object.keys(data?.continue70)?.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 70 Devam Edenler - " + item,
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data?.continue70?.[item], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    if (data?.decreasingRed70) {
      Object.keys(data?.decreasingRed70)?.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 70 Kısalan Kırmızı Barlar - " + item,
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data?.decreasingRed70?.[item], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    if (data?.redtoBlue70) {
      Object.keys(data?.redtoBlue70)?.forEach((item: any) => {
        sections.push(
          new Paragraph({
            text: "Bist 70 Kırmızıdan Maviye Dönen - " + item,
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({ text: data?.redtoBlue70?.[item], style: "aside" })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    return sections;
  };
  const saveAsDocx = () => {
    const sections: any = [];
    if (data?.page2?.comment) {
      sections.push(
        new Paragraph({
          text: "Haftalık Yorum",
          heading: HeadingLevel.HEADING_2,
        })
      );
      sections.push(
        new Paragraph({
          text: data?.page2?.comment,
          style: "aside",
        })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }
    if (data?.page3?.comment) {
      sections.push(
        new Paragraph({
          text: "Haftalık Sentiment Değerlendirmesi",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page3?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }
    if (data?.page4?.comment) {
      sections.push(
        new Paragraph({
          text: "Genel Sentiment Değerlendirmesi",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page4?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }
    if (data?.page5?.comment) {
      sections.push(
        new Paragraph({
          text: "Günlük Sentiment Değerlendirmesi",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page5?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }
    if (data?.page6?.comment) {
      sections.push(
        new Paragraph({
          text: "Saatlik Sentiment Değerlendirmesi",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page6?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }
    if (data?.page7?.comment) {
      sections.push(
        new Paragraph({
          text: "Osilator Sentiment Değerlendirmesi",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page7?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }
    if (data?.page8?.comment) {
      sections.push(
        new Paragraph({
          text: "Haftalık Sentiment Değerlendirmesi Sonuç",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page8?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }
    if (data?.page11?.comment) {
      sections.push(
        new Paragraph({
          text: "Haftalık Hisse Değerlendirmesi Not",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page11?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }

    const bist30StockSections: any = bist30Stocks();
    if (bist30StockSections?.length > 0) {
      bist30StockSections.forEach((section: any) => sections.push(section));
    }

    const bist70StockSections: any = bist70Stocks();
    if (bist70StockSections?.length > 0) {
      bist70StockSections.forEach((section: any) => sections.push(section));
    }
    if (data?.["sector-detail"]) {
      Object.keys(data?.["sector-detail"]).forEach((sector: any) => {
        sections.push(
          new Paragraph({
            text: "Sektörler- " + sector,
            heading: HeadingLevel.HEADING_2,
          })
        );

        sections.push(
          new Paragraph({
            text: data?.["sector-detail"]?.[sector],
            style: "aside",
          })
        );
        sections.push(
          new Paragraph({
            text: " ",
            style: "aside",
          })
        );
      });
    }
    if (data?.page14?.comment) {
      sections.push(
        new Paragraph({
          text: "Genel Sektörel Değerlendirme",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page14?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }
    if (data?.page12?.comment) {
      sections.push(
        new Paragraph({
          text: "Haftanın Sektörleri Değerlendirme",
          heading: HeadingLevel.HEADING_2,
        })
      );

      sections.push(
        new Paragraph({ text: data?.page12?.comment, style: "aside" })
      );
      sections.push(
        new Paragraph({
          text: " ",
          style: "aside",
        })
      );
    }

    const doc = new Document({
      styles: {
        default: {
          document: {
            run: {
              size: "12pt",
              font: "Arial",
            },
            paragraph: {
              alignment: AlignmentType.LEFT,
            },
          },
          heading2: {
            run: {
              size: 28,
              bold: true,
              color: "000000",
            },
            paragraph: {
              spacing: {
                after: 120,
              },
            },
          },
        },
        paragraphStyles: [
          {
            id: "aside",
            name: "Aside",
            basedOn: "Normal",
            next: "Normal",
            run: {
              color: "000000",
              italics: false,
              bold: false,
              size: 28,
            },
            paragraph: {
              spacing: {
                after: 100,
              },
            },
          },
        ],
      },
      sections: [{ children: sections }],
    });

    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, "bulten_metinleri.docx");
    });
  };
  return (
    <div>
      <Button
        style={{ marginTop: 40 }}
        onClick={() => {
          saveAsDocx();
        }}
      >
        {" "}
        Word'e Aktar
      </Button>
    </div>
  );
};
