import { useEffect, useState } from "react";
import { WeeklyPage } from "../components/WeeklyPage";
import { on } from "events";

export const PreviosPerformance = ({
  list,
  onPageDataUpdated,
  weekData,
  pageIndex,
  subIndex,
  registerPageIndex,
  saveKey,
}: any) => {
  const getEmptyArray = () => {
    let result = [];
    for (let i = 0; i < 10; i++) {
      result.push({});
    }
    return result;
  };
  const [short, setShort] = useState<any>(
    weekData?.[saveKey]?.short ?? getEmptyArray()
  );
  const [toBlue, setToBlue] = useState<any>(
    weekData?.[saveKey]?.toBlue ?? getEmptyArray()
  );
  const onShortChange = (index: number, type: string, value: string) => {
    const newShorts = [...short];
    if (!newShorts[index]) newShorts[index] = {};
    newShorts[index][type] = value;
    setShort(newShorts);
    onPageDataUpdated(saveKey, "short", newShorts);
  };

  const onToBlueChange = (index: number, type: string, value: string) => {
    const newBlues = [...toBlue];
    if (!newBlues[index]) newBlues[index] = {};
    newBlues[index][type] = value;
    setToBlue(newBlues);
    onPageDataUpdated(saveKey, "toBlue", newBlues);
  };
  useEffect(() => {
    registerPageIndex(
      pageIndex,
      subIndex,
      `Bist ${list} - Önceki Hafta Analizleri`
    );
  }, [pageIndex]);
  const getValueColor = (value: string) => {
    if (!value) return "#fff";
    try {
      const floatValue = parseFloat(value);
      if (floatValue < 0) return "red";
      else return "rgb(96,198,108)";
    } catch {
      return "rgb(245,85,61)";
    }
  };
  const getValueFixed = (value: string) => {
    if (!value) return "";
    const floatVal = parseFloat(value);
    const strVal = floatVal.toFixed(2).replace("-", "").replace("+", "");
    const sign = floatVal > 0 ? "+" : "-";
    return `${sign} %${strVal}`;
  };
  return (
    <WeeklyPage cropped pageId={`page-${pageIndex}-${subIndex}`}>
      <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <div
          style={{
            color: "#fff",
            fontSize: 35,
            fontWeight: "bold",
            textAlign: "center",
            padding: 20,
            paddingBottom: 10,
          }}
        >
          BIST {list} - ÖNCEKİ HAFTA ANALİZLERİ
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "start",
            width: "90%",
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: 100,
          }}
        >
          <div
            style={{
              width: "50%",
            }}
          >
            <div
              style={{
                fontSize: 22,
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              ÖNCEKİ HAFTA KISALAN KIRMIZI BARLAR
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: 5 }}
              className="no-print"
            >
              {Array(10)
                .fill(0)
                .map((_, index) => (
                  <div style={{ display: "flex", gap: 5 }}>
                    <input
                      className="no-print"
                      style={{ padding: 5 }}
                      placeholder="Hisse adı"
                      onChange={(e) =>
                        onShortChange(index, "stock", e.target.value)
                      }
                      value={short[index]?.stock ?? ""}
                    ></input>
                    <input
                      className="no-print"
                      style={{ padding: 5 }}
                      placeholder="Performans"
                      onChange={(e) =>
                        onShortChange(index, "value", e.target.value)
                      }
                      value={short[index]?.value ?? ""}
                    ></input>
                  </div>
                ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                paddingTop: 20,
              }}
              className="print-only-flex"
            >
              {Array(10)
                .fill(0)
                .map((_, index) =>
                  short[index]?.stock ? (
                    <div
                      style={{  gap: 15 }}
                      className="print-only-flex"
                    >
                      <span
                        style={{
                          fontSize: 22,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {short[index]?.stock ?? ""}:
                      </span>
                      {!isNaN(parseFloat(short[index]?.value)) ? (
                        <span
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            color: getValueColor(short[index]?.value),
                          }}
                        >
                          {getValueFixed(short[index]?.value)}
                        </span>
                      ) : null}
                    </div>
                  ) : null
                )}
            </div>
          </div>
          <div
            style={{
              width: "50%",
            }}
          >
            <div
              style={{
                fontSize: 22,
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              ÖNCEKİ HAFTA MAVİYE DÖNENLER
            </div>
            <div
              style={{ display: "flex", flexDirection: "column", gap: 5 }}
              className="no-print"
            >
              {Array(10)
                .fill(0)
                .map((_, index) => (
                  <div style={{ display: "flex", gap: 5 }}>
                    <input
                      className="no-print"
                      style={{ padding: 5 }}
                      placeholder="Hisse adı"
                      onChange={(e) =>
                        onToBlueChange(index, "stock", e.target.value)
                      }
                      value={toBlue[index]?.stock ?? ""}
                    ></input>
                    <input
                      className="no-print"
                      style={{ padding: 5 }}
                      placeholder="Performans"
                      onChange={(e) =>
                        onToBlueChange(index, "value", e.target.value)
                      }
                      value={toBlue[index]?.value ?? ""}
                    ></input>
                  </div>
                ))}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 5,
                paddingTop: 20,
              }}
              className="print-only-flex"
            >
              {Array(10)
                .fill(0)
                .map((_, index) =>
                  toBlue[index]?.stock ? (
                    <div
                      style={{  gap: 15 }}
                      className="print-only-flex"
                    >
                      <span
                        style={{
                          fontSize: 22,
                          fontWeight: "bold",
                          color: "#fff",
                        }}
                      >
                        {toBlue[index]?.stock ?? ""}:
                      </span>
                      {!isNaN(parseFloat(toBlue[index]?.value)) ? (
                        <span
                          style={{
                            fontSize: 20,
                            fontWeight: "bold",
                            color: getValueColor(toBlue[index]?.value),
                          }}
                        >
                          {getValueFixed(toBlue[index]?.value)}
                        </span>
                      ) : null}
                    </div>
                  ) : null
                )}
            </div>
          </div>
        </div>
      </div>
    </WeeklyPage>
  );
};
