import { TextArea } from "@blueprintjs/core";
import { DailyInstantECharts } from "../charts/daily-instant";
import { useEffect, useState } from "react";
import { WeeklyPage } from "../components/WeeklyPage";
import {
  query,
  collection,
  where,
  limit,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { fireStore } from "../../../../firebase";
import moment from "moment";
import { beforePreviousFridayNight } from "../util";
import { useChartDateContext } from "../chart-date-context";

export const Page16 = ({
  onPageDataUpdated,
  weekData,
  pageIndex,
  subIndex,
  registerPageIndex,
}: any) => {
  const [data30, setData30] = useState<any>(null);
  const [data70, setData70] = useState<any>(null);
  const [data500, setData500] = useState<any>(null);
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Haftalık Sentiment En Yüksek");
  }, []);
  const { chartDate } = useChartDateContext();

  useEffect(() => {
    const previousFriday = beforePreviousFridayNight(chartDate);

    const q = query(
      collection(fireStore, "NS_LINES_30_1W_P99"),
      orderBy("dateTime", "desc"),
      where("dateTime", "<=", previousFriday.toDate()),

      limit(29)
    );

    getDocs(q).then((querySnapshot: any) => {
      const list: any = [];
      const map: any = {};
      querySnapshot.docs.forEach((doc: any) => {
        if (map[doc.data().symbol]) return;
        map[doc.data().symbol] = true;
        list.push(doc.data());
      });
      list.sort((a: any, b: any) => {
        return b.sentiment - a.sentiment;
      });
      setData30(list);
    });
  }, [chartDate]);

  useEffect(() => {
    const previousFriday = beforePreviousFridayNight(chartDate);

    const q = query(
      collection(fireStore, "NS_LINES_70_1W_P99"),
      orderBy("dateTime", "desc"),
      where("dateTime", "<=", previousFriday.toDate()),
      limit(63)
    );

    getDocs(q).then((querySnapshot: any) => {
      const list: any = [];
      const map: any = {};
      querySnapshot.docs.forEach((doc: any) => {
        if (map[doc.data().symbol]) return;
        map[doc.data().symbol] = true;
        list.push(doc.data());
      });
      list.sort((a: any, b: any) => {
        return b.sentiment - a.sentiment;
      });
      setData70(list);
    });
  }, [chartDate]);
  useEffect(() => {
    const previousFriday = beforePreviousFridayNight(chartDate);

    const q = query(
      collection(fireStore, "NS_LINES_200_1W_P99"),
      orderBy("dateTime", "desc"),
      where("dateTime", "<=", previousFriday.toDate()),
      limit(365 - 34)
    );

    getDocs(q).then((querySnapshot: any) => {
      const list: any = [];
      const map: any = {};
      querySnapshot.docs.forEach((doc: any) => {
        if (map[doc.data().symbol]) return;
        map[doc.data().symbol] = true;
        list.push(doc.data());
      });

      list.sort((a: any, b: any) => {
        return b.sentiment - a.sentiment;
      });
      setData500(list);
    });
  }, [chartDate]);

  return (
    <WeeklyPage cropped pageId={`page-${pageIndex}-${subIndex}`}>
      <div
        style={{
          fontSize: 35,
          color: "#fff",
          textAlign: "center",
          fontWeight: "bold",
          padding: 10,
        }}
      >
        HAFTALIK SENTIMENT PUANI EN YÜKSEK HİSSELER
      </div>
      <div
        style={{
          display: "flex",
          gap: 30,
          justifyContent: "center",
          marginTop: 20,
        }}
      >
        <div>
          <div
            style={{
              textAlign: "center",
              color: "#fff",
              fontSize: 30,
              textDecoration: "underline",
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            BİST 30
          </div>

          <table className="weekly-perf-table">
            <thead>
              <th>#</th>
              <th>Sembol</th>
              <th>Fiyat</th>
              <th>Sentiment</th>
            </thead>
            {data30?.slice(0, 10).map((doc: any) => {
              return (
                <tr>
                  <td>{doc.order}</td>
                  <td>{doc.symbol}</td>
                  <td>{doc.price}</td>
                  <td>{doc.sentiment}</td>
                </tr>
              );
            })}{" "}
          </table>
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              color: "#fff",
              fontSize: 30,
              textDecoration: "underline",
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            BİST 70
          </div>

          <table className="weekly-perf-table">
            <thead>
              <th>#</th>
              <th>Sembol</th>
              <th>Fiyat</th>
              <th>Sentiment</th>
            </thead>
            {data70?.slice(0, 10).map((doc: any) => {
              return (
                <tr>
                  <td>{doc.order}</td>
                  <td>{doc.symbol}</td>
                  <td>{doc.price}</td>
                  <td>{doc.sentiment}</td>
                </tr>
              );
            })}{" "}
          </table>
        </div>
        <div>
          <div
            style={{
              textAlign: "center",
              color: "#fff",
              fontSize: 30,
              textDecoration: "underline",
              fontWeight: "bold",
              marginBottom: 20,
            }}
          >
            Yıldız
          </div>
          <table className="weekly-perf-table">
            <thead>
              <th>#</th>
              <th>Sembol</th>
              <th>Fiyat</th>
              <th>Sentiment</th>
            </thead>
            {data500?.slice(0, 15).map((doc: any) => {
              return (
                <tr>
                  <td>{doc.order}</td>
                  <td>{doc.symbol}</td>
                  <td>{doc.price}</td>
                  <td>{doc.sentiment}</td>
                </tr>
              );
            })}{" "}
          </table>
        </div>
      </div>
    </WeeklyPage>
  );
};
