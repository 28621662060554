import { useEffect, useRef, useState } from "react";
import { WeeklyPage } from "../components/WeeklyPage";
import { StockBar } from "../charts/stock-bar";
import { StockLine } from "../charts/stock-line";
import { TextArea } from "@blueprintjs/core";

export const PageStockContinue = ({
  list,
  title,
  stocks,
  onPageDataUpdated,
  weekData,
  saveKey,
  pageIndex,
  subIndex,
  registerPageIndex,
  shortTitle,
  printChange,
  ignoredStocks,
  onIgnoreStockChange,
  stockCount,
  orderChanged,
}: any) => {
  const [comments, setComments] = useState<any>(weekData?.[saveKey] ?? "");
  const chartData = useRef<any>({});
  const onDataLoaded = (stock: string, newData: any) => {
    (chartData as any)[stock] = newData;
  };

  const onCommentChange = (stock: string, value: string) => {
    const newComment = { ...comments };
    newComment[stock] = value;
    setComments({ ...newComment });
    onPageDataUpdated(saveKey, stock, value);
  };

  useEffect(() => {
    registerPageIndex(
      pageIndex,
      subIndex,
      `Bist ${list}${shortTitle ? `-${shortTitle}-` : " "}${stocks?.join(",")}`
    );
  }, [pageIndex]);

  const onOrderChanged = (value: string, stock: string) => {
    onPageDataUpdated(`${saveKey}_orders`, stock, parseInt(value));

    orderChanged(saveKey, stock, value);
  };

  return (
    <WeeklyPage cropped pageId={`page-${pageIndex}-${subIndex}`}>
      <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <div
          style={{
            color: "#fff",
            fontSize: 35,
            fontWeight: "bold",
            textAlign: "center",
            padding: 20,
            paddingBottom: 10,
          }}
        >
          {title ?? "7'Lİ LİSTEDE DEVAM EDENLER"}, BIST {list}{" "}
          {stocks
            ?.filter((stock: any) =>
              !ignoredStocks
                ? true
                : !ignoredStocks?.[saveKey]?.includes(stock) ?? true
            )
            .join(", ")}
        </div>
        <div>
          <div
            style={{ width: "100%", paddingLeft: 20 }}
            key={(stocks as any)?.join(",")}
          >
            {stocks?.map((stock: string) => {
              const changeData = (chartData as any)?.[stock];

              return (
                <div
                  style={{ display: "flex", marginBottom: 3, gap: 10 }}
                  className={
                    ignoredStocks?.[saveKey]?.includes(stock) ? "no-print" : ""
                  }
                >
                  <input
                    checked={
                      ignoredStocks?.[saveKey]?.includes(stock) ? false : true
                    }
                    className="no-print"
                    onChange={(e) => {
                      onIgnoreStockChange(saveKey, stock, e.target.checked);
                    }}
                    type="checkbox"
                  />
                  <select
                    defaultValue={
                      weekData?.[saveKey + "_orders"]?.[stock] ?? "1"
                    }
                    onChange={(e) => onOrderChanged(e.target.value, stock)}
                    className="no-print continue-order-select"
                  >
                    {Array(stockCount ?? 0)
                      .fill(1)
                      .map((_, index) => (
                        <option value={index + 1}>{index + 1}</option>
                      ))}
                  </select>
                  <div className="weekly-chart-continue-part">
                    <StockBar
                      symbol={stock}
                      list={list}
                      height={170}
                      onDataLoaded={onDataLoaded}
                    />
                  </div>
                  <div className="weekly-chart-continue-part">
                    {" "}
                    <StockLine symbol={stock} list={list} height={170} />
                  </div>
                  <div style={{ width: "250px" }}>
                    <TextArea
                      style={{ height: "100%" }}
                      className="no-print"
                      onChange={(e) => {
                        onCommentChange(stock, e.target.value);
                      }}
                      defaultValue={comments[stock] ?? ""}
                      placeholder={`${stock} yorumu`}
                    />
                    {comments?.[stock] && (
                      <div style={{ fontWeight: "bold", marginRight: 20 }}>
                        <span
                          style={{ color: "#fff" }}
                          className="print-only-inline-block"
                        >
                          <span style={{ color: "red" }}>{stock}: </span>
                          {comments?.[stock]}{" "}
                          {printChange && changeData
                            ? `Sıralama değişimi ${
                                changeData.DailyChange >= 0 ? "+" : ""
                              }${changeData.DailyChange}, sentiment ${
                                changeData.Sentiment
                              }`
                            : ""}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </WeeklyPage>
  );
};
