import { TextArea } from "@blueprintjs/core";
import { DailyInstantECharts } from "../charts/daily-instant";
import { useEffect, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { PageHider } from "../components/PageHider";
import { WeeklyPage } from "../components/WeeklyPage";
import { ImageContainer } from "../components/ImageContainer";

export const Page8 = ({ onPageDataUpdated, weekData,pageIndex,subIndex,registerPageIndex }: any) => {
  const [comments, setComments] = useState<any>(weekData?.page8?.comment ?? "");
  const onTitleUpdated = (text: string) => {
    setComments(text);
    onPageDataUpdated("page8", "comment", text);
  };
  useEffect(() => {
    registerPageIndex(pageIndex,subIndex,"HAFTALIK SENTİMENT DEĞERLENDİRMESİ SONUÇ")
  },[]);
  return (
    <WeeklyPage  pageId={`page-${pageIndex}-${subIndex}`} blue>
      <div
        style={{
          fontSize: 40,
          color: "#fff",
          textAlign: "center",
          fontWeight: "bold",
          padding: 10,
        }}
      >
        HAFTALIK SENTİMENT DEĞERLENDİRMESİ SONUÇ
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <TextArea
          className="no-print"
          style={{ flex: 1, width: "100%", height: "100%" }}
          placeholder="Haftalık Sentiment Değerlendirme Sonucunu girebilirisiniz"
          onChange={(e) => onTitleUpdated(e.target.value)}
          defaultValue={comments}
        />
        <div
          className="print-only-block"
          style={{ color: "#fff", fontSize: 30, lineHeight: "50px" }}
        >
          <ul>
            {comments?.split("\n").filter((item:string)=>item.trim()!=="").map((item: string) => (
              <li>{item}</li>
            ))}
          </ul>
        </div>
      </div>
      <ImageContainer
        pageId={"page8"}
        onPageDataUpdated={onPageDataUpdated}
        weekData={weekData?.page8}
      />
    </WeeklyPage>
  );
};
