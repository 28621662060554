import { Alignment, Button, Navbar } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import { useProvideAuth } from "../hooks/use-auth-provider";
import { BULLETIN_VERSION } from "../version";

export const Header = () => {
  const { userSignOut } = useProvideAuth();
  const navigate = useNavigate();
  return (
    <Navbar style={{ marginBottom: 10 }}>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading onClick={() => navigate("/home")}>
          Sentiment Bülten{" "}
          <span style={{ color: "#777", marginLeft: 1, fontSize: 12 }}>
            {BULLETIN_VERSION}
          </span>
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Button
          minimal
          icon="home"
          text="Anasayfa"
          onClick={() => navigate("/home")}
        />
        <Button
          minimal
          icon="clean"
          text="NCM"
          onClick={() => navigate("/ncm")}
        />
        <Button
          minimal
          icon="clean"
          text="Ata"
          onClick={() => navigate("/company/ata")}
        />
        <Button
          minimal
          icon="clean"
          text="Haftalık"
          onClick={() => navigate("/weekly")}
        />
        <Button
          onClick={() => {
            userSignOut();
          }}
          minimal
          icon="log-out"
          text="Çıkış"
        />
      </Navbar.Group>
    </Navbar>
  );
};
