import { useEffect } from "react";
import { WeeklyPage } from "../components/WeeklyPage";

export const Page10 = ({ pageIndex, subIndex, registerPageIndex }: any) => {
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Özelleştirilmiş Ana Ekran");
  }, []);
  return (
    <WeeklyPage  pageId={`page-${pageIndex}-${subIndex}`} blue>
      <div
        style={{
          textAlign: "center",
          fontSize: 40,
          color: "#fff",
          fontWeight: "bold",
          padding: 40,
        }}
      >
        ÖZELLEŞTİRİLMİŞ ANASAYFA İLE ARTIK HERŞEY TEK EKRANDA!
      </div>
      <div style={{ display: "flex", padding: 20, paddingTop: 10 }}>
        <div style={{ width: "100%", textAlign: "center" }}>
          <img
            style={{ width: "78%", borderRadius: 5 }}
            src="/images/custom_dashboard.jpg"
          />
        </div>
      </div>
    </WeeklyPage>
  );
};
