import { useEffect, useRef, useState } from "react";
import { WeeklyPage } from "../components/WeeklyPage";
import { StockBar } from "../charts/stock-bar";
import { StockLine } from "../charts/stock-line";
import { TextArea } from "@blueprintjs/core";
import { SectorChart } from "../charts/sector-chart";
import { SectorLine } from "../charts/sector-line";
import { Sectors } from "../charts/sectors";

export const SectorDetail = ({
  onPageDataUpdated,
  weekData,
  saveKey,
  pageIndex,
  subIndex,
  registerPageIndex,
  sector,
  period,
}: any) => {
  const [comments, setComments] = useState<any>(
    weekData?.[saveKey]?.[`${subIndex}_comment`] ?? ""
  );
  const [selectedSector, setSelectedSector] = useState(
    weekData?.[saveKey]?.[subIndex]
  );

  const [selectedPeriod, setSelectedPeriod] = useState( weekData?.[saveKey]?.[`${subIndex}_period`] ?? "weekly");

  const onCommentChange = (stock: string, value: string) => {
    setComments(value);
    onPageDataUpdated(saveKey, `${subIndex}_comment`, value);
  };
  const onSectorChange = (sector: string) => {
    setSelectedSector(sector);
    onPageDataUpdated(saveKey, subIndex, sector);
  };
  const onPeriodChange = (period: string) => {
    setSelectedPeriod(period);
    onPageDataUpdated(saveKey, `${subIndex}_period`, period);
  };

  useEffect(() => {
    registerPageIndex(
      pageIndex,
      subIndex,
      `Haftanın Sektörleri ${selectedSector}`
    );
  }, [pageIndex]);

  return (
    <WeeklyPage cropped pageId={`page-${pageIndex}-${subIndex}`}>
      <div style={{ display: "flex", height: "100%", flexDirection: "column" }}>
        <div
          style={{
            color: "#fff",
            fontSize: 35,
            fontWeight: "bold",
            textAlign: "center",
            padding: 20,
            paddingBottom: 0,
          }}
        >
          HAFTANIN SEKTÖRLERİ
        </div>
        <div
          style={{
            color: "#fff",
            fontSize: 25,
            fontWeight: "bold",
            textAlign: "center",
            padding: 20,
            paddingBottom: 10,
          }}
        >
          <select
            className="no-print"
            defaultValue={selectedSector}
            onChange={(e) => {
              onSectorChange(e.target.value);
            }}
          >
            {Object.keys(Sectors).map((key: any) => {
              return (
                <option value={key}>{(Sectors as any)[key] as string}</option>
              );
            })}
          </select>
          <select 
              className="no-print"
          defaultValue={selectedPeriod}
             onChange={(e) => {
              onPeriodChange(e.target.value);
            }}
          >
            <option value="weekly">Haftalık</option>
            <option value="daily">Günlük</option>
          </select>{" "}
          <span className="print-only-inline-block">{selectedSector}</span> -
          BIST {(Sectors as any)[selectedSector] ?? ""}
        </div>
        <div style={{ display: "flex", padding: 20, gap: 20 }}>
          <div
            style={{
              width: "55%",
              display: "flex",
              gap: 10,
              flexDirection: "column",
            }}
          >
            {selectedSector !== "" && (
              <SectorChart
                period={selectedPeriod}
                chartHeight={230}
                sector={selectedSector}
              />
            )}
            {selectedSector !== "" && (
              <SectorLine
                period={selectedPeriod}
                chartHeight={230}
                sector={selectedSector}
              />
            )}
          </div>
          <div style={{ width: "45%" }}>
            <TextArea
              placeholder={selectedSector + " yorumu"}
              defaultValue={comments}
              onChange={(e) => onCommentChange(selectedSector, e.target.value)}
              className="no-print"
              style={{ width: "100%", height: "100%" }}
            />
            {comments &&
              comments?.split("\n").map((commentLine: any) => (
                <div
                  style={{ color: "#fff", fontSize: 25, marginBottom: 20 }}
                  className="print-only-block"
                >
                  {commentLine}
                </div>
              ))}
          </div>
        </div>
      </div>
    </WeeklyPage>
  );
};
