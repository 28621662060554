import { Button, Intent } from "@blueprintjs/core";
import { useRef, useState } from "react";

//@ts-ignore
import { JsonEditor as Editor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";

import { doc, setDoc } from "firebase/firestore";
import { fireStore } from "../firebase";
import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
export const TemplateEditor = (props: any) => {
  const saveTemplate = async () => {
    await setDoc(
      doc(fireStore, "NS_BULLETIN_TEMPLATE", props.id),
      {
        template: JSON.stringify(data),
      },
      { merge: true }
    );
  };
  const [data, setData] = useState<any>(props.template);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        height: "500px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "right" }}>
        <Button
          icon="saved"
          onClick={() => saveTemplate()}
          intent={Intent.PRIMARY}
        >
          Kaydet
        </Button>
      </div>
      <Editor
        htmlElementProps={{ style: { height: "900px" } }}
        style={{ height: "500px" }}
        ace={ace}
        allowedModes={["code","tree"]}
        mode={"code"}
        theme="ace/theme/github"
        onChange={(e: any) => {
          setData(e);
        }}
        value={props.template}
      />
    </div>
  );
};
