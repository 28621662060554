import { useEffect } from "react";
import { WeeklyPage } from "../components/WeeklyPage";

export const Page9 = ({ pageIndex, subIndex, registerPageIndex }: any) => {
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Bu verilere nasıl ulaşırım");
  }, []);
  return (
    <WeeklyPage  pageId={`page-${pageIndex}-${subIndex}`} blue>
      <div
        style={{
          textAlign: "center",
          fontSize: 40,
          color: "#fff",
          fontWeight: "bold",
          padding: 40,
        }}
      >
        BU VERİLERE NASIL ULAŞIRIM?
      </div>
      <div style={{ display: "flex", padding: 20 }}>
        <div
          style={{
            width: "30%",
            color: "#fff",
            fontSize: 25,
            lineHeight: "50px",
            fontWeight: "bold",
          }}
        >
          Bu endeks verileri herkes faydalanabilsin diye ücretsizdir. Değerli
          üyemiz, borsa ile ilgilenen tanıdıklarınıza, sevdiklerinize Sentiment
          Algo'yu tanıma ayrıcalığını yaşatın.
        </div>
        <div style={{ width: "70%" }}>
          <img
            style={{ width: "100%", borderRadius: 5}}
            src="/images/app_sentiment_algo.png"
          />
          <div className="weekly-badge">
            <a style={{color:"#fff"}} href="https://sentimentalgo.com" target="_blank" >sentimentalgo.com</a> sitesine gir, kayıt ol. Panele gir
          </div>
        </div>
      </div>
    </WeeklyPage>
  );
};
