export const SentimentEndeksLabel = {
  TUMU: "Tümü",
  XAKUR: "Aracı Kurumlar",
  XBANK: "Banka",
  XBLSM: "Bilişim",
  XELKT: "Elektrik",
  XFINK: "Finansal Kiralama Faktöring",
  XGMYO: "Gayrimenkul Yat. Ort.",
  XGIDA: "Gıda İçecek",
  XUHIZ: "Hizmetler",
  XHOLD: "Holding ve Yatırım",
  XILTM: "İletişim",
  XINSA: "İnşaat",
  XK100: "Katılım100",
  XKTUM: "Katılım Tüm",
  XKMYA: "Kimya Petrol Plastik",
  XMADN: "Madencilik",
  XMANA: "Metal Ana",
  XMESY: "Metal Eşya Makine",
  XKAGT: "Orman Kağıt Basım",
  XSGRT: "Sigorta",
  XUSIN: "Sanayi",
  XSPOR: "Spor",
  XTAST: "Taş Toprak",
  XUTEK: "Teknoloji",
  XTCRT: "Ticaret",
  XTRZM: "Turizm",
  XULAS: "Ulaştırma",
  XYLDZ: "Yıldız",
};
export const SentimentEndeksler = [
  { value: "", label: `TUMU` },
  { value: "XAKUR", label: `XAKUR` },
  { value: "XBANK", label: `XBANK` },
  { value: "XBLSM", label: `XBLSM` },
  { value: "XELKT", label: `XELKT` },
  { value: "XFINK", label: `XFINK` },
  { value: "XGMYO", label: `XGMYO` },
  { value: "XGIDA", label: `XGIDA` },
  { value: "XUHIZ", label: `XUHIZ` },
  { value: "XHOLD", label: `XHOLD` },
  { value: "XILTM", label: `XILTM` },
  { value: "XINSA", label: `XINSA` },
  { value: "XK100", label: `XK100` },
  { value: "XKTUM", label: `XKTUM` },
  { value: "XKMYA", label: `XKMYA` },
  { value: "XMADN", label: `XMADN` },
  { value: "XMANA", label: `XMANA` },
  { value: "XMESY", label: `XMESY` },
  { value: "XKAGT", label: `XKAGT` },
  { value: "XSGRT", label: `XSGRT` },
  { value: "XUSIN", label: `XUSIN` },
  { value: "XSPOR", label: `XSPOR` },
  { value: "XTAST", label: `XTAST` },
  { value: "XUTEK", label: `XUTEK` },
  { value: "XTCRT", label: `XTCRT` },
  { value: "XTRZM", label: `XTRZM` },
  { value: "XULAS", label: `XULAS` },
  { value: "XYLDZ", label: `XYLDZ` },
];

const ImkbEndeksSenetler = [
  {
    val: [],
    name: "ACSEL",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUSIN,XKMYA,XSDNZ",
  },
  { val: [], name: "ADEL", ix: "XUTUM,XTUMY,XBANA,XUSIN,XSIST" },
  { val: [], name: "ADESE", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSKON" },
  {
    val: [],
    name: "AEFES",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XUSIN,XGIDA,XSIST",
  },
  { val: [], name: "AFYON", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XTAST" },
  { val: [], name: "AGESA", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XSGRT" },
  {
    val: [],
    name: "AGHOL",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XHOLD,XSIST",
  },
  { val: [], name: "AGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
  {
    val: [],
    name: "AHGAZ",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XELKT,XSIST",
  },
  {
    val: [],
    name: "AKBNK",
    ix: "XU100,XU050,XU030,XLBNK,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSRD,XSD25,XUMAL,XBANK",
  },
  {
    val: [],
    name: "AKCNS",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XSRDK,XKTMT,XUSRD,XUSIN,XTAST,XSIST",
  },
  { val: [], name: "AKENR", ix: "XUTUM,XTUMY,XYLDZ,XUSRD,XUHIZ,XELKT,XSIST" },
  {
    val: [],
    name: "AKFGY",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUMAL,XGMYO",
  },
  { val: [], name: "AKGRT", ix: "XUTUM,XTUMY,XYLDZ,XUSRD,XUMAL,XSGRT" },
  { val: [], name: "AKMGY", ix: "XUTUM,XTUMY,XBANA,XTMTU,XKURY,XUMAL,XGMYO" },
  {
    val: [],
    name: "AKSA",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XUSIN,XKMYA",
  },
  {
    val: [],
    name: "AKSEN",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XUHIZ,XELKT,XSIST",
  },
  { val: [], name: "AKSGY", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUMAL,XGMYO" },
  { val: [], name: "AKSUE", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XELKT" },
  { val: [], name: "AKYHO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  {
    val: [],
    name: "ALARK",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XKURY,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "ALBRK",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XUMAL,XBANK",
  },
  { val: [], name: "ALCAR", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMESY,XSIST" },
  {
    val: [],
    name: "ALCTL",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSANK",
  },
  { val: [], name: "ALFAS", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XELKT" },
  {
    val: [],
    name: "ALGYO",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XUMAL,XGMYO",
  },
  {
    val: [],
    name: "ALKA",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XKAGT,XSIZM",
  },
  {
    val: [],
    name: "ALKIM",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XKTMT,XUSIN,XKMYA",
  },
  { val: [], name: "ALMAD", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMADN,XSTKR" },
  { val: [], name: "ANELE", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XINSA,XSIST" },
  { val: [], name: "ANGEN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XKMYA,XSIST" },
  { val: [], name: "ANHYT", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSRD,XUMAL,XSGRT" },
  { val: [], name: "ANSGR", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUMAL,XSGRT" },
  {
    val: [],
    name: "ARASE",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XUHIZ,XELKT",
  },
  {
    val: [],
    name: "ARCLK",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XSD25,XUSIN,XMESY,XSIST",
  },
  {
    val: [],
    name: "ARDYZ",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "ARENA",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "ARMDA",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "ARSAN", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTEKS" },
  {
    val: [],
    name: "ARZUM",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XTCRT,XSIST",
  },
  {
    val: [],
    name: "ASELS",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XUTEK,XTKJS,XSANK",
  },
  {
    val: [],
    name: "ASTOR",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XMESY,XSANK",
  },
  {
    val: [],
    name: "ASUZU",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XMESY,XSKOC",
  },
  { val: [], name: "ATAGY", ix: "XUTUM,XTUMY,XUMAL,XGMYO" },
  {
    val: [],
    name: "ATATP",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "ATEKS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTEKS" },
  { val: [], name: "ATLAS", ix: "XYORT" },
  { val: [], name: "AVGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
  { val: [], name: "AVHOL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  {
    val: [],
    name: "AVOD",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUSIN,XGIDA,XSIZM",
  },
  { val: [], name: "AVTUR", ix: "XUTUM,XTUMY,XUHIZ,XTRZM,XSIST" },
  { val: [], name: "AYCES", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIZM" },
  {
    val: [],
    name: "AYDEM",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKURY,XUHIZ,XELKT,XSDNZ",
  },
  { val: [], name: "AYEN", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XELKT,XSKAY" },
  {
    val: [],
    name: "AYGAZ",
    ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUSIN,XKMYA,XSIST",
  },
  {
    val: [],
    name: "AZTEK",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "BAGFS",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XKMYA,XSBAL",
  },
  {
    val: [],
    name: "BAKAB",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUSIN,XKAGT,XSIZM",
  },
  { val: [], name: "BANVT", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XGIDA,XSBAL" },
  {
    val: [],
    name: "BARMA",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XKAGT,XSIZM",
  },
  {
    val: [],
    name: "BASGZ",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XK050,XK030,XKTMT,XUMAL,XGMYO",
  },
  {
    val: [],
    name: "BAYRK",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSIST",
  },
  {
    val: [],
    name: "BERA",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUMAL,XHOLD,XSKON",
  },
  {
    val: [],
    name: "BEYAZ",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XULAS,XSBAL",
  },
  {
    val: [],
    name: "BFREN",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XUSIN,XMESY,XSBUR",
  },
  {
    val: [],
    name: "BIMAS",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XUSRD,XSD25,XUHIZ,XTCRT",
  },
  {
    val: [],
    name: "BIOEN",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUHIZ,XELKT,XSIZM",
  },
  { val: [], name: "BIZIM", ix: "XUTUM,XTUMY,XYLDZ,XUSRD,XUHIZ,XTCRT" },
  { val: [], name: "BJKAS", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSPOR,XSIST" },
  { val: [], name: "BLCYT", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XTEKS,XSADA" },
  {
    val: [],
    name: "BMSCH",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKOBI,XUSIN,XMANA,XSMNS",
  },
  { val: [], name: "BMSTL", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUSIN,XMANA,XSKOC" },
  { val: [], name: "BNTAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY,XSBAL" },
  {
    val: [],
    name: "BOBET",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XTAST,XSIST",
  },
  { val: [], name: "BOSSA", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS,XSADA" },
  {
    val: [],
    name: "BRISA",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSRD,XUSIN,XKMYA,XSKOC",
  },
  {
    val: [],
    name: "BRKSN",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSTKR",
  },
  { val: [], name: "BRKVY", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUMAL" },
  {
    val: [],
    name: "BRLSM",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XINSA,XSIST",
  },
  { val: [], name: "BRSAN", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XMANA,XSIST" },
  {
    val: [],
    name: "BRYAT",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XUMAL,XHOLD,XSIST",
  },
  { val: [], name: "BSOKE", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTAST,XSAYD" },
  { val: [], name: "BTCIM", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSIN,XTAST,XSIZM" },
  {
    val: [],
    name: "BUCIM",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XTAST,XSBUR",
  },
  {
    val: [],
    name: "BURCE",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XMANA,XSBUR",
  },
  {
    val: [],
    name: "BURVA",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XMANA,XSBUR",
  },
  { val: [], name: "BVSAN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XMESY,XSANK" },
  {
    val: [],
    name: "CANTE",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XELKT",
  },
  {
    val: [],
    name: "CCOLA",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XUSIN,XGIDA,XSIST",
  },
  { val: [], name: "CELHA", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA,XSKOC" },
  {
    val: [],
    name: "CEMAS",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XK050,XUSIN,XMANA",
  },
  {
    val: [],
    name: "CEMTS",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XUSIN,XMANA,XSBUR",
  },
  { val: [], name: "CEOEM", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIST" },
  {
    val: [],
    name: "CIMSA",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XUSIN,XTAST",
  },
  {
    val: [],
    name: "CLEBI",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUHIZ,XULAS,XSIST",
  },
  { val: [], name: "CMBTN", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTAST,XSIZM" },
  { val: [], name: "CONSE", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSIST" },
  { val: [], name: "CRDFA", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK" },
  { val: [], name: "CRFSA", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTCRT" },
  { val: [], name: "CUSAN", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA,XSIST" },
  { val: [], name: "DAGHL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  { val: [], name: "DAGI", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XTEKS,XSIST" },
  {
    val: [],
    name: "DAPGM",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XINSA,XSIST",
  },
  { val: [], name: "DARDL", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XGIDA" },
  { val: [], name: "DENGE", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  { val: [], name: "DERHL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  { val: [], name: "DERIM", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS,XSIST" },
  { val: [], name: "DESA", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTEKS" },
  { val: [], name: "DESPC", ix: "XUTUM,XTUMY,XBANA,XUTEK,XTKJS,XBLSM,XSIST" },
  { val: [], name: "DEVA", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XKMYA,XSTKR" },
  {
    val: [],
    name: "DGATE",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "DGGYO", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XGMYO" },
  { val: [], name: "DGNMO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XKAGT" },
  { val: [], name: "DITAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY" },
  { val: [], name: "DMSAS", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA" },
  { val: [], name: "DNISI", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XKMYA,XSIZM" },
  {
    val: [],
    name: "DOAS",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XSD25,XUHIZ,XTCRT,XSIST",
  },
  { val: [], name: "DOBUR", ix: "XUTUM,XTUMY,XKOBI,XUHIZ,XSIST" },
  { val: [], name: "DOCO", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM" },
  { val: [], name: "DOGUB", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XTAST" },
  {
    val: [],
    name: "DOHOL",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XUMAL,XHOLD,XSIST",
  },
  { val: [], name: "DOKTA", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA,XSBUR" },
  { val: [], name: "DURDO", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKAGT,XSIST" },
  { val: [], name: "DYOBY", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKMYA,XSKOC" },
  { val: [], name: "DZGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO" },
  {
    val: [],
    name: "ECILC",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "ECZYT",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XTM25,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "EDATA",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUTEK,XTKJS,XBLSM,XSANK",
  },
  { val: [], name: "EDIP", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST" },
  {
    val: [],
    name: "EGEEN",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XKTMT,XUSIN,XMESY,XSIZM",
  },
  { val: [], name: "EGEPO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XSIZM" },
  {
    val: [],
    name: "EGGUB",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XKMYA,XSIZM",
  },
  { val: [], name: "EGPRO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKMYA,XSIZM" },
  { val: [], name: "EGSER", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XTAST,XSIZM" },
  {
    val: [],
    name: "EKGYO",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUMAL,XGMYO",
  },
  { val: [], name: "ELITE", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XGIDA,XSANK" },
  { val: [], name: "EMKEL", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XMESY,XSANK" },
  {
    val: [],
    name: "ENJSA",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XUHIZ,XELKT,XSIST",
  },
  {
    val: [],
    name: "ENKAI",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUHIZ,XINSA,XSIST",
  },
  {
    val: [],
    name: "ENSRI",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKOBI,XUSIN,XTEKS,XSTKR",
  },
  { val: [], name: "EPLAS", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKMYA,XSIZM" },
  {
    val: [],
    name: "ERBOS",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XUSIN,XMANA,XSKAY",
  },
  { val: [], name: "ERCB", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XMANA" },
  {
    val: [],
    name: "EREGL",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XUSIN,XMANA",
  },
  {
    val: [],
    name: "ERSU",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XGIDA,XSKON",
  },
  { val: [], name: "ESCAR", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XSIST" },
  {
    val: [],
    name: "ESCOM",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "ESEN", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XELKT,XSANK" },
  { val: [], name: "ETILR", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIST" },
  { val: [], name: "ETYAT", ix: "XYORT" },
  { val: [], name: "EUHOL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  { val: [], name: "EUKYO", ix: "XYORT" },
  {
    val: [],
    name: "EUREN",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUSIN,XKMYA",
  },
  { val: [], name: "EUYO", ix: "XYORT" },
  { val: [], name: "EYGYO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUMAL,XGMYO" },
  {
    val: [],
    name: "FADE",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XGIDA,XSIZM",
  },
  {
    val: [],
    name: "FENER",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUHIZ,XSPOR,XSIST",
  },
  { val: [], name: "FLAP", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIST" },
  {
    val: [],
    name: "FMIZP",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUSIN,XMESY,XSKOC",
  },
  {
    val: [],
    name: "FONET",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSANK",
  },
  {
    val: [],
    name: "FORMT",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XK100,XUSIN,XMESY,XSKAY",
  },
  { val: [], name: "FRIGO", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XGIDA,XSBUR" },
  {
    val: [],
    name: "FROTO",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XUSIN,XMESY,XSKOC",
  },
  {
    val: [],
    name: "GARAN",
    ix: "XU100,XU050,XU030,XLBNK,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XUMAL,XBANK",
  },
  { val: [], name: "GARFA", ix: "XUTUM,XTUMY,XKURY,XUMAL,XFINK" },
  { val: [], name: "GEDIK", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XAKUR" },
  { val: [], name: "GEDZA", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA" },
  {
    val: [],
    name: "GENIL",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUHIZ,XTCRT,XSANK",
  },
  { val: [], name: "GENTS", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XKAGT" },
  { val: [], name: "GEREL", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY" },
  {
    val: [],
    name: "GESAN",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUHIZ,XINSA,XSANK",
  },
  { val: [], name: "GLBMD", ix: "XUTUM,XTUMY,XBANA,XUMAL,XAKUR" },
  { val: [], name: "GLCVY", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XUMAL" },
  { val: [], name: "GLRYH", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  {
    val: [],
    name: "GLYHO",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XHOLD,XSIST",
  },
  { val: [], name: "GMTAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XTCRT" },
  { val: [], name: "GOLTS", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XTAST" },
  {
    val: [],
    name: "GOODY",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XKMYA,XSIST",
  },
  {
    val: [],
    name: "GOZDE",
    ix: "XUTUM,XTUMY,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUMAL,XHOLD",
  },
  { val: [], name: "GRNYO", ix: "XYORT" },
  { val: [], name: "GRSEL", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XULAS,XSIST" },
  { val: [], name: "GSDDE", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XULAS,XSIST" },
  {
    val: [],
    name: "GSDHO",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUMAL,XHOLD,XSIST",
  },
  { val: [], name: "GSRAY", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSPOR,XSIST" },
  {
    val: [],
    name: "GUBRF",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XKMYA,XSIST",
  },
  {
    val: [],
    name: "GWIND",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XTMTU,XTM25,XKTUM,XK100,XK050,XSRDK,XKTMT,XKURY,XUSRD,XUHIZ,XELKT,XSBAL",
  },
  { val: [], name: "GZNMI", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XSANT" },
  {
    val: [],
    name: "HALKB",
    ix: "XU100,XU050,XLBNK,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XBANK",
  },
  { val: [], name: "HATEK", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS" },
  { val: [], name: "HDFGS", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD" },
  { val: [], name: "HEDEF", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XHOLD,XSIST" },
  {
    val: [],
    name: "HEKTS",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSIN,XKMYA,XSKOC",
  },
  {
    val: [],
    name: "HKTM",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XMESY,XSKOC",
  },
  { val: [], name: "HLGYO", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUMAL,XGMYO" },
  {
    val: [],
    name: "HTTBT",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "HUBVC", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD" },
  {
    val: [],
    name: "HUNER",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XELKT,XSIST",
  },
  { val: [], name: "HURGZ", ix: "XUTUM,XTUMY,XBANA,XKURY,XUHIZ,XSIST" },
  { val: [], name: "ICBCT", ix: "XUTUM,XTUMY,XBANA,XUMAL,XBANK" },
  { val: [], name: "IDEAS", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIST" },
  { val: [], name: "IDGYO", ix: "XUTUM,XTUMY,XUMAL,XGMYO" },
  { val: [], name: "IEYHO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSIST" },
  { val: [], name: "IHAAS", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XSIST" },
  {
    val: [],
    name: "IHEVA",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XKURY,XUSIN,XMESY,XSIST",
  },
  { val: [], name: "IHGZT", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIST" },
  { val: [], name: "IHLAS", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XHOLD,XSIST" },
  { val: [], name: "IHLGM", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XSIZM" },
  { val: [], name: "IHYAY", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  { val: [], name: "IMASM", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUSIN,XMESY,XSKON" },
  {
    val: [],
    name: "INDES",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "INFO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XAKUR" },
  { val: [], name: "INTEM", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XTCRT,XSIST" },
  { val: [], name: "INVEO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XHOLD,XSIST" },
  { val: [], name: "INVES", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XHOLD,XSIST" },
  {
    val: [],
    name: "IPEKE",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSIN,XMADN,XSANK",
  },
  { val: [], name: "ISATR", ix: "XUTUM,XTUMY,XUMAL,XBANK" },
  { val: [], name: "ISBTR", ix: "XUTUM,XTUMY,XUMAL,XBANK" },
  {
    val: [],
    name: "ISCTR",
    ix: "XU100,XU050,XU030,XLBNK,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSRD,XSD25,XUMAL,XBANK",
  },
  {
    val: [],
    name: "ISDMR",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XUSIN,XMANA",
  },
  {
    val: [],
    name: "ISFIN",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUMAL,XFINK",
  },
  { val: [], name: "ISGSY", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD" },
  {
    val: [],
    name: "ISGYO",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XUMAL,XGMYO",
  },
  {
    val: [],
    name: "ISKPL",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUSIN,XKMYA,XSKOC",
  },
  {
    val: [],
    name: "ISMEN",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XTM25,XUSRD,XUMAL,XAKUR",
  },
  {
    val: [],
    name: "ISSEN",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XTEKS,XSBAL",
  },
  { val: [], name: "ISYAT", ix: "XYORT" },
  { val: [], name: "ITTFH", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSKON" },
  { val: [], name: "IZFAS", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XKMYA,XSIZM" },
  { val: [], name: "IZINV", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XSIST" },
  { val: [], name: "IZMDC", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA,XSIZM" },
  {
    val: [],
    name: "JANTS",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XKTUM,XK100,XK050,XK030,XKTMT,XUSIN,XMESY,XSAYD",
  },
  { val: [], name: "KAPLM", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKAGT,XSIZM" },
  { val: [], name: "KAREL", ix: "XUTUM,XTUMY,XYLDZ,XUTEK,XTKJS,XBLSM,XSANK" },
  {
    val: [],
    name: "KARSN",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUSIN,XMESY,XSBUR",
  },
  { val: [], name: "KARTN", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XKAGT,XSKOC" },
  {
    val: [],
    name: "KARYE",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUHIZ,XELKT,XSMNS",
  },
  { val: [], name: "KATMR", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMESY,XSIZM" },
  {
    val: [],
    name: "KCAER",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUSIN,XMANA,XSIZM",
  },
  {
    val: [],
    name: "KCHOL",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "KERVT",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUSIN,XGIDA,XSBUR",
  },
  {
    val: [],
    name: "KFEIN",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XK100,XKTMT,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "KGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO" },
  { val: [], name: "KIMMR", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XTCRT" },
  { val: [], name: "KLGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
  {
    val: [],
    name: "KLKIM",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XTAST,XSIST",
  },
  { val: [], name: "KLMSN", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUSIN,XMESY,XSMNS" },
  {
    val: [],
    name: "KLRHO",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XUMAL,XHOLD,XSIST",
  },
  { val: [], name: "KLSYN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XKAGT,XSTKR" },
  {
    val: [],
    name: "KMPUR",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XTMTU,XKURY,XUSIN,XKMYA,XSKOC",
  },
  { val: [], name: "KNFRT", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XGIDA,XSDNZ" },
  {
    val: [],
    name: "KONKA",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XKAGT,XSKON",
  },
  {
    val: [],
    name: "KONTR",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XKURY,XUHIZ,XSIST",
  },
  {
    val: [],
    name: "KONYA",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XTAST,XSKON",
  },
  {
    val: [],
    name: "KORDS",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XSD25,XUSIN,XTEKS,XSKOC",
  },
  {
    val: [],
    name: "KOZAA",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSIN,XMADN,XSANK",
  },
  {
    val: [],
    name: "KOZAL",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSIN,XMADN,XSIZM",
  },
  { val: [], name: "KRDMA", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XMANA" },
  { val: [], name: "KRDMB", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUSIN,XMANA" },
  {
    val: [],
    name: "KRDMD",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XUSIN,XMANA",
  },
  { val: [], name: "KRGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO" },
  {
    val: [],
    name: "KRONT",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "KRPLS", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XKMYA,XSIST" },
  {
    val: [],
    name: "KRSTL",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUSIN,XGIDA,XSBAL",
  },
  { val: [], name: "KRTEK", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS,XSKAY" },
  {
    val: [],
    name: "KRVGD",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XUSIN,XGIDA,XSIST",
  },
  { val: [], name: "KTSKR", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUSIN,XGIDA" },
  { val: [], name: "KUTPO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUSIN,XTAST" },
  { val: [], name: "KUYAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XINSA,XSIST" },
  {
    val: [],
    name: "KZBGY",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XUMAL,XGMYO",
  },
  { val: [], name: "LIDER", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XUHIZ,XSIST" },
  { val: [], name: "LIDFA", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK" },
  {
    val: [],
    name: "LINK",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "LKMNH",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XSANK",
  },
  {
    val: [],
    name: "LOGO",
    ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUSRD,XUTEK,XTKJS,XBLSM,XSKOC",
  },
  {
    val: [],
    name: "LUKSK",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XTEKS,XSKAY",
  },
  { val: [], name: "MAALT", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT" },
  { val: [], name: "MACKO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XSIST" },
  { val: [], name: "MAGEN", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUHIZ,XELKT,XSANK" },
  {
    val: [],
    name: "MAKIM",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XMESY,XSANK",
  },
  { val: [], name: "MAKTK", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XMESY,XSKOC" },
  {
    val: [],
    name: "MANAS",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUTEK,XTKJS,XBLSM,XSANK",
  },
  { val: [], name: "MARKA", ix: "XUTUM,XTUMY,XUMAL,XHOLD,XSIST" },
  { val: [], name: "MARTI", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XTRZM,XSANT" },
  {
    val: [],
    name: "MAVI",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XSD25,XUHIZ,XTCRT",
  },
  {
    val: [],
    name: "MEDTR",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XKMYA,XSIZM",
  },
  { val: [], name: "MEGAP", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XTEKS,XSIST" },
  { val: [], name: "MEPET", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTCRT" },
  {
    val: [],
    name: "MERCN",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XKMYA,XSDNZ",
  },
  { val: [], name: "MERIT", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSIST" },
  {
    val: [],
    name: "MERKO",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XGIDA,XSBUR",
  },
  { val: [], name: "METRO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  { val: [], name: "METUR", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  {
    val: [],
    name: "MGROS",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUHIZ,XTCRT",
  },
  {
    val: [],
    name: "MIATK",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSANK",
  },
  { val: [], name: "MIPAZ", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XTCRT" },
  { val: [], name: "MNDRS", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS,XSDNZ" },
  { val: [], name: "MNDTR", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XKAGT,XSIZM" },
  {
    val: [],
    name: "MOBTL",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "MPARK",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XSRDK,XUSRD,XUHIZ,XSIST",
  },
  { val: [], name: "MRGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
  { val: [], name: "MRSHL", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKMYA,XSKOC" },
  { val: [], name: "MSGYO", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUMAL,XGMYO" },
  {
    val: [],
    name: "MTRKS",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "MTRYO", ix: "XYORT" },
  { val: [], name: "MZHLD", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIZM" },
  { val: [], name: "NATEN", ix: "XUTUM,XTUMY,XYLDZ,XUHIZ,XELKT,XSANK" },
  {
    val: [],
    name: "NETAS",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XSRDK,XUSRD,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "NIBAS", ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XTAST" },
  {
    val: [],
    name: "NTGAZ",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XELKT,XSIST",
  },
  {
    val: [],
    name: "NTHOL",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUMAL,XHOLD,XSIST",
  },
  { val: [], name: "NUGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
  {
    val: [],
    name: "NUHCM",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XUSIN,XTAST,XSKOC",
  },
  {
    val: [],
    name: "OBASE",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XTMTU,XKTUM,XKTMT,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "ODAS",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUHIZ,XELKT",
  },
  {
    val: [],
    name: "ONCSM",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XKMYA,XSANK",
  },
  { val: [], name: "ORCAY", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKOBI,XUSIN,XGIDA" },
  {
    val: [],
    name: "ORGE",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUHIZ,XINSA,XSIST",
  },
  { val: [], name: "OSMEN", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUMAL,XAKUR" },
  { val: [], name: "OSTIM", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSANK" },
  {
    val: [],
    name: "OTKAR",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XUSIN,XMESY",
  },
  {
    val: [],
    name: "OYAKC",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XTAST,XSANK",
  },
  { val: [], name: "OYAYO", ix: "XYORT" },
  {
    val: [],
    name: "OYLUM",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XGIDA,XSKAY",
  },
  { val: [], name: "OYYAT", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XAKUR" },
  { val: [], name: "OZGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO" },
  {
    val: [],
    name: "OZKGY",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XK050,XUMAL,XGMYO",
  },
  {
    val: [],
    name: "OZRDN",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSKOC",
  },
  { val: [], name: "OZSUB", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XSIZM" },
  { val: [], name: "PAGYO", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUMAL,XGMYO" },
  { val: [], name: "PAMEL", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XELKT" },
  {
    val: [],
    name: "PAPIL",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XUTEK,XTKJS,XBLSM,XSANK",
  },
  { val: [], name: "PARSN", ix: "XUTUM,XTUMY,XYLDZ,XUSIN,XMESY,XSIST" },
  { val: [], name: "PCILT", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XSIST" },
  { val: [], name: "PEGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
  { val: [], name: "PEKGY", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUMAL,XGMYO" },
  {
    val: [],
    name: "PENGD",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUSIN,XGIDA,XSBUR",
  },
  {
    val: [],
    name: "PENTA",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "PETKM",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSIN,XKMYA,XSIZM",
  },
  {
    val: [],
    name: "PETUN",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XKTMT,XKURY,XUSIN,XGIDA,XSIZM",
  },
  {
    val: [],
    name: "PGSUS",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XUHIZ,XULAS,XSIST",
  },
  { val: [], name: "PINSU", ix: "XUTUM,XTUMY,XBANA,XKURY,XUSIN,XGIDA,XSIZM" },
  {
    val: [],
    name: "PKART",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST",
  },
  { val: [], name: "PKENT", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT" },
  { val: [], name: "PLTUR", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XSIST" },
  {
    val: [],
    name: "PNLSN",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XMANA,XSANK",
  },
  {
    val: [],
    name: "PNSUT",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XSRDK,XKTMT,XKURY,XUSRD,XUSIN,XGIDA,XSIZM",
  },
  {
    val: [],
    name: "POLHO",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XSRDK,XKTMT,XUSRD,XUMAL,XHOLD,XSKOC",
  },
  {
    val: [],
    name: "POLTK",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUSIN,XKMYA,XSIST",
  },
  { val: [], name: "PRDGS", ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUMAL,XHOLD" },
  {
    val: [],
    name: "PRKAB",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XKURY,XUSIN,XMESY,XSBUR",
  },
  { val: [], name: "PRKME", ix: "XUTUM,XTUMY,XYLDZ,XKOBI,XKURY,XUSIN,XMADN" },
  { val: [], name: "PRZMA", ix: "XUTUM,XTUMY,XKOBI,XUSIN,XKAGT,XSIST" },
  { val: [], name: "PSDTC", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XTCRT,XSIZM" },
  {
    val: [],
    name: "PSGYO",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUMAL,XGMYO",
  },
  {
    val: [],
    name: "QUAGR",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XK050,XKURY,XUSIN,XTAST,XSAYD",
  },
  { val: [], name: "RALYH", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XHOLD,XSANK" },
  { val: [], name: "RAYSG", ix: "XUTUM,XTUMY,XUMAL,XSGRT" },
  { val: [], name: "RHEAG", ix: "XUTUM,XTUMY,XKTUM,XUMAL,XHOLD" },
  {
    val: [],
    name: "RNPOL",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKOBI,XUSIN,XKMYA,XSKAY",
  },
  {
    val: [],
    name: "RODRG",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XTEKS,XSIST",
  },
  { val: [], name: "RTALB", ix: "XUTUM,XTUMY,XBANA,XUSIN,XKMYA,XSKOC" },
  {
    val: [],
    name: "RUBNS",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XTEKS",
  },
  { val: [], name: "RYGYO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO" },
  { val: [], name: "RYSAS", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XULAS,XSIST" },
  {
    val: [],
    name: "SAFKR",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XMESY,XSIZM",
  },
  {
    val: [],
    name: "SAHOL",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "SAMAT",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKAGT,XSANK",
  },
  { val: [], name: "SANEL", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST" },
  {
    val: [],
    name: "SANFM",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XKTUM,XUSIN,XKMYA,XSTKR",
  },
  {
    val: [],
    name: "SANKO",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XKTMT,XUHIZ,XTCRT",
  },
  { val: [], name: "SARKY", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUSIN,XMANA,XSKOC" },
  {
    val: [],
    name: "SASA",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUSIN,XKMYA,XSADA",
  },
  {
    val: [],
    name: "SAYAS",
    ix: "XUTUM,XTUMY,XYLDZ,XKOBI,XKTUM,XUSIN,XMESY,XSIZM",
  },
  { val: [], name: "SDTTR", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUTEK,XTKJS,XSANK" },
  { val: [], name: "SEGYO", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUMAL,XGMYO" },
  { val: [], name: "SEKFK", ix: "XUTUM,XTUMY,XBANA,XKURY,XUMAL,XFINK" },
  { val: [], name: "SEKUR", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XKMYA" },
  {
    val: [],
    name: "SELEC",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XTMTU,XKTUM,XK100,XK050,XKTMT,XUHIZ,XTCRT,XSIST",
  },
  { val: [], name: "SELGD", ix: "XUTUM,XTUMY,XKOBI,XKTUM,XUSIN,XGIDA,XSAYD" },
  {
    val: [],
    name: "SELVA",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUSIN,XGIDA,XSKON",
  },
  { val: [], name: "SEYKM", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XKMYA,XSMNS" },
  { val: [], name: "SILVR", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY" },
  {
    val: [],
    name: "SISE",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XSD25,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "SKBNK",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XUMAL,XBANK",
  },
  { val: [], name: "SKTAS", ix: "XUTUM,XTUMY,XBANA,XUSIN,XTEKS,XSAYD" },
  {
    val: [],
    name: "SMART",
    ix: "XUTUM,XTUMY,XBANA,XKTUM,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "SMRTG",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUHIZ,XELKT,XSKOC",
  },
  {
    val: [],
    name: "SNGYO",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XUMAL,XGMYO",
  },
  { val: [], name: "SNICA", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUSIN,XMESY,XSMNS" },
  {
    val: [],
    name: "SOKE",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUSIN,XGIDA,XSAYD",
  },
  {
    val: [],
    name: "SOKM",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUHIZ,XTCRT",
  },
  { val: [], name: "SONME", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XSBUR" },
  { val: [], name: "SRVGY", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUMAL,XGMYO" },
  {
    val: [],
    name: "SUNTK",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XTEKS,XSIZM",
  },
  {
    val: [],
    name: "SUWEN",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XTCRT",
  },
  {
    val: [],
    name: "TATGD",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKURY,XUSRD,XUSIN,XGIDA,XSBUR",
  },
  {
    val: [],
    name: "TAVHL",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XSD25,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "TCELL",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XUSRD,XSD25,XUHIZ,XILTM,XSIST",
  },
  { val: [], name: "TDGYO", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUMAL,XGMYO" },
  { val: [], name: "TEKTU", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XTRZM,XSANT" },
  { val: [], name: "TERA", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUMAL,XAKUR" },
  { val: [], name: "TETMT", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XGIDA,XSANK" },
  {
    val: [],
    name: "TEZOL",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUSIN,XKAGT,XSIZM",
  },
  { val: [], name: "TGSAS", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUHIZ,XTCRT,XSIST" },
  {
    val: [],
    name: "THYAO",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XUSRD,XSD25,XUHIZ,XULAS,XSIST",
  },
  {
    val: [],
    name: "TKFEN",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XUSRD,XSD25,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "TKNSA",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUHIZ,XTCRT",
  },
  { val: [], name: "TLMAN", ix: "XUTUM,XTUMY,XBANA,XTMTU,XUHIZ,XULAS" },
  { val: [], name: "TMPOL", ix: "XUTUM,XTUMY,XBANA,XKOBI,XUSIN,XKMYA,XSKAY" },
  {
    val: [],
    name: "TMSN",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XMESY,XSKON",
  },
  { val: [], name: "TNZTP", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUHIZ,XSIZM" },
  {
    val: [],
    name: "TOASO",
    ix: "XU100,XU050,XU030,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XUSIN,XMESY,XSBUR",
  },
  {
    val: [],
    name: "TRCAS",
    ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XK100,XKURY,XUMAL,XHOLD,XSIST",
  },
  { val: [], name: "TRGYO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO" },
  {
    val: [],
    name: "TRILC",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XK050,XUSIN,XKMYA,XSANK",
  },
  { val: [], name: "TSGYO", ix: "XUTUM,XTUMY,XYLDZ,XUMAL,XGMYO" },
  {
    val: [],
    name: "TSKB",
    ix: "XU100,XU050,XLBNK,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUMAL,XBANK",
  },
  {
    val: [],
    name: "TSPOR",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUHIZ,XSPOR",
  },
  {
    val: [],
    name: "TTKOM",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XTMTU,XTM25,XKURY,XUSRD,XUHIZ,XILTM,XSANK",
  },
  {
    val: [],
    name: "TTRAK",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XKURY,XUSRD,XSD25,XUSIN,XMESY,XSANK",
  },
  { val: [], name: "TUCLK", ix: "XUTUM,XTUMY,XBANA,XUSIN,XMANA,XSIST" },
  {
    val: [],
    name: "TUKAS",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKTUM,XK100,XK050,XUSIN,XGIDA,XSIZM",
  },
  {
    val: [],
    name: "TUPRS",
    ix: "XU100,XU050,XU030,X10XB,XUTUM,X100S,X100C,X030S,X030C,X030EA,XK030EA,XYLDZ,XKTUM,XK100,XK050,XK030,XSRDK,XKURY,XUSRD,XUSIN,XKMYA,XSKOC",
  },
  {
    val: [],
    name: "TUREX",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XUHIZ,XULAS,XSIST",
  },
  { val: [], name: "TURGG", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST" },
  {
    val: [],
    name: "TURSG",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XKURY,XUMAL,XSGRT",
  },
  { val: [], name: "UFUK", ix: "XUTUM,XTUMY,XUMAL,XHOLD,XSIST" },
  { val: [], name: "ULAS", ix: "XUTUM,XTUMY,XUHIZ,XTRZM,XSANK" },
  {
    val: [],
    name: "ULKER",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUSIN,XGIDA,XSANK",
  },
  { val: [], name: "ULUFA", ix: "XUTUM,XTUMY,XBANA,XHARZ,XUMAL,XFINK" },
  { val: [], name: "ULUSE", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMESY,XSANK" },
  { val: [], name: "ULUUN", ix: "XUTUM,XTUMY,XBANA,XUSIN,XGIDA" },
  { val: [], name: "UNLU", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XHOLD,XSIST" },
  { val: [], name: "USAK", ix: "XUTUM,XTUMY,XBANA,XKTUM,XK100,XUSIN,XTAST" },
  {
    val: [],
    name: "VAKBN",
    ix: "XU100,XU050,XLBNK,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUMAL,XBANK",
  },
  { val: [], name: "VAKFN", ix: "XUTUM,XTUMY,XBANA,XUMAL,XFINK" },
  { val: [], name: "VAKKO", ix: "XUTUM,XTUMY,XYLDZ,XKTUM,XUHIZ,XTCRT" },
  { val: [], name: "VANGD", ix: "XUTUM,XTUMY,XKOBI,XUSIN,XGIDA" },
  {
    val: [],
    name: "VBTYZ",
    ix: "XUTUM,XTUMY,XBANA,XHARZ,XKTUM,XUTEK,XTKJS,XBLSM,XSIST",
  },
  {
    val: [],
    name: "VERTU",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XK100,XKTMT,XUMAL,XHOLD",
  },
  {
    val: [],
    name: "VERUS",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XK100,XKTMT,XUMAL,XHOLD,XSIST",
  },
  {
    val: [],
    name: "VESBE",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XTMTU,XTM25,XKTUM,XK100,XK050,XK030,XSRDK,XKTMT,XUSRD,XUSIN,XMESY,XSMNS",
  },
  {
    val: [],
    name: "VESTL",
    ix: "XU100,XU050,XUTUM,XELOT,XELOS,XELOC,XYUZO,X100S,X100C,XYLDZ,XKURY,XUSRD,XSD25,XUSIN,XMESY,XSMNS",
  },
  { val: [], name: "VKFYO", ix: "XYORT" },
  { val: [], name: "VKGYO", ix: "XUTUM,XTUMY,XYLDZ,XKURY,XUMAL,XGMYO" },
  { val: [], name: "VKING", ix: "XUTUM,XTUMY,XUSIN,XKAGT,XSIZM" },
  {
    val: [],
    name: "YAPRK",
    ix: "XUTUM,XTUMY,XBANA,XKOBI,XTMTU,XKTUM,XKTMT,XUSIN,XSBAL",
  },
  {
    val: [],
    name: "YATAS",
    ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XTEKS,XSKAY",
  },
  { val: [], name: "YAYLA", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSANK" },
  {
    val: [],
    name: "YEOTK",
    ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XKTUM,XK100,XUHIZ,XSIST",
  },
  { val: [], name: "YESIL", ix: "XUTUM,XTUMY,XBANA,XUMAL,XHOLD,XSIST" },
  { val: [], name: "YGGYO", ix: "XUTUM,XTUMY,XYLDZ,XTMTU,XUMAL,XGMYO" },
  { val: [], name: "YGYO", ix: "XUTUM,XTUMY,XBANA,XUMAL,XGMYO" },
  {
    val: [],
    name: "YKBNK",
    ix: "XU100,XU050,XU030,XLBNK,XUTUM,X100S,X100C,X030S,X030C,X030EA,XYLDZ,XKURY,XUSRD,XUMAL,XBANK",
  },
  { val: [], name: "YKSLN", ix: "XUTUM,XTUMY,XBANA,XKTUM,XUSIN,XMANA,XSIST" },
  {
    val: [],
    name: "YUNSA",
    ix: "XUTUM,XTUMY,XBANA,XTMTU,XKTUM,XK100,XKTMT,XUSIN,XTEKS,XSTKR",
  },
  { val: [], name: "YYAPI", ix: "XUTUM,XTUMY,XBANA,XUHIZ,XINSA,XSIST" },
  {
    val: [],
    name: "YYLGD",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XK030EA,XYLDZ,XHARZ,XKTUM,XK100,XK050,XK030,XUSIN,XGIDA",
  },
  {
    val: [],
    name: "ZOREN",
    ix: "XU100,XUTUM,XYUZO,X100S,X100C,XYLDZ,XUSRD,XUHIZ,XELKT,XSBUR",
  },
  { val: [], name: "ZRGYO", ix: "XUTUM,XTUMY,XYLDZ,XHARZ,XUMAL,XGMYO" },
];

ImkbEndeksSenetler.forEach((element) => {
  let list = element.ix.split(",");
  element.val = list.filter((x) => {
    return SentimentEndeksler.some((s) => s.value == x);
  });
});

export default ImkbEndeksSenetler;
