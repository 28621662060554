import { Page1 } from "./pages/page1";
import "./index.css";
import { Page2 } from "./pages/page2";
import { Page3 } from "./pages/page3";
import { Page4 } from "./pages/page4";
import { Page5 } from "./pages/page5";
import { Page6 } from "./pages/page6";
import { Page7 } from "./pages/page7";
import { Page8 } from "./pages/page8";
import { Page9 } from "./pages/page9";
import { Page10 } from "./pages/page10";
import { Page11 } from "./pages/page11";
import { PageStockEnter } from "./pages/page-stock-enter";
import {
  query,
  collection,
  limit,
  getDocs,
  orderBy,
  where,
  setDoc,
  doc,
  getDoc,
} from "firebase/firestore";
import { useCallback, useEffect, useRef, useState } from "react";
import { auth, fireStore } from "../../../firebase";
import { PageStockDetails } from "./pages/page-stock-detail";
import { PageStockContinue } from "./pages/page-stock-continue";
import { Page12 } from "./pages/page12";
import { Page13 } from "./pages/page13";
import _, { set } from "lodash";
import moment, { weekdays } from "moment";
import { Page14 } from "./pages/page14";
import { Page16 } from "./pages/page16";
import { Page17 } from "./pages/page17";
import { PageIndex } from "./components/PageIndex";
import { PageSettings } from "./components/PageSettings";
import Moveable from "react-moveable";
import { SectorDetail } from "./pages/sector-detail";
import { useSearchParams } from "react-router-dom";
import React from "react";
import { PreviosPerformance } from "./pages/page-previous-performance";
import { previousFridayRange } from "./util";
import { useChartDateContext } from "./chart-date-context";
const Weekly = () => {
  const [bist30Signal, setBist30Signal] = useState<any>(null);
  const [bist70Signal, setBist70Signal] = useState<any>(null);
  const [bist30Continue, setBist30Continue] = useState<any>(null);
  const [bist70Continue, setBist70Continue] = useState<any>(null);
  const [bistWeeklyReport, setBistWeeklyReport] = useState<any>(null);
  const [weekData, setWeekData] = useState<any>(null);
  const [isPreview, setIsPreview] = useState<boolean>(false);
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [detailsLoading, setDetailsLoading] = useState<boolean>(true);
  const [pageIndexes, setPageIndexes] = useState<any>([]);
  const [selectedSectors, setSelectedSectors] = useState<any>([]);
  const [sector1Period, setSector1Period] = useState<any>("weekly");
  const [sector2Period, setSector2Period] = useState<any>("weekly");
  const [sector3Period, setSector3Period] = useState<any>("weekly");
  const [searchParams, setSearchParams] = useSearchParams();
  const { chartDate } = useChartDateContext();
  const [bulletinId, setBulletinId] = React.useState<string | null>(
    searchParams.get("id")
  );
  const [ignoredStocks, setIgnoredStocks] = useState<any>({});
  const pageDataRef = useRef<any>({});
  const pageSaveRef = useRef<any>({});
  const [stockOrders, setStockOrders] = useState<any>({});
  const [selectedSectorDetails, setSelectedSectorDetails] = useState<any>(
    Array(3).fill(null)
  );
  const onOrderChanged = (saveKey: string, stock: string, value: string) => {
    const newOrder = { ...stockOrders };
    if (!newOrder[saveKey]) newOrder[saveKey] = {};
    newOrder[saveKey][stock] = parseInt(value);
    setStockOrders({ ...newOrder });
  };
  const onIgnoreStockChange = (
    key: string,
    stock: string,
    ignored: boolean
  ) => {
    if (!ignored) {
      if (!ignoredStocks[key]) ignoredStocks[key] = [];
      ignoredStocks[key].push(stock);
    } else {
      if (ignoredStocks[key].includes(stock)) {
        ignoredStocks[key] = ignoredStocks[key].filter(
          (item: string) => item !== stock
        );
      }
    }
    setIgnoredStocks({ ...ignoredStocks });
    onPageDataUpdated("ignoredStocks", "keys", ignoredStocks);
  };
  const save = () => {
    if (!bulletinId) return;
    const weekId = moment().year() + "_" + moment().week();
    const ref = doc(
      fireStore,
      "BIST_WEEKLY_REPORT_DATA",
      bulletinId?.toString()
    );
    setDoc(
      ref,
      {
        content: pageDataRef.current,
        updatedAt: new Date(),
        lastUpdateUser: auth.currentUser?.email ?? "",
      },
      { merge: true }
    );
  };
  const saveDebounced = useCallback(_.debounce(save, 5 * 1000), []);
  const onPageDataUpdated = (page: string, dataName: string, value: string) => {
    if (!pageDataRef.current[page]) pageDataRef.current[page] = {};
    if (!pageSaveRef.current) pageSaveRef.current = {};
    if (!pageSaveRef.current[page]) pageSaveRef.current[page] = {};
    pageDataRef.current[page][dataName] = value;
    pageSaveRef.current[page][dataName] = value;
    saveDebounced();
  };

  const registerPageIndex = (
    pageIndex: number,
    subIndex: number,
    title: string
  ) => {
    pageIndexes[`page-${pageIndex}-${subIndex}`] = {
      pageIndex,
      subIndex,
      title,
    };
    setPageIndexes({ ...pageIndexes });
  };
  const onPreivewChange = (e: boolean) => {
    setIsPreview(e);
  };

  useEffect(() => {
    const { previousFriday, range } = previousFridayRange(chartDate);
    console.log(previousFriday.toDate(), range.toDate());
    const q = query(
      collection(fireStore, "NS_SIGNALS_30_1W_P99"),
      orderBy("dateTime", "desc"),
      where("reverse", "==", 0),
      where("dateTime", ">=", previousFriday.toDate()),
      where("dateTime", "<=", range.toDate()),
      limit(1)
    );

    getDocs(q).then((querySnapshot: any) => {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      const continued: any = [];
      data.basket?.forEach((stock: string) => {
        if (!data.incoming?.includes(stock)) continued.push(stock);
      });
      setBist30Continue(continued);
      setBist30Signal(data);
    });
  }, [chartDate]);
  useEffect(() => {
    const { previousFriday, range } = previousFridayRange(chartDate);
    const q = query(
      collection(fireStore, "NS_SIGNALS_70_1W_P99"),
      orderBy("dateTime", "desc"),
      where("reverse", "==", 0),
      where("dateTime", ">=", previousFriday.toDate()),
      where("dateTime", "<=", range.toDate()),
      limit(1)
    );

    getDocs(q).then((querySnapshot: any) => {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      const continued: any = [];

      data.basket?.forEach((stock: string) => {
        if (!data.incoming?.includes(stock)) continued.push(stock);
      });
      setBist70Continue(continued);
      setBist70Signal(data);
    });
  }, [chartDate]);
  useEffect(() => {
    const q = query(
      collection(fireStore, "BIST_WEEKLY_REPORT"),
      orderBy("date", "desc"),
      limit(1)
    );

    getDocs(q).then((querySnapshot: any) => {
      const doc = querySnapshot.docs[0];
      const data = doc.data();
      setBistWeeklyReport(data?.data);
      setTimeout(() => {
        setDetailsLoading(false);
      }, 100);
    });
  }, []);
  useEffect(() => {
    if (!bulletinId) return;
    const ref = doc(fireStore, "BIST_WEEKLY_REPORT_DATA", bulletinId);
    getDoc(ref).then((doc) => {
      if (doc.exists()) {
        const content = doc.data()?.content ?? {};
        setWeekData(content);
        const page14 = content?.page14;
        if (content) pageSaveRef.current = content;
        else pageSaveRef.current = {};
        const sectors = [
          page14?.sector1,
          page14?.sector2,
          page14?.sector3,
        ].filter((item: any) => item);
        const orders: any = {};
        if (content.continue30_orders)
          orders["continue30"] = content.continue30_orders;

        if (content.continue70_orders)
          orders["continue70"] = content.continue70_orders;

        if (content.decreasingRed30_orders)
          orders["decreasingRed30"] = content.decreasingRed30_orders;

        if (content.decreasingRed70_orders)
          orders["decreasingRed70"] = content.decreasingRed70_orders;

        if (content.redtoBlue30_orders)
          orders["redtoBlue30"] = content.redtoBlue30_orders;

        if (content.redtoBlue70_orders)
          orders["redtoBlue70"] = content.redtoBlue70_orders;

        setStockOrders(orders);
        setSelectedSectors(sectors);
        setSector1Period(page14?.sector1Period ?? "weekly");
        setSector2Period(page14?.sector2Period ?? "weekly");
        setSector3Period(page14?.sector3Period ?? "weekly");
        if (content?.ignoredStocks)
          setIgnoredStocks(content?.ignoredStocks?.keys ?? {});
      }
      setTimeout(() => {
        setDataLoading(false);
      }, 100);
    });
  }, []);
  const getOrderedStocks = (saveKey: string, stocks: string[]) => {
    stocks?.sort((a: any, b: any) => {
      const aStockName = a?.symbol ? a.symbol : a;
      const bStockName = b?.symbol ? b.symbol : b;

      const aKey = stockOrders[saveKey]?.[aStockName] ?? 1;
      const bKey = stockOrders[saveKey]?.[bStockName] ?? 1;
      return aKey - bKey;
    });
    return stocks;
  };
  if (dataLoading || detailsLoading) return null;

  return (
    <>
      <PageIndex
        goToPage={(index: string) => {
          const element: any = document.getElementById(index);
          if (element) element.scrollIntoView();
        }}
        pageIndexes={pageIndexes}
      />
      <div className={"page-container " + (isPreview ? "weekly-preview" : "")}>
        <PageSettings
          onPreivewChange={onPreivewChange}
          data={pageSaveRef.current}
        />
        <Page1
          pageIndex={0}
          subIndex={0}
          registerPageIndex={registerPageIndex}
        />
        <Page2
          pageIndex={1}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
          details={bistWeeklyReport?.bist}
        />
        <Page3
          pageIndex={2}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
        />
        <Page4
          pageIndex={3}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
        />
        <Page5
          pageIndex={4}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
        />
        <Page6
          pageIndex={5}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
        />
        <Page7
          pageIndex={6}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
        />
        <Page8
          pageIndex={7}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
        />
        <Page9
          pageIndex={8}
          subIndex={0}
          registerPageIndex={registerPageIndex}
        />
        <Page10
          pageIndex={9}
          subIndex={0}
          registerPageIndex={registerPageIndex}
        />
        <Page11
          pageIndex={10}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
        />
        <PageStockEnter
          pageIndex={11}
          subIndex={0}
          saveKey="bistStockEnter30"
          weekData={weekData}
          onPageDataUpdated={onPageDataUpdated}
          registerPageIndex={registerPageIndex}
          list="30"
          data={bist30Signal}
          details={bistWeeklyReport?.basket030}
        />

        <>
          {bist30Signal?.incoming?.map((stock: string, index: number) => {
            return (
              <PageStockDetails
                list="30"
                incoming={true}
                stock={stock}
                data={bist30Signal}
                details={bistWeeklyReport?.basket030?.find(
                  (item: any) => item.symbol === stock
                )}
                onPageDataUpdated={onPageDataUpdated}
                weekData={weekData}
                pageIndex={12}
                subIndex={index}
                registerPageIndex={registerPageIndex}
              />
            );
          })}
          {bist30Signal?.outgoing?.map((stock: string, index: number) => {
            return (
              <PageStockDetails
                list="30"
                incoming={false}
                stock={stock}
                data={bist30Signal}
                onPageDataUpdated={onPageDataUpdated}
                weekData={weekData}
                pageIndex={13}
                subIndex={index}
                registerPageIndex={registerPageIndex}
                details={bistWeeklyReport?.basket030?.find(
                  (item: any) => item.symbol === stock
                )}
              />
            );
          })}
        </>
        <PageStockContinue
          list="30"
          stocks={getOrderedStocks("continue30", bist30Continue)?.slice(0, 3)}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
          saveKey="continue30"
          pageIndex={14}
          subIndex={0}
          shortTitle="DEVAM"
          registerPageIndex={registerPageIndex}
          ignoredStocks={ignoredStocks}
          onIgnoreStockChange={onIgnoreStockChange}
          orderChanged={onOrderChanged}
          stockCount={bist30Continue?.length}
          printChange={true}
        />
        {bist30Continue?.length > 3 && (
          <PageStockContinue
            list="30"
            stocks={getOrderedStocks("continue30", bist30Continue)?.slice(3, 6)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="continue30"
            pageIndex={15}
            subIndex={0}
            shortTitle="DEVAM"
            registerPageIndex={registerPageIndex}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
            stockCount={bist30Continue?.length}
            printChange={true}
          />
        )}
        {bist30Continue?.length > 6 && (
          <PageStockContinue
            list="30"
            stocks={getOrderedStocks("continue30", bist30Continue)?.slice(6)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="continue30"
            pageIndex={16}
            subIndex={0}
            shortTitle="DEVAM"
            registerPageIndex={registerPageIndex}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
            stockCount={bist30Continue?.length}
            printChange={true}
          />
        )}
        <PreviosPerformance
          list={30}
          pageIndex={17}
          saveKey={"previousPerformance30"}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
          registerPageIndex={registerPageIndex}
        />
        {bistWeeklyReport?.decreasingRed30?.length > 0 && (
          <PageStockContinue
            list="30"
            title="KISALAN KIRMIZI BARLAR"
            stocks={getOrderedStocks(
              "decreasingRed30",
              bistWeeklyReport?.decreasingRed30
            )
              ?.slice(0, 3)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="decreasingRed30"
            pageIndex={18}
            subIndex={0}
            shortTitle={"KKM"}
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
            stockCount={bistWeeklyReport?.decreasingRed30?.length}
          />
        )}
        {bistWeeklyReport?.decreasingRed30?.length > 3 && (
          <PageStockContinue
            list="30"
            title="KISALAN KIRMIZI BARLAR"
            stocks={getOrderedStocks(
              "decreasingRed30",
              bistWeeklyReport?.decreasingRed30
            )
              ?.slice(3, 6)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="decreasingRed30"
            shortTitle={"KKM"}
            pageIndex={19}
            subIndex={0}
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
            stockCount={bistWeeklyReport?.decreasingRed30?.length}
          />
        )}
        {bistWeeklyReport?.decreasingRed30?.length > 6 && (
          <PageStockContinue
            list="30"
            title="KISALAN KIRMIZI BARLAR"
            stocks={getOrderedStocks(
              "decreasingRed30",
              bistWeeklyReport?.decreasingRed30
            )
              ?.slice(6, 9)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="decreasingRed30"
            shortTitle={"KKM"}
            pageIndex={20}
            subIndex={0}
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
            stockCount={bistWeeklyReport?.decreasingRed30?.length}
          />
        )}
        {bistWeeklyReport?.redtoBlue30?.length > 0 && (
          <PageStockContinue
            list="30"
            title="KIRMIZIDAN MAVİYE DÖNENLER"
            stocks={getOrderedStocks(
              "redtoBlue30",
              bistWeeklyReport?.redtoBlue30
            )
              ?.slice(0, 3)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="redtoBlue30"
            shortTitle={"KMD"}
            pageIndex={21}
            subIndex={0}
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
            stockCount={bistWeeklyReport?.redtoBlue30?.length}
          />
        )}
        {bistWeeklyReport?.redtoBlue30?.length > 3 && (
          <PageStockContinue
            list="30"
            title="KIRMIZIDAN MAVİYE DÖNENLER"
            stocks={getOrderedStocks(
              "redtoBlue30",
              bistWeeklyReport?.redtoBlue30
            )
              ?.slice(3, 6)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            saveKey="redtoBlue30"
            pageIndex={22}
            subIndex={0}
            shortTitle={"KMD"}
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            weekData={weekData}
            orderChanged={onOrderChanged}
            stockCount={bistWeeklyReport?.redtoBlue30?.length}
          />
        )}
        {bistWeeklyReport?.redtoBlue30?.length > 6 && (
          <PageStockContinue
            list="30"
            title="KIRMIZIDAN MAVİYE DÖNENLER"
            stocks={getOrderedStocks(
              "redtoBlue30",
              bistWeeklyReport?.redtoBlue30
            )
              ?.slice(6, 9)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            saveKey="redtoBlue30"
            pageIndex={23}
            subIndex={0}
            shortTitle={"KMD"}
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            weekData={weekData}
            orderChanged={onOrderChanged}
            stockCount={bistWeeklyReport?.redtoBlue30?.length}
          />
        )}

        <PageStockEnter
          pageIndex={24}
          subIndex={0}
          saveKey="bistStockEnter70"
          weekData={weekData}
          onPageDataUpdated={onPageDataUpdated}
          registerPageIndex={registerPageIndex}
          list="70"
          data={bist70Signal}
          details={bistWeeklyReport?.basket070}
        />
        <>
          {bist70Signal?.incoming?.map((stock: string, index: number) => {
            return (
              <PageStockDetails
                list="70"
                incoming={true}
                stock={stock}
                data={bist70Signal}
                onPageDataUpdated={onPageDataUpdated}
                weekData={weekData}
                pageIndex={25}
                subIndex={index}
                registerPageIndex={registerPageIndex}
                details={bistWeeklyReport?.basket070?.find(
                  (item: any) => item.symbol === stock
                )}
              />
            );
          })}
          {bist70Signal?.outgoing?.map((stock: string, index: number) => {
            return (
              <PageStockDetails
                list="70"
                incoming={false}
                stock={stock}
                data={bist70Signal}
                onPageDataUpdated={onPageDataUpdated}
                weekData={weekData}
                pageIndex={26}
                subIndex={index}
                registerPageIndex={registerPageIndex}
                details={bistWeeklyReport?.basket070?.find(
                  (item: any) => item.symbol === stock
                )}
              />
            );
          })}
        </>
        <PageStockContinue
          list="70"
          stocks={getOrderedStocks("continue70", bist70Continue)?.slice(0, 3)}
          onPageDataUpdated={onPageDataUpdated}
          stockCount={bist70Continue?.length}
          weekData={weekData}
          saveKey="continue70"
          pageIndex={27}
          subIndex={0}
          shortTitle="DEVAM"
          registerPageIndex={registerPageIndex}
          ignoredStocks={ignoredStocks}
          onIgnoreStockChange={onIgnoreStockChange}
          orderChanged={onOrderChanged}
          printChange={true}
        />
        {bist70Continue?.length > 3 && (
          <PageStockContinue
            list="70"
            stocks={getOrderedStocks("continue70", bist70Continue)?.slice(3, 6)}
            onPageDataUpdated={onPageDataUpdated}
            stockCount={bist70Continue?.length}
            weekData={weekData}
            saveKey="continue70"
            pageIndex={28}
            subIndex={0}
            shortTitle="DEVAM"
            registerPageIndex={registerPageIndex}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
            printChange={true}
          />
        )}
        {bist70Continue?.length > 6 && (
          <PageStockContinue
            list="70"
            stocks={getOrderedStocks("continue70", bist70Continue)?.slice(6, 9)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="continue70"
            stockCount={bist70Continue?.length}
            pageIndex={29}
            subIndex={0}
            shortTitle="DEVAM"
            registerPageIndex={registerPageIndex}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
            printChange={true}
          />
        )}
        <PreviosPerformance
          list={70}
          pageIndex={30}
          saveKey={"previousPerformance70"}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
          registerPageIndex={registerPageIndex}
        />
        {bistWeeklyReport?.decreasingRed70?.length > 0 && (
          <PageStockContinue
            list="70"
            title="KISALAN KIRMIZI BARLAR"
            stocks={getOrderedStocks(
              "decreasingRed70",
              bistWeeklyReport?.decreasingRed70
            )
              ?.slice(0, 3)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="decreasingRed70"
            stockCount={bistWeeklyReport?.decreasingRed70?.length}
            pageIndex={31}
            subIndex={0}
            shortTitle="KKB"
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
          />
        )}
        {bistWeeklyReport?.decreasingRed70?.length > 3 && (
          <PageStockContinue
            list="70"
            title="KISALAN KIRMIZI BARLAR"
            stocks={getOrderedStocks(
              "decreasingRed70",
              bistWeeklyReport?.decreasingRed70
            )
              ?.slice(3, 6)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="decreasingRed70"
            stockCount={bistWeeklyReport?.decreasingRed70?.length}
            pageIndex={32}
            subIndex={0}
            shortTitle="KKB"
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
          />
        )}
        {bistWeeklyReport?.decreasingRed70?.length > 6 && (
          <PageStockContinue
            list="70"
            title="KISALAN KIRMIZI BARLAR"
            stocks={getOrderedStocks(
              "decreasingRed70",
              bistWeeklyReport?.decreasingRed70
            )
              ?.slice(6, 9)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            stockCount={bistWeeklyReport?.decreasingRed70?.length}
            saveKey="decreasingRed70"
            pageIndex={33}
            subIndex={0}
            shortTitle="KKB"
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
          />
        )}
        {bistWeeklyReport?.redtoBlue70?.length > 0 && (
          <PageStockContinue
            list="70"
            title="KIRMIZIDAN MAVİYE DÖNENLER"
            stockCount={bistWeeklyReport?.redtoBlue70?.length}
            stocks={getOrderedStocks(
              "redtoBlue70",
              bistWeeklyReport?.redtoBlue70
            )
              ?.slice(0, 3)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="redtoBlue70"
            pageIndex={34}
            subIndex={0}
            shortTitle="KMD"
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
          />
        )}
        {bistWeeklyReport?.redtoBlue70?.length > 3 && (
          <PageStockContinue
            list="70"
            title="KIRMIZIDAN MAVİYE DÖNENLER"
            stockCount={bistWeeklyReport?.redtoBlue70?.length}
            stocks={getOrderedStocks(
              "redtoBlue70",
              bistWeeklyReport?.redtoBlue70
            )
              ?.slice(3, 6)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="redtoBlue70"
            pageIndex={35}
            subIndex={0}
            shortTitle="KMD"
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
          />
        )}
        {bistWeeklyReport?.redtoBlue70?.length > 6 && (
          <PageStockContinue
            list="70"
            title="KIRMIZIDAN MAVİYE DÖNENLER"
            stockCount={bistWeeklyReport?.redtoBlue70?.length}
            stocks={getOrderedStocks(
              "redtoBlue70",
              bistWeeklyReport?.redtoBlue70
            )
              ?.slice(6, 9)
              .map((item: any) => item.symbol)}
            onPageDataUpdated={onPageDataUpdated}
            weekData={weekData}
            saveKey="redtoBlue70"
            pageIndex={36}
            subIndex={0}
            shortTitle="KMD"
            registerPageIndex={registerPageIndex}
            printChange={true}
            ignoredStocks={ignoredStocks}
            onIgnoreStockChange={onIgnoreStockChange}
            orderChanged={onOrderChanged}
          />
        )}
        <Page16
          pageIndex={37}
          subIndex={0}
          registerPageIndex={registerPageIndex}
        />
        <Page17
          pageIndex={38}
          subIndex={0}
          registerPageIndex={registerPageIndex}
        />
        <Page14
          pageIndex={39}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
          details={bistWeeklyReport?.sectors}
          onSectorsChanged={setSelectedSectors}
          sector1PeriodChanged={setSector1Period}
          sector2PeriodChanged={setSector2Period}
          sector3PeriodChanged={setSector3Period}
        />
        {selectedSectorDetails?.map((sector: any, subIndex: any) => {
          return (
            <SectorDetail
              pageIndex={40}
              subIndex={subIndex}
              registerPageIndex={registerPageIndex}
              saveKey="sector-detail-2"
              onPageDataUpdated={onPageDataUpdated}
              weekData={weekData}
              sector={sector}
              period={
                subIndex === 0
                  ? sector1Period
                  : subIndex === 1
                  ? sector2Period
                  : sector3Period
              }
            />
          );
        })}
        <Page12
          pageIndex={41}
          subIndex={0}
          registerPageIndex={registerPageIndex}
          onPageDataUpdated={onPageDataUpdated}
          weekData={weekData}
        />

        <Page13
          pageIndex={42}
          subIndex={0}
          registerPageIndex={registerPageIndex}
        />
      </div>
    </>
  );
};
export default Weekly;
