import { Dialog, DialogBody, Icon } from "@blueprintjs/core";
import { useState } from "react";

export const ImageModal = ({ imageSelected }: any) => {
  const [showImageDialog, setShowImageDialog] = useState(false);
  return (
    <>
      <div
        onClick={() => {
          setShowImageDialog(true);
        }}
        style={{ cursor: "pointer", color: "#fff" }}
        className="weekly-image-modal-open"
      >
        <Icon icon="emoji" iconSize={20} color="#000" />
      </div>
      <Dialog
        portalClassName="weekly-image-modal-portal"
        isOpen={showImageDialog}
        onClose={() => {
          setShowImageDialog(false);
        }}
        style={{ width: 454 }}
        title="Görseller"
      >
        <DialogBody>
          <div className="weekly-image-selection">
            {[
              { src: "smile.png", keepRatio: true },
              { src: "notr.png", keepRatio: true },
              { src: "sad.png", keepRatio: true },
              { src: "arrow_chalk.png", keepRatio: false },
              { src: "long_arrow.png", keepRatio: false },
              { src: "arrow_up.png", keepRatio: false },
              { src: "curvy_icon.png", keepRatio: false },
              { src: "circle.png", keepRatio: true },
              { src: "circle2.png", keepRatio: true },
              { src: "down_arrow.png", keepRatio: true },
              { src: "red_arrow.png", keepRatio: true },
              { src: "green_arrow.png", keepRatio: true },
              { src: "white_left_arrow.png", keepRatio: false },
              { src: "thums_up.png", keepRatio: true },
              { src: "clap.png", keepRatio: true },
              { src: "alert.png", keepRatio: true },
              { src: "clock.png", keepRatio: true },
              { src: "dollar.png", keepRatio: true },
              { src: "tick.png", keepRatio: true },
              { src: "question.png", keepRatio: true },
              { src: "warning.png", keepRatio: true },
              { src: "exclamation.png", keepRatio: true },
              { src: "stop.png", keepRatio: true },
              { src: "nazar.png", keepRatio: true },
              { src: "star.png", keepRatio: true },
              { src: "rocket.png", keepRatio: true },
              { src: "announce.png", keepRatio: true },
            ].map((item) => {
              return (
                <div
                  onClick={() => {
                    setShowImageDialog(false);
                    imageSelected(
                      `/images/weekly_image/${item.src}`,
                      item.keepRatio
                    );
                  }}
                >
                  <img src={`/images/weekly_image/${item.src}`} />
                </div>
              );
            })}
          </div>
        </DialogBody>
      </Dialog>
    </>
  );
};
