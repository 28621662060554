import {
  Button,
  Card,
  Divider,
  EditableText,
  Elevation,
  InputGroup,
  Intent,
  TextArea,
} from "@blueprintjs/core";
import {
  query,
  collection,
  limit,
  getDocs,
  doc,
  getDoc,
  setDoc,
} from "firebase/firestore";
import { list } from "firebase/storage";
import { useState, useEffect } from "react";
import { fireStore } from "../firebase";

export const GlobalLangEditor = (props: any) => {
  const [docData, setDocData] = useState<any>({});
  const [component, setComponent] = useState<string>("");
  const [key, setKey] = useState<string>("");
  const [value, setValue] = useState<string>("");
  const saveLang = async () => {
    await setDoc(doc(fireStore, "NS_BULLETIN_GLOBAL_LANG", props.lang), {
      lang: JSON.stringify(docData),
    });
  };
  useEffect(() => {
    const ref = doc(fireStore, "NS_BULLETIN_GLOBAL_LANG", props.lang);
    getDoc(ref).then((doc) => {
      setDocData(JSON.parse(doc.data()?.lang));
    });
  }, []);
  const updateData = (
    parentKey: string,
    key: string,
    value: string,
    index?: number
  ) => {
    if (index !== undefined) docData[parentKey][key][index] = value;
    else docData[parentKey][key] = value;
    setDocData({ ...docData });
  };
  const getCompoentForText = (
    parentKey: string,
    childKey: string,
    value: string,
    index?: number
  ): any => {
    if (Array.isArray(value)) {
      return (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {value.map((item: any, index: number) => {
            return getCompoentForText(parentKey, childKey, item, index);
          })}
        </div>
      );
    }
    if (!value.includes("\n") && value.length < 80)
      return (
        <EditableText
          onChange={(e) => updateData(parentKey, childKey, e, index)}
          alwaysRenderInput
          value={value}
        />
      );
    return (
      <EditableText
        onChange={(e) => updateData(parentKey, childKey, e, index)}
        alwaysRenderInput
        multiline
        minLines={3}
        maxLines={3}
        value={value}
      ></EditableText>
    );
  };
  return (
    <Card elevation={Elevation.TWO}>
      <div style={{ display: "flex", justifyContent: "right" }}>
        <InputGroup
          placeholder="Bileşen"
          onChange={(e) => setComponent(e.target.value)}
        ></InputGroup>
        <InputGroup placeholder="Anahtar"   onChange={(e) => setKey(e.target.value)}></InputGroup>
        <InputGroup placeholder="Değer"   onChange={(e) => setValue(e.target.value)}></InputGroup>
        <Button icon="add" onClick={()  => {
             debugger;
          if(component && key && component!="" && key!="") {
            if(!docData[component])
            docData[component]={}
          docData[component][key] = value;
          setDocData({ ...docData });
          }

        }} intent={Intent.PRIMARY}>
          Ekle
        </Button>
        <Button icon="saved" onClick={() => saveLang()} intent={Intent.PRIMARY}>
          Kaydet
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 10,
          height: "500px",
          overflow: "auto",
        }}
      >
        {Object.keys(docData)
          .filter((item) => docData[item])
          .map((key: any) => {
            return (
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    paddingBottom: "10px",
                  }}
                >
                  <span style={{ textDecoration: "underline" }}>Bileşen:</span>{" "}
                  {key}
                </div>
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 7 }}
                >
                  {Object.keys(docData[key])
                    .filter(
                      (item) =>
                        typeof docData[key][item] === "string" ||
                        Array.isArray(docData[key][item])
                    )
                    .map((key2: any) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            gap: 5,
                            paddingLeft: "20px",
                          }}
                        >
                          <div style={{ fontWeight: "bold", minWidth: 200 }}>
                            {key2}:
                          </div>
                          <div style={{ flex: 1, marginRight: 10 }}>
                            {getCompoentForText(key, key2, docData[key][key2])}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <Divider />
              </div>
            );
          })}
      </div>
    </Card>
  );
};
