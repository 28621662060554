import { Button, Switch } from "@blueprintjs/core";
import { useCallback, useEffect, useState } from "react";
import { ExportToDocx } from "./ExportDocx";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { beforePreviousFridayNight } from "../util";
import moment from "moment";
import React from "react";
import { useChartDateContext } from "../chart-date-context";
export const PageSettings = (props: any) => {
  const [preview, setPreview] = useState(false);
  const [tempDate, setTempDate] = useState<Date | null>(null);
  const { chartDate, setChartDate } = useChartDateContext();
  const [date, setDate] = useState(
    beforePreviousFridayNight(chartDate).toDate()
  );
  const inputRef = React.useRef<any>();
  useEffect(() => {
    setDate(beforePreviousFridayNight(chartDate).toDate());

    if (inputRef.current)
      inputRef.current.value =
        beforePreviousFridayNight(chartDate).format("YYYY-MM-DD");
  }, [chartDate]);
  return (
    <div className=" no-print weekly-page-settings">
      <div>
        <Switch
          label="Önizleme"
          checked={preview}
          onChange={(e) => {
            setPreview(e.currentTarget.checked);
            props.onPreivewChange(e.currentTarget.checked);
          }}
        />
      </div>
      <ExportToDocx data={props.data} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          paddingTop: 20,
          gap: 10,
        }}
      >
        <input
          ref={inputRef}
          type="date"
          defaultValue={moment(date).format("YYYY-MM-DD")}
          onChange={(e) => setTempDate(e.target.valueAsDate)}
        />
        <Button
          onClick={() => {
            setChartDate(tempDate);
          }}
        >
          Tarihi Ayarla
        </Button>
        <Button
          onClick={() => {
            setChartDate(null);
          }}
        >
          Tarihi Temizle
        </Button>
      </div>
    </div>
  );
};
