import {
  Button,
  Card,
  Dialog,
  DialogBody,
  DialogFooter,
  Icon,
  InputGroup,
  Intent,
  ProgressBar,
  Spinner,
} from "@blueprintjs/core";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { auth, fireStore, storage } from "../../../firebase";
export const WeeklyList = () => {
  const navigate = useNavigate();
  const [bulletins, setBulletins] = useState<any[]>([]);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [file, setFile] = useState<File | null | undefined>(null);
  const [fileProgress, setFileProgress] = useState<number>(0);
  const getDefaultReportName = () => {
    return `Sentiment Algo Haftalık Bülten (${
      moment().startOf("isoWeek").add(1, "week").format("Do MMMM") +
      " - " +
      moment()
        .startOf("isoWeek")
        .add(1, "week")
        .add(4, "days")
        .format("Do MMMM")
    })`;
  };
  const [bulletinName, setBulletinName] = useState<string>(
    getDefaultReportName()
  );
  const [fileUploadRunning, setFileUploadRunning] = useState<boolean>(false);
  const [reportUploaded, setReportUploaded] = useState<boolean>(false);
  const [showReportUploadDialog, setShowReportUploadDialog] = useState(false);
  const [deleteBulletinName, setDeleteBulletinName] = useState<string | null>(
    null
  );
  const [fileUploadTask, setFileUploadTask] = useState<any>(null);

  const [reportDefaultName] = useState<string>(getDefaultReportName());

  const readDocs = async () => {
    const q = query(
      collection(fireStore, "BIST_WEEKLY_REPORT_DATA"),
      orderBy("updatedAt", "desc"),
      limit(20)
    );

    getDocs(q).then((querySnapshot) => {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        list.push({
          name: doc.data().name,
          id: doc.id,
          updatedAt: doc.data().updatedAt,
          lastUpdateUser: doc.data().lastUpdateUser,
        });
      });
      setBulletins(list);
    });
  };
  useEffect(() => {
    readDocs();
  }, []);

  return (
    <div style={{ width: 1024, margin: "auto" }}>
      <Card>
        <div
          style={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
          }}
        >
          {!showReportUploadDialog && (
            <div style={{ display: "flex", gap: 3 }}>
              <Button
                intent={Intent.PRIMARY}
                icon={<Icon icon="upload" />}
                minimal={false}
                onClick={() => {
                  setShowReportUploadDialog(true);
                }}
              >
                Rapor Yükle
              </Button>
              <Button
                onClick={() => {
                  navigate("/weekly-report-list");
                }}
                icon={<Icon icon="list" />}
              >
                Rapor Listesi
              </Button>
            </div>
          )}
          {showReportUploadDialog && (
            <div
              style={{
                display: "flex",
                alignItems: "start",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <InputGroup
                style={{ width: 400 }}
                placeholder="Bülten Başlığı"
                defaultValue={reportDefaultName}
                onChange={(e) => {
                  setBulletinName(e.target.value);
                }}
              />
              <input
                onChange={(e) => {
                  setFile(e.target.files?.[0]);
                  setReportUploaded(false);
                }}
                type="file"
              />
              {fileUploadRunning && (
                <div style={{ display: "flex", gap: 10 }}>
                  <span>Yükleniyor: %{Math.round(fileProgress)} </span>
                  <Spinner size={15}></Spinner>
                </div>
              )}
              {fileUploadRunning && (
                <div style={{ width: "100%" }}>
                  <ProgressBar
                    animate={true}
                    stripes={true}
                    value={fileProgress / 100}
                  />
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  gap: 3,
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 20,
                }}
              >
                <div style={{ display: "flex", gap: 3 }}>
                  <Button
                    disabled={fileUploadRunning}
                    intent={Intent.PRIMARY}
                    icon={<Icon icon="upload" />}
                    minimal={false}
                    onClick={() => {
                      if (file) {
                        const storageRef = ref(
                          storage,
                          `bulletin/${file.name}`
                        );
                        const uploadTask = uploadBytesResumable(
                          storageRef,
                          file
                        );
                        setFileUploadTask(uploadTask);

                        uploadTask.on(
                          "state_changed",
                          (snapshot) => {
                            const progress =
                              (snapshot.bytesTransferred /
                                snapshot.totalBytes) *
                              100;
                            setFileProgress(progress);

                            switch (snapshot.state) {
                              case "running":
                                setFileUploadRunning(true);
                                console.log("Upload running");
                                break;
                              default:
                                setFileUploadRunning(false);
                                break;
                            }
                          },
                          (error) => {
                            setFileUploadRunning(false);
                            console.log(error);
                          },
                          () => {
                            setFileUploadRunning(false);
                            getDownloadURL(uploadTask.snapshot.ref).then(
                              (downloadedURL) => {
                                addDoc(
                                  collection(fireStore, "NS_USER_BULLETINS"),
                                  {
                                    name: bulletinName,
                                    url: downloadedURL,
                                    dateTime: new Date(),
                                    version: "2",
                                    source: "firebase",
                                  }
                                ).then((docRef: any) => {
                                  setReportUploaded(true);
                                  setFileUploadTask(null);
                                });
                              }
                            );
                          }
                        );
                      }
                    }}
                  >
                    Yükle
                  </Button>

                  <Button
                    onClick={() => {
                      setShowReportUploadDialog(false);
                      setFile(null);
                      if (fileUploadTask) {
                        fileUploadTask.cancel();
                      }
                      setFileUploadTask(null);
                    }}
                  >
                    İptal
                  </Button>
                </div>
                <Button
                  icon={<Icon icon="list" />}
                  onClick={() => {
                    navigate("/weekly-report-list");
                  }}
                >
                  Rapor Listesi
                </Button>
              </div>
              {reportUploaded && <div>Rapor yüklendi</div>}
            </div>
          )}
          <Button
            intent={Intent.PRIMARY}
            icon={<Icon icon="add" />}
            minimal={false}
            onClick={() => {
              addDoc(collection(fireStore, "BIST_WEEKLY_REPORT_DATA"), {
                name: moment().format("DD.MM.YYYY"),
                updatedAt: new Date(),
                createdAt: new Date(),
                lastUpdateUser: auth.currentUser?.email,
                lastUpdateUserId: auth.currentUser?.uid,
                content: {},
              }).then((docRef: any) => {
                navigate(`/weekly-report?id=${docRef.id}`);
              });
            }}
          >
            Yeni Bülten
          </Button>
        </div>
        <div style={{ margin: 20 }}></div>
        <table cellPadding={0} cellSpacing={0} className="bulletin-list">
          <thead>
            <tr>
              <th>Güncelleme Tarihi</th>
              <th>Güncelleyen</th>
              <th style={{ width: 100 }}></th>
              <th style={{ width: 100 }}></th>
            </tr>
          </thead>
          <tbody>
            {bulletins.map((bulletin) => (
              <tr key={bulletin.id}>
                <td>
                  {moment(bulletin.updatedAt.toDate()).format(
                    "DD.MM.YYYY HH:mm"
                  )}
                </td>
                <td>{bulletin.lastUpdateUser ?? ""}</td>
                <td>
                  <Button
                    intent={Intent.DANGER}
                    fill={true}
                    onClick={() => {
                      setDeleteId(bulletin.id);
                      setShowDeleteDialog(true);
                      setDeleteBulletinName(bulletin.name);
                    }}
                    icon={<Icon icon="trash" />}
                    minimal
                  >
                    Sil
                  </Button>
                </td>

                <td>
                  <Button
                    fill={true}
                    onClick={() => {
                      navigate(`/weekly-report?id=${bulletin.id}`);
                    }}
                    icon={<Icon icon="document-open" />}
                    minimal
                  >
                    Aç
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Card>
      <Dialog
        isOpen={showDeleteDialog}
        onClose={() => {
          setShowDeleteDialog(false);
          setDeleteId(null);
        }}
        title="Bülten Sil"
      >
        <DialogBody>
          <b>{deleteBulletinName}</b> isimli bülteni silmek üzerisiniz. Devam
          etmek istiyor musunuz?
        </DialogBody>
        <DialogFooter
          actions={
            <>
              <Button
                intent="primary"
                text="Kapat"
                onClick={() => {
                  setShowDeleteDialog(false);
                }}
              />
              <Button
                intent="danger"
                text="Sil"
                onClick={() => {
                  if (deleteId == null) return;
                  deleteDoc(
                    doc(fireStore, "BIST_WEEKLY_REPORT_DATA", deleteId)
                  ).then(() => {
                    readDocs();
                    setShowDeleteDialog(false);
                  });
                }}
              />
            </>
          }
        />
      </Dialog>
    </div>
  );
};
