import { set } from "lodash";
import moment from "moment";
import { createContext, useContext, useEffect, useState } from "react";

const ChartDateContext = createContext({
  chartDate: null,
  setChartDate: (date: Date | null) => {},
});

export function ChartDateContextProvider({ children, initialHeight }: any) {
  const [chartDate, setChartDateInternal] = useState<any>(null);
  const setChartDate = (date: Date | null) => {
    if (date === null) {
      setChartDateInternal(null);
      return;
    }
    const _date = moment(date);
    _date.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
    setChartDateInternal(_date.toDate());
  };

  return (
    <ChartDateContext.Provider value={{ chartDate, setChartDate }}>
      {children}
    </ChartDateContext.Provider>
  );
}

export function useChartDateContext() {
  const context = useContext(ChartDateContext);
  if (!context) {
    throw new Error(
      "useChartDateContext must be used within a ChartDateContextProvider"
    );
  }
  return context;
}
