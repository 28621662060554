import React, { useEffect, useState } from "react";

import {
  Button,
  Card,
  Classes,
  Dialog,
  DialogBody,
  DialogFooter,
  Elevation,
  H5,
  Intent,
  Tab,
  Tabs,
} from "@blueprintjs/core";
import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
  where,
} from "firebase/firestore";
import { useSearchParams } from "react-router-dom";
import { auth, fireStore } from "../firebase";
import { Comment } from "./comment";
import { BulletinTable } from "./table";
import axios from "axios";
import { EXAMPLE_DATA } from "../data/example";
export const BulletinForm = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [bulletinId, setBulletinId] = React.useState<string | null>(
    searchParams.get("id")
  );
  const [bulletMeta, setBulletMeta] = React.useState<any>({});
  const [docData, setDocData] = React.useState<any>(null);
  const [selectedTabId, setSelectedTabId] = useState("sentiment");
  const [showDownloadDialog, setShowDownloadDialog] = useState(false);
  const [waitingLang, setWaitingLang] = useState<string | null>(null);
  const [companyList, setCompanyList] = useState<any[]>([]);
  useEffect(() => {
    if (bulletinId) {
      const ref = doc(fireStore, "NS_BULLETINS", bulletinId);
      getDoc(ref).then((doc) => {
        setDocData(JSON.parse(doc.data()?.content));
        bulletMeta.name = doc.data()?.name;
      });
    }
  }, [bulletinId]);
  useEffect(() => {
    const q = query(
      collection(fireStore, "NS_BULLETIN_COMPANY"),
      where("visible","==",true),
      limit(20)
    );

    getDocs(q).then((querySnapshot) => {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        list.push({
          name: doc.data().name,
          id: doc.id,
        });
      });
      setCompanyList(list);
    });
  }, []);
  const updateDocumentForm = (
    key: string,
    type: string,
    data: any,
    preventFilter = false
  ) => {
    if (!docData[key]) docData[key] = {};
    docData[key][type] = data;
  };

  const saveForm = async () => {
    if (bulletinId)
      await setDoc(
        doc(fireStore, "NS_BULLETINS", bulletinId),
        {
          ...bulletMeta,
          updatedAt: new Date(),
          lastUpdateUser: auth.currentUser?.email,
          lastUpdateUserId: auth.currentUser?.uid,
          content: JSON.stringify(docData),
        },
        { merge: true }
      );
  };

  const downloadBulletin = async (companyId: string, lang: string) => {
    const token = await auth.currentUser?.getIdToken();
    setWaitingLang(`${companyId}.${lang}`);
    axios({
      url: `${process.env.REACT_APP_BULLETIN_API_URL}/bulletin/${companyId}/${bulletinId}/${lang}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: "blob",
    })
      .then((response) => {
        setWaitingLang(null);
        const a = document.createElement("a");
        a.href = window.URL.createObjectURL(response.data);
        a.download = `bulten.pdf`;
        document.body.appendChild(a);
        a.click();
        a.remove();
      })
      .catch((err) => {
        setWaitingLang(null);
      });
  };

  return (
    <div className="container">
      <Card elevation={Elevation.ONE}>
        <H5>Bülten Adı</H5>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: ":center",
          }}
        >
          <input
            value={bulletMeta?.name ?? ""}
            className={Classes.INPUT}
            onChange={(e) => {
              setBulletMeta({ ...bulletMeta, name: e.target.value });
            }}
            placeholder="Bülten adını girin"
          />
          <div style={{ display: "flex", gap: 5 }}>
       
            <Button icon="download" onClick={() => setShowDownloadDialog(true)}>
              Bülten İndir
            </Button>
            <Button
              icon="saved"
              onClick={() => saveForm()}
              intent={Intent.PRIMARY}
            >
              Kaydet
            </Button>
          </div>
        </div>
      </Card>
      <Tabs
        id="BulletinTabs"
        vertical
        onChange={(val: string) => setSelectedTabId(val)}
        selectedTabId={selectedTabId}
      >
        <Tab
          id="sentiment"
          title="Sentiment"
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={5}
              cols={[
                { name: "date", title: "Tarih", maxWidth: 120 },
                { name: "sentiment", title: "Sentiment", maxWidth: 100 },
                { name: "sentimentMom", title: "Sent-Mom", maxWidth: 100 },
                { name: "bist100", title: "Bist 100", maxWidth: 100 },
              ]}
              dataName={"sentiment"}
              initialData={docData?.["sentiment"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
        <Tab
          id="bist"
          title="BİST"
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={7}
              cols={[
                { name: "name", title: "Veri", disabled: true, maxWidth: 200 },
                { name: "value", title: "Değer", maxWidth: 100 },
              ]}
              fillData={[
                { name: "BİST 100 Kapanış" },
                { name: "Önceki Gün Kapanış" },
                { name: "Günlük Değişim %" },
                { name: "BİST 30 Kapanış" },
                { name: "Önceki Gün Kapanış" },
                { name: "Günlük Değişim %" },
                { name: "XU030 Viop" },
              ]}
              dataName={"bist"}
              initialData={docData?.["bist"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
        <Tab
          id="comment"
          title="Yorum"
          className="page-tab"
          panel={
            <Comment
              initialData={docData}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
        <Tab
          id="focus"
          title="Yoğunlaşma"
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={10}
              cols={[
                { name: "name", title: "Hisse Adı", maxWidth: 100 },
                { name: "price", title: "Son Fiyat", maxWidth: 100 },
              ]}
              dataName={"focus"}
              initialData={docData?.["focus"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
        <Tab
          id="bist-first-5"
          title="BİST İlk 5 "
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={5}
              cols={[
                { name: "name", title: "Hisse Adı", maxWidth: 100 },
                { name: "score", title: "Puan", maxWidth: 100 },
              ]}
              dataName={"bist-first-5"}
              initialData={docData?.["bist-first-5"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
        <Tab
          id="bist-worst-5"
          title="BİST Son 5 "
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={5}
              cols={[
                { name: "name", title: "Hisse Adı", maxWidth: 100 },
                { name: "score", title: "Puan", maxWidth: 100 },
              ]}
              dataName={"bist-worst-5"}
              initialData={docData?.["bist-worst-5"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
        <Tab
          id="bist-first-10"
          title="BİST İlk 10"
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={5}
              cols={[
                { name: "incoming", title: "Girenler", maxWidth: 100 },
                { name: "outgoing", title: "Çıkanlar", maxWidth: 100 },
              ]}
              dataName={"bist-first-10"}
              initialData={docData?.["bist-first-10"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
        <Tab
          id="perf"
          title="Performans"
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={5}
              cols={[
                { name: "date", title: "Tarih", maxWidth: 100 },
                { name: "name", title: "Hisse Adı", maxWidth: 100 },
                { name: "price", title: "Fiyat", maxWidth: 100 },
                { name: "lastPrice", title: "Son Fiyat", maxWidth: 100 },
                {
                  name: "lastChange",
                  title: "Değişim",
                  maxWidth: 100,
                  autoColorize: true,
                },
                {
                  name: "maxChange",
                  title: "Maks Fiyata Göre Değişim",
                  maxWidth: 100,
                  autoColorize: true,
                },
              ]}
              dataName={"perf"}
              initialData={docData?.["perf"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
        <Tab
          id="stocks"
          title="Hisseler"
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={5}
              cols={[
                { name: "date", title: "Liste Tarihi", maxWidth: 100 },
                { name: "name", title: "Hisse Adı", maxWidth: 100 },
                { name: "price", title: "Hisse Fiyatı", maxWidth: 100 },
                { name: "close", title: "Kapanış Fiyatı", maxWidth: 100 },
                {
                  name: "afterPrice",
                  title: "Liste Sonrası Performans",
                  maxWidth: 100,
                  autoColorize: true,
                },
                { name: "remaininDays", title: "Kalan Gün", maxWidth: 100 },
                { name: "maxDays", title: "Max Tarih", maxWidth: 100 },
                {
                  name: "maxPerf",
                  title: "Max Performans Fiyatı",
                  maxWidth: 100,
                },
                {
                  name: "maxChange",
                  title: "Max Performansa Göre Değişim",
                  maxWidth: 120,
                  autoColorize: true,
                },
              ]}
              dataName={"stocks"}
              initialData={docData?.["stocks"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />

        {[6, 5, 4, 3, 2, 1].map((num) => {
          return (
            <Tab
              id={"weeks" + num}
              title={num + ". Hafta"}
              className="page-tab"
              panel={
                <BulletinTable
                  rowCount={5}
                  cols={[
                    { name: "stock", title: num + " Hafta", maxWidth: 100 },
                    { name: "price", title: "Fiyat", maxWidth: 100 },
                    {
                      name: "perf",
                      autoColorize: true,
                      title: "Perf",
                      maxWidth: 100,
                    },
                    { name: "maxWeek", title: "Max Hafta", maxWidth: 100 },
                    { name: "maxPerf", title: "Max Perf", maxWidth: 100 },
                    {
                      name: "maxPerfRatio",
                      title: "Max Perf %",
                      maxWidth: 100,
                      autoColorize: true,
                    },
                  ]}
                  dataName={"week" + num}
                  initialData={docData?.["week" + num]}
                  updateDocumentForm={updateDocumentForm}
                />
              }
            />
          );
        })}
        <Tab
          id="suggestions"
          title="Öneriler"
          className="page-tab"
          panel={
            <BulletinTable
              rowCount={5}
              cols={[
                { name: "order", title: "Öneri", maxWidth: 50 },
                { name: "name", title: "Hisse Adı", maxWidth: 100 },
                { name: "price", title: "Ort Fiyat", maxWidth: 100 },
                {
                  name: "perf",
                  title: "Ort Performans",
                  maxWidth: 100,
                  autoColorize: true,
                },
              ]}
              dataName={"suggestions"}
              initialData={docData?.["suggestions"]}
              updateDocumentForm={updateDocumentForm}
            />
          }
        />
      </Tabs>
      <Dialog
        isOpen={showDownloadDialog}
        onClose={() => {
          setShowDownloadDialog(false);
        }}
        title="Bülteni İndir"
      >
        <DialogBody>
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                {" "}
                <th>Kurum</th>
                <th>Arapça</th>
                <th>Azerice</th>
                <th>İngilizce</th>
                <th>Türkçe</th>
              </tr>
            </thead>
            <tbody>
              {companyList.map((company) => {
                return (
                  <tr>
                    <td>{company.name}</td>
                   {["ar","az","en","tr"].map( lang=><td>
                      <Button
                      loading={waitingLang===`${company.id}.${lang}`}
                        fill
                        minimal
                        icon="download"
                        onClick={() => downloadBulletin(company.id, lang)}
                        intent={Intent.PRIMARY}
                      >
                        İndir
                      </Button>
                    </td>

                   )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogBody>
        <DialogFooter
          actions={
            <>
              <Button
                intent="primary"
                text="Kapat"
                onClick={() => {
                  setShowDownloadDialog(false);
                }}
              />
            </>
          }
        />
      </Dialog>
    </div>
  );
};
