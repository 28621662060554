export const Sectors = {
    XAKUR: "Aracı Kurumlar",
    XBANK: "Banka",
    XBLSM: "Bilişim",
    XELKT: "Elektrik",
    XFINK: "Finansal Kiralama Faktöring",
    XGMYO: "Gayrimenkul Yat. Ort.",
    XGIDA: "Gıda İçecek",
    XUHIZ: "Hizmetler",
    XHOLD: "Holding ve Yatırım",
    XILTM: "İletişim",
    XINSA: "İnşaat",
    XK100: "Katılım100",
    XKTUM: "Katılım Tüm",
    XKMYA: "Kimya Petrol Plastik",
    XMADN: "Madencilik",
    XMANA: "Metal Ana",
    XMESY: "Metal Eşya Makine",
    XKAGT: "Orman Kağıt Basım",
    XSGRT: "Sigorta",
    XUSIN: "Sanayi",
    XSPOR: "Spor",
    XTAST: "Taş Toprak",
    XUTEK: "Teknoloji",
    XTCRT: "Ticaret",
    XTRZM: "Turizm",
    XULAS: "Ulaştırma",
    XYLDZ: "Yıldız",
  };