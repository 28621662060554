import {
    createUserWithEmailAndPassword, GoogleAuthProvider, onAuthStateChanged,
    signInAnonymously, signInWithEmailAndPassword, User
} from 'firebase/auth';
import { useEffect, useState } from 'react';
  
  import { auth } from '../firebase';
  
  export const useProvideAuth = () => {
    const [authUser, setAuthUser] = useState<User | null>(null);
    const [error, setError] = useState('');
    const [logingIn, setLogingIn] = useState(false);
    const [signingUp, setSigningUp] = useState(false);
    const [isLoadingUser, setLoadingUser] = useState(true);
    const [isLoading, setLoading] = useState(false);
    const [googleProvider, setGoogleProvider] =
      useState<GoogleAuthProvider | null>(null);
    useEffect(() => {
      setGoogleProvider(new GoogleAuthProvider());
  
      const unlisten = onAuthStateChanged(auth, function (user) {

        setLoadingUser(false);
        setAuthUser(user);
      });
  
      return () => {
        unlisten();
      };
    }, []);
    const userLoginWithGoogle = (
      onSuccess: () => void,
      onError?: (error: any) => void
    ) => {
  
    };
    const userLogin = (
      user: { email: string; password: string },
      onSuccess: () => void,
      onError?: (error: any) => void
    ) => {
      setLogingIn(true);
      setTimeout(() => {
        signInWithEmailAndPassword(auth, user.email, user.password)
          .then((user) => {
            setLogingIn(false);
            setAuthUser(user.user);
            onSuccess();
          })
          .catch((err) => {
            setLogingIn(false);
            if (onError) onError(err);
          });
      }, 0);
    };
  
    const userSignup = (
      user: { email: string; password: string },
      onSuccess: () => void,
      onError?: (error: any) => void
    ) => {
      setSigningUp(true);
      createUserWithEmailAndPassword(auth, user.email, user.password)
        .then((user) => {
          setSigningUp(false);
          setAuthUser(user.user);
          onSuccess();
        })
        .catch((err) => {
          setSigningUp(false);
          if (onError) onError(err);
        });
    };
  
    const renderSocialMediaLogin = () => null;
  
    const userSignOut = () => {
      auth.signOut();
    };
  
    const getAuthUser = () => {
      return auth?.currentUser;
    };
  
    // Return the user object and auth methods
    return {
      logingIn,
      isLoadingUser,
      isLoading,
      authUser,
      error,
      setError,
      setAuthUser,
      getAuthUser,
      userLogin,
      userSignup,
      userSignOut,
      renderSocialMediaLogin,
      userLoginWithGoogle,
    };
  };
  