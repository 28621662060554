import { TextArea } from "@blueprintjs/core";
import { DailyInstantECharts } from "../charts/daily-instant";
import { useEffect, useState } from "react";
import { WeeklyPage } from "../components/WeeklyPage";
import { ImageModal } from "../components/ImageModal";
import Moveable from "react-moveable";
import { ImageContainer } from "../components/ImageContainer";

export const Page3 = ({
  onPageDataUpdated,
  weekData,
  pageIndex,
  subIndex,
  registerPageIndex,
}: any) => {
  const [focusedImageId, setFocusedImageId] = useState<number>(1);
  const [comments, setComments] = useState<any>(weekData?.page3?.comment ?? "");
  const [resistance1, setResistance1] = useState<any>(
    weekData?.page3?.resistance1 ?? ""
  );
  const [resistance2, setResistance2] = useState<any>(
    weekData?.page3?.resistance2 ?? ""
  );
  const [resistance3, setResistance3] = useState<any>(
    weekData?.page3?.resistance3 ?? ""
  );
  const onTitleUpdated = (text: string) => {
    setComments(text);
  };
  useEffect(() => {
    registerPageIndex(
      pageIndex,
      subIndex,
      "Haftalık Sentiment Değerlendirmesi"
    );
  }, []);
  return (
    <WeeklyPage
      pageId={`page-${pageIndex}-${subIndex}`}
      cropped
      onClick={() => {
        setFocusedImageId((prev) => prev + 1);
      }}
    >
      <div
        style={{
          fontSize: 40,
          color: "#fff",
          textAlign: "center",
          fontWeight: "bold",
          padding: 10,
        }}
      >
        HAFTALIK SENTİMENT DEĞERLENDİRMESİ
      </div>
      <div style={{ display: "flex", gap: 10 }}>
        <div style={{ width: "25%" }}>
          <div
            className="weekly-sentiment-resistant no-print"
            style={{ display: "flex" }}
          >
            <input
              placeholder="Direnç - 1"
              value={resistance1}
              onChange={(e) => {
                onPageDataUpdated("page3", "resistance1", e.target.value);
                setResistance1(e.target.value);
              }}
            ></input>
            <input
              placeholder="Direnç - 2"
              value={resistance2}
              onChange={(e) => {
                onPageDataUpdated("page3", "resistance2", e.target.value);
                setResistance2(e.target.value);
              }}
            ></input>
            <input
              placeholder="Direnç - 3"
              value={resistance3}
              onChange={(e) => {
                onPageDataUpdated("page3", "resistance3", e.target.value);
                setResistance3(e.target.value);
              }}
            ></input>
          </div>
          <TextArea
            className="no-print"
            style={{ flex: 1, width: "100%", height: "100%" }}
            placeholder="Haftalık Sentiment Değerlendirmesini girebilirisiniz"
            defaultValue={comments}
            onChange={(e) => {
              onPageDataUpdated("page3", "comment", e.target.value);
              onTitleUpdated(e.target.value);
            }}
          />
          <div
            className="print-only-block"
            style={{ color: "#fff", fontSize: 20, lineHeight: "30px" }}
          >
            <ul>
              {comments
                ?.split("\n")
                .filter((item: string) => item.trim() !== "")
                .map((item: string) => (
                  <li>{item}</li>
                ))}
            </ul>
          </div>
        </div>
        <div
          style={{
            backgroundColor: "rgb(19,23,35)",
            flex: 1,
            marginRight: 20,
            width: "75%",
            borderRadius: 5,
            overflow: "hidden",
          }}
        >
          <DailyInstantECharts
            resistance1={resistance1}
            resistance2={resistance2}
            resistance3={resistance3}
          />
          <div
            style={{
              position: "absolute",
              right: 60,
              bottom: 90,
              fontSize: 40,
              color: "#fff",
            }}
          >
            SENTİMENT
          </div>
        </div>
      </div>
      <ImageContainer
        pageId={"page3"}
        onPageDataUpdated={onPageDataUpdated}
        weekData={weekData?.page3}
        focusedImageId={focusedImageId}
      />
    </WeeklyPage>
  );
};
