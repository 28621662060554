import { Children, useState } from "react";
import { PageHider } from "./PageHider";

export const WeeklyPage = ({
  children,
  blue,
  cropped,
  pageId,
  onClick,
  isPageVisible,
}: any) => {
  const [pageVisible, setPageVisible] = useState<boolean>(
    isPageVisible !== undefined ? isPageVisible : true
  );

  return (
    <div
      onClick={() => {
        if (onClick) onClick();
      }}
      id={pageId}
      className={
        "page" +
        (pageVisible ? "" : " no-print") +
        " " +
        (blue ? " weekly-blue-page" : "") +
        " " +
        (cropped ? " weekly-cropped-page" : "")
      }
    >
      <PageHider pageVisible={pageVisible} setPageVisible={setPageVisible} />
      {!pageVisible && <div className="page-overlay"></div>}
      {children}
    </div>
  );
};
