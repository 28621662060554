import { Tabs, Tab, Button, Intent } from "@blueprintjs/core";
import { query, collection, orderBy, limit, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";
import { fireStore } from "../firebase";
import { BulletinTable } from "./table";
import { TemplateEditor } from "./template-editor";

export const Templates = () => {
  const [templates, setTemplates] = useState<any[]>([]);
  const [selectedTabId, setSelectedTabId] = useState<string | null>(null);
  const readDocs = async () => {
    const q = query(collection(fireStore, "NS_BULLETIN_TEMPLATE"), limit(20));

    getDocs(q).then((querySnapshot) => {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        list.push({
          name: doc.data().name,
          id: doc.id,
          template: doc.data().template,
        });
      });
      setTemplates(list);
      setSelectedTabId(list[0].id);
    });
  };
  useEffect(() => {
    readDocs();
  }, []);
  if (selectedTabId === null) return null;
 
  return (
    <div className="container">
      
      <Tabs
        id="BulletinTabs"
        vertical
        onChange={(val: string) => setSelectedTabId(val)}
        selectedTabId={selectedTabId}
      >
        {templates.map((t) => (
          <Tab
          key={t.id}
            id={t.id}
            title={t.name}
            className="page-tab"
            panel={<TemplateEditor id={t.id} template={JSON.parse(t.template)} />}
          />
        ))}
      </Tabs>
    </div>
  );
};
