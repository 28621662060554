import { TextArea } from "@blueprintjs/core";
import { DailyInstantECharts } from "../charts/daily-instant";
import { useEffect, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { PageHider } from "../components/PageHider";
import { WeeklyPage } from "../components/WeeklyPage";

export const Page11 = ({
  pageIndex,
  subIndex,
  registerPageIndex,
  weekData,
  onPageDataUpdated,
}: any) => {
  const [comment, setComment] = useState(weekData?.page11?.comment ?? "");

  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Haftalık hisse değerlendirmesi");
  }, []);
  return (
    <WeeklyPage pageId={`page-${pageIndex}-${subIndex}`} cropped>
      <div
        style={{
          fontSize: 40,
          color: "#fff",
          textAlign: "center",
          fontWeight: "bold",
          padding: 10,
        }}
      >
        HAFTALIK HİSSE DEĞERLENDİRME
      </div>
      <ul style={{ color: "#fff", fontSize: 25, lineHeight: "48px" }}>
        <li>
          Günlük ilk 10’lardan farklı olarak, haftalıklarda en yüksek ilk 7
          Sentiment puanlı hisseler üzerinden giden bir modelimiz var.
        </li>
        <li>
          Bu bültenimizde Bist 30 ilk 7’lisi ve Bist 70 ilk 7’lisini sizler ile
          paylaşacağız.
        </li>
        <li>
          Ayrıca her hafta için Sentiment tarafında toparlanma görünen
          hisselerden paylaşımlarımız olacak.
        </li>
        <li>
          Bunlar arasında kısalan kırmızı barlar, kırmızıdan mavi bara geçenler
          gibi çeşitli örnekler göreceksiniz.
        </li>
        <li>
          Son olarak haftalık iyi görünen sektörlerden 2-3 tanesini
          yorumlayacağız.
        </li>
        <TextArea
          className="no-print"
          placeholder="Not girebilirisiniz"
          defaultValue={comment}
          style={{width:"100%",lineHeight:"30px",height:"150px"}}
          onChange={(e) => {
            onPageDataUpdated("page11", "comment", e.target.value);
            setComment(e.target.value);
          }}
        ></TextArea>
        {comment && comment !== "" && (
          <li className="print-only-list-item">
            <span style={{ color: "red" }}>NOT:</span> <b>{comment}</b>
          </li>
        )}
      </ul>
    </WeeklyPage>
  );
};
