import { Tabs, Tab } from "@blueprintjs/core"
import { query, collection, limit, getDocs } from "firebase/firestore"
import { title } from "process"
import { useEffect, useState } from "react"
import { fireStore } from "../firebase"
import { GlobalLangEditor } from "./global-lang-editor"
import { TemplateEditor } from "./template-editor"

export const GlobalLangList = () => {
  const [langList, setLangList] = useState<any>([])
  useEffect(() => {
    const q = query(collection(fireStore, "NS_BULLETIN_GLOBAL_LANG_LIST"), limit(20));

    getDocs(q).then((querySnapshot) => {
      const list: any = [];
      querySnapshot.forEach((doc) => {
        list.push({
          name: doc.data().name,
          key: doc.data().key,
        });
      });
      setLangList(list)

    })
  }, [])
  const [selectedTabId, setSelectedTabId] = useState("tr")
  return <div className="container"><Tabs
    id="GlobalLangTabs"
    vertical
    onChange={(val: string) => setSelectedTabId(val)}
    selectedTabId={selectedTabId}
  >
    {langList.map((t:any) => (
      <Tab
        key={t.key}
        id={t.key}
        title={t.name}
        className="page-tab"
        panel={<GlobalLangEditor lang={t.key} />}
      />
    ))}
  </Tabs>
  </div>

}