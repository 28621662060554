import moment from "moment";
import { useState } from "react";

export const previousFridayRange = (fixedDate: Date | null) => {
  if (fixedDate) {
    const range = moment(fixedDate);
    const pereviousFriday = moment(fixedDate).subtract(6, "hours");
    return { previousFriday: pereviousFriday, range };
  }
  const previousFriday = moment().day(moment().day() >= 5 ? 5 : -2);
  previousFriday.set({ hour: 18, minute: 0, second: 0, millisecond: 0 });
  const range = previousFriday
    .clone()
    .set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
  return { previousFriday, range };
};

export const beforePreviousFridayNight = (fixedDate: Date | null) => {
  if (fixedDate) {
    return moment(fixedDate);
  }
  const previousFriday = moment().day(moment().day() >= 5 ? 5 : -2);
  previousFriday.set({ hour: 23, minute: 59, second: 0, millisecond: 0 });
  return previousFriday;
};
