import { TextArea } from "@blueprintjs/core";
import { DailyInstantECharts } from "../charts/daily-instant";
import { useEffect, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { PageHider } from "../components/PageHider";
import { WeeklyPage } from "../components/WeeklyPage";

export const Page13 = ({ pageIndex, subIndex, registerPageIndex }: any) => {
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Sentiment Algo'ya Nasıl Ulaşırım?");
  }, []);
  return (
    <WeeklyPage blue   pageId={`page-${pageIndex}-${subIndex}`}>
      <div
        style={{
          fontSize: 40,
          color: "#fff",
          textAlign: "center",
          fontWeight: "bold",
          padding: 10,
        }}
      >
        Sentiment Algo'ya Nasıl Ulaşırım?
      </div>
      <div style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            fontSize: 30,
            color: "#fff",
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "30px",
            marginBottom: "80px",
            lineHeight: "65px",
          }}
        >
          <div><a style={{color:"#fff"}} href="https://sentimentalgo.com" target="_blank" >sentimentalgo.com</a> adresine gir, kayıt ol. Panele gir.</div>
          <div>Abonelikler için : <a style={{color:"#fff"}} href="https://sentimentalgo.com/paketler" target="_blank" >sentimentalgo.com</a></div>
          <div>
            Tüm sorularınız için info@zephlex.com & 0532 499 63 68 (whatsapp)
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "stretch",
            gap: 10,
            paddingLeft: 6,
          }}
        >
          <img style={{ width: 332 }} src="/images/weekly-final-image1.png" />
          <img style={{ width: 432 }} src="/images/weekly-final-image2.png" />

          <img style={{ width: 372 }} src="/images/weekly-final-image3.png" />
        </div>
      </div>
    </WeeklyPage>
  );
};
