import ReactEchartsCore from "echarts-for-react/lib/core";
import { BarChart, GaugeChart, LineChart } from "echarts/charts";
import {
  BrushComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from "echarts/components";
import * as echarts from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import {
  collection,
  getDocs,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import moment from "moment";
import "moment/locale/tr";
import { useEffect, useRef, useState } from "react";
import { fireStore } from "../../../../firebase";
import { useChartDateContext } from "../chart-date-context";
import { beforePreviousFridayNight } from "../util";
moment.locale("tr");

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  DataZoomSliderComponent,
  GridSimpleComponent,
  DataZoomInsideComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  BrushComponent,
  LegendComponent,
  MarkLineComponent,
  CanvasRenderer,
  LineChart,
  GaugeChart,
  BarChart,
]);

export const HourlySentimentMomentumECharts = (props: any) => {
  const echartsRef = useRef(null);
  const updateCallbacRef = useRef<((newBar: any) => void) | null>(null);
  const updater = (symbol: any, resol: any, updateCallback: any) => {
    updateCallbacRef.current = updateCallback;
  };
  const [options, setOptions] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [markers, setMarkers] = useState<any>(null);
  const [lastSentiment, setLastSentiment] = useState<any>(null);
  const [lastPrice, setlastPrice] = useState<any>(null);

  const getChartOptions = (forceMarkers?: any) => {
    if (!data) return;
    return {
      color: ["#1d9afb", "#40b74d", "#ff0000"],
      grid: [
        {
          left: "2",
          right: "6%",
          height: "40%",
        },
        {
          left: "2%",
          right: "6%",
          top: "70%",
          height: "23%",
        },
      ],
      xAxis: [
        {
          type: "category",
          axisLabel: {
            color: "#ddd",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
        },
        {
          type: "category",
          axisLabel: {
            color: "#ddd",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
          gridIndex: 1,
        },
      ],

      visualMap: [
        {
          show: false,
          pieces: [
            {
              gt: 0,
              lte: 26,
              color: "#1d9afb",
            },
            {
              gt: -26,
              lte: 0,
              color: "#ff0000",
            },
          ],
        },
      ],
      yAxis: [
        {
          type: "value",
          min: -25,
          max: 25,
          axisLabel: {
            color: "#ddd",
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
        },
        {
          axisLabel: {
            color: "#ddd",
          },
          min: "dataMin",
          max: "dataMax",
          type: "value",
          boundaryGap: [0, "100%"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
        },
        {
          min: "dataMin",
          max: "dataMax",
          axisLabel: {
            color: "#ddd",
          },
          type: "value",
          boundaryGap: [0, "100%"],
          splitLine: {
            show: true,
            lineStyle: {
              color: "#242b3e",
            },
          },
          scale: true,
          gridIndex: 1,
        },
      ],

      legend: {
        icon: "circle",
        top: 5,
        textStyle: {
          color: "#fff",
        },
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "cross",
        },
      },
      series: [
        {
          name: "Sentiment",
          type: "bar",
          lineStyle: {
            color: "#1d9afb",
          },
          showSymbol: false,
          hoverAnimation: true,
          data: data.sentiment,
        },
        {
          lineStyle: { color: "rgb(64, 183, 77)" },

          name: "Endeks",
          type: "line",
          showSymbol: false,
          hoverAnimation: true,
          yAxisIndex: 1,
          data: data.index,
        },
        {
          name: "Momentum",
          type: "bar",
          xAxisIndex: 1,
          yAxisIndex: 2,
          data: data.momentum,
          lineStyle: {
            color: "#1d9afb",
          },
        },
      ],
    };
  };
  const markerFormatter = (value: any) => {
    return parseFloat(value.value).toFixed(2);
  };
  const updateMarkers = () => {
    const newMarkers: any = [];
    if (lastSentiment) {
      newMarkers.push({
        name: "destek",
        yAxis: lastSentiment.value[1],
        lineStyle: {
          width: 0,
        },
        label: {
          formatter: markerFormatter,
          backgroundColor: "#2266f9",
        },
      });
    }
    if (props.marker1 != null && props.marker1 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker1,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker2 != null && props.marker2 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker2,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker3 != null && props.marker3 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker3,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker4 != null && props.marker4 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker4,
        label: {
          formatter: markerFormatter,
        },
      });

    setMarkers(newMarkers);
    setOptions(getChartOptions());
    setTimeout(() => {
      const echartCurrent: any = echartsRef.current;

      if (echartCurrent) {
        echartCurrent
          .getEchartsInstance()
          .setOption(getChartOptions(newMarkers), true);
      }
    }, 100);
  };
  useEffect(() => {
    updateMarkers();
  }, [
    props.marker1,
    props.marker2,
    props.marker3,
    props.marker4,
    lastSentiment,
  ]);

  const { chartDate } = useChartDateContext();

  useEffect(() => {
    const previousFriday = beforePreviousFridayNight(chartDate);

    const q = query(
      collection(fireStore, "HIS1"),
      orderBy("dateTime", "desc"),
      where("dateTime", "<=", previousFriday.toDate()),
      limit(90)
    );

    getDocs(q).then((querySnapshot: any) => {
      var sentimentData: Object[] = [];
      var sentiment2Data: Object[] = [];

      var indexData: Object[] = [];
      var momentumData: Object[] = [];

      querySnapshot.forEach((doc: any) => {
        const dateVal = moment
          .unix(doc.data().dateTime.seconds)
          .format("YYYY/MM/DD HH:mm");
        sentimentData.push({
          name: "sentiment",
          value: [dateVal, doc.data().sentiment],
          date: doc.data().dateTime.seconds,
        });

        indexData.push({
          name: "index",
          value: [dateVal, doc.data().index_value],
          date: doc.data().dateTime.seconds,
        });
        momentumData.push({
          name: "momentum",
          value: [dateVal, doc.data().momentum],
          date: doc.data().dateTime.seconds,
        });
      });
      indexData = indexData.reverse();
      sentimentData = sentimentData.reverse();
      momentumData = momentumData.reverse();
      setLastSentiment(sentimentData[sentimentData.length - 1]);
      setlastPrice(indexData[indexData.length - 1]);

      setData({
        sentiment: sentimentData,
        momentum: momentumData,
        index: indexData,
      });
      setOptions(getChartOptions());
    });
  }, [chartDate]);
  if (!data || !data.sentiment) return null;

  return (
    <ReactEchartsCore
      echarts={echarts}
      ref={echartsRef}
      style={{
        height: "560px",
        width: "100%",
        marginBottom: 0,
        border: "1.5px solid #000",
      }}
      option={options ?? getChartOptions()}
    />
  );
};
