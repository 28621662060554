import {
  Button,
  Callout,
  Card,
  FileInput,
  FormGroup,
  HTMLSelect,
  InputGroup,
  Intent,
  Radio,
  RadioGroup,
  TextArea,
} from "@blueprintjs/core";
import axios from "axios";
import { useState } from "react";
import { auth } from "../../firebase";
import { Select2 } from "@blueprintjs/select";

export const Speak = () => {
  const [text, setText] = useState<string>("");
  const [pending, setPending] = useState<boolean>(false);
  const [videoUrl, setVideoUrl] = useState<string>("");
  const [audioUrl, setAudioUrl] = useState<string>("");
  const [openDelay, setOpenDelay] = useState<string>("");
  const [mediaType, setMediaType] = useState<string>("default");
  const [background, setBackground] = useState<any>(null);
  const [apiError, setApiError] = useState<string>("");
  const [voiceName, setVoiceName] = useState<string>("revoicer");
  const [videoSize, setVideoSize] = useState<string>("1920x1080");
  const [openerType, setOpenerType] = useState<string>("daily-bulletin");

  const sendData = async () => {
    setVideoUrl("");
    setAudioUrl("");
    setApiError("");
    setPending(true);
    setTimeout(async () => {
      const token = await auth.currentUser?.getIdToken();
      const formData = new FormData();
      formData.append("text", text);
      formData.append("mediaType", mediaType);
      formData.append("openDelay", openDelay);
      formData.append("voiceName", voiceName);
      formData.append("videoSize", videoSize);
      formData.append("openerType", openerType);

      if (background) formData.append("background", background);
      axios
        .post(`https://revoicer-api-ti3ljgilca-nw.a.run.app/speak`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response: any) => {
          if (response.data?.error) {
            setApiError(response.data?.error);
          }
          if (response.data?.videoLink) {
            setVideoUrl(response.data?.videoLink);
          }
          if (response.data?.audioLink) {
            setAudioUrl(response.data?.audioLink);
          }
        })
        .catch((err) => {
          console.log(err);
          setApiError(err.message);
        })
        .finally(() => {
          setPending(false);
        });
    }, 0);
  };
  return (
    <Card>
      <div
        style={{
          paddingTop: 50,
          display: "flex",
          gap: 10,
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <FormGroup
          style={{ width: "100%", fontFamily: "Arial" }}
          label="Seslendirilecek Metin"
          labelFor="voice-text"
        >
          <TextArea
            id="voice-text"
            value={text}
            fill
            growVertically
            large
            onChange={(e) => {
              setText(e.target.value);
            }}
          />
        </FormGroup>
        <FormGroup label="Ses" labelFor="text-input">
          <HTMLSelect
            onChange={(e) => setVoiceName(e.target.value)}
            name="voice-name"
          >
            <option value="revoicer">Revoicer</option>
            <option value="tr-TR-EmelNeural">Azure-Emel</option>
            <option value="tr-TR-AhmetNeural">Azure-Ahmet</option>
          </HTMLSelect>
        </FormGroup>
        <FormGroup label="Video Boyutu" labelFor="text-input">
          <HTMLSelect
            onChange={(e) => setVideoSize(e.target.value)}
            name="video-size"
          >
            <option value="1920x1080">1920x1080</option>
            <option value="1280x720">1280x720</option>
            <option value="640x480">640x480</option>
            <option value="320x240">320x240</option>
          </HTMLSelect>
        </FormGroup>
        {mediaType !== "opener" && (
          <FormGroup label="Seslendirme gecikme (sn)" labelFor="text-input">
            <InputGroup
              onChange={(e) => setOpenDelay(e.target.value)}
              value={openDelay.toString()}
            />
          </FormGroup>
        )}
        <RadioGroup
          label="Seçenekler"
          onChange={(e) => setMediaType(e.currentTarget.value)}
          selectedValue={mediaType}
        >
          <Radio label="Varsayılan arkaplan" value="default" />
          <Radio label="Özel arkaplan" value="upload-background" />
          <Radio label="Açılış Video" value="opener" />
        </RadioGroup>

        {mediaType === "upload-background" && (
          <FileInput
            text="Arkaplan seçin..."
            onChange={(e: any) => setBackground(e.target.files[0])}
          />
        )}
         {mediaType === "opener" && (
          <FormGroup label="Açılış Türü" labelFor="text-input">
          <HTMLSelect
            onChange={(e) => setOpenerType(e.target.value)}
            name="voice-name"
          >
            <option value="long-opener">Uzun Açılış</option>
            <option value="daily-bulletin">Günlük Bülten</option>
          </HTMLSelect>
        </FormGroup>
        )}
        {apiError && apiError !== "" && (
          <Callout intent={Intent.DANGER}>{apiError}</Callout>
        )}
        <Button disabled={pending} onClick={sendData}>
          Video İndir
        </Button>
      </div>
      <div
        style={{
          paddingTop: 50,
          display: "flex",
          gap: 20,
          flexDirection: "column",
        }}
      >
        {videoUrl && videoUrl !== "" && (
          <div>
            <video width="500" height="300" controls>
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        )}
        {videoUrl && videoUrl !== "" && (
          <a href={videoUrl} download target="_blank">
            Videoyu indir
          </a>
        )}
        {audioUrl && audioUrl !== "" && (
          <a href={audioUrl} download target="_blank">
            Seslendirmeyi indir
          </a>
        )}
      </div>
    </Card>
  );
};
