import moment from "moment";
//@ts-ignore
import trLocale from "moment/locale/tr";

import { useEffect, useState } from "react";
import ts from "typescript";
import { PageHider } from "../components/PageHider";
import { WeeklyPage } from "../components/WeeklyPage";

export const Page1 = ({pageIndex,subIndex,registerPageIndex}:any) => {
  const [date, setDate] = useState<string>("");

  useEffect(() => {
    moment.locale("tr", trLocale);
    const currentDate = moment();

    const previousFriday = moment().day(moment().day() >= 5 ? 5 : -2);
    const nextMonday=previousFriday.clone().add(3,"days").format("D MMMM");
    const nextFriday=previousFriday.clone().add(7,"days").format("D MMMM");

    const dateRange = `${nextMonday} - ${nextFriday}`;
    setDate(dateRange);
  }, []);
  useEffect(() => {
    registerPageIndex(pageIndex,subIndex,"Giriş")

  },[]);
  return (
    <WeeklyPage pageId={`page-${pageIndex}-${subIndex}`}>
      <div
        style={{
          fontSize: 55,
          color: "#fff",
          fontWeight: "bold",
          paddingLeft: 15,
          paddingTop: 15,
        }}
      >
        SENTİMENT ALGO HAFTALIK RAPOR
      </div>
      <div
        style={{
          fontSize: 50,
          color: "#fff",
          fontWeight: "bold",
          paddingLeft: 15,
        }}
      >
        {date}
      </div>
      <div
        style={{
          color: "#fff",
          fontSize: 20,
          position: "absolute",
          bottom: 0,
          padding: 15,
          fontWeight: "bold",
          backgroundColor: "rgba(0,0,0,0.6)",
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center"
        }}
      >
        <div
          style={{
            color: "#fff",
        
            padding: 15,
            width: "50%",
            fontWeight: "bold",
          }}
        >
          Bu raporda yer alan bilgiler Sentiment Algo verileri kullanılarak
          üretilmiştir. Yatırım danışmanlığı kapsamında değildir. Bu bülten
          sadece Sentiment Algo üyelerine özeldir.
        </div>
        <a style={{cursor:"pointer"}} target="_blank" href="https://zephlex.com"><img style={{height:"50px",borderRadius:"5px"}} src="/images/zephlex_logo.png"/></a>
      </div>
    </WeeklyPage>
  );
};
