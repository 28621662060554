import { useEffect, useState } from "react";
import { WeeklyPage } from "../components/WeeklyPage";
import { StockBar } from "../charts/stock-bar";
import { StockLine } from "../charts/stock-line";
import { TextArea } from "@blueprintjs/core";
import { Sectors } from "../charts/sectors";
import { SectorChart } from "../charts/sector-chart";

export const Page14 = ({
  list,
  title,
  stocks,
  onPageDataUpdated,
  weekData,
  pageIndex,
  subIndex,
  registerPageIndex,
  details,
  onSectorsChanged,
  sector1PeriodChanged,
  sector2PeriodChanged,
  sector3PeriodChanged,
}: any) => {
  const [comments, setComments] = useState<any>(
    weekData?.["page14"]?.comment ?? ""
  );
  const [sector1, setSector1] = useState<any>(weekData?.page14?.sector1 ?? "");
  const [sector2, setSector2] = useState<any>(weekData?.page14?.sector2 ?? "");
  const [sector3, setSector3] = useState<any>(weekData?.page14?.sector3 ?? "");

  const [sector1Period, setSector1Period] = useState<any>(
    weekData?.page14?.sector1Period ?? "weekly"
  );
  const [sector2Period, setSector2Period] = useState<any>(
    weekData?.page14?.sector2Period ?? "weekly"
  );
  const [sector3Period, setSector3Period] = useState<any>(
    weekData?.page14?.sector3Period ?? "weekly"
  );

  const [sector1Value, setSector1Value] = useState<any>(
    weekData?.page14?.sector1Value ?? ""
  );
  const [sector2Value, setSector2Value] = useState<any>(
    weekData?.page14?.sector2Value ?? ""
  );
  const [sector3Value, setSector3Value] = useState<any>(
    weekData?.page14?.sector3Value ?? ""
  );

  const onCommentChange = (value: string) => {
    setComments(value);
    onPageDataUpdated("page14", "comment", value);
  };
  useEffect(() => {
    registerPageIndex(pageIndex, subIndex, "Sektörel  Değerlendirme");
    if (sector1) {
      const sector1Temp = details.find((item: any) => item.symbol === sector1);
      if (sector1Temp) {
        setSector1Value(sector1Temp.perClose);
      }
    }
    if (sector2) {
      const sector = details.find((item: any) => item.symbol === sector2);
      if (sector) {
        setSector2Value(sector.perClose);
      }
    }
    if (sector3) {
      const sector = details.find((item: any) => item.symbol === sector3);
      if (sector) {
        setSector3Value(sector.perClose);
      }
    }
  }, []);

  return (
    <WeeklyPage cropped pageId={`page-${pageIndex}-${subIndex}`}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ width: "50%" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 10,
              gap: 10,
            }}
          >
            <div
              style={{
                color: "#fff",
                fontSize: 40,
                fontWeight: "bold",
                textAlign: "center",
                padding: 20,
                paddingBottom: 10,
              }}
            >
              SEKTÖREL DEĞERLENDİRME
            </div>
            <TextArea
              style={{ height: "300px" }}
              className="no-print"
              onChange={(e) => {
                onCommentChange(e.target.value);
              }}
              defaultValue={comments ?? ""}
            />
            <div
              className="no-print"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                gap: 10,
              }}
            >
              <div>
                <select
                  style={{ padding: 10 }}
                  defaultValue={weekData?.page14?.sector1 ?? ""}
                  onChange={(e) => {
                    onPageDataUpdated("page14", "sector1", e.target.value);
                    setSector1(e.target.value);
                    const sector = details.find(
                      (item: any) => item.symbol === e.target.value
                    );
                    if (sector) {
                      setSector1Value(sector.perClose);
                    }
                    onSectorsChanged([e.target.value, sector2, sector3]);
                  }}
                >
                  <option value="">(Seçiniz)</option>
                  {Object.keys(Sectors).map((item: string) => (
                    <option value={item}>{(Sectors as any)[item]}</option>
                  ))}
                </select>
                <select
                  style={{ padding: 10 }}
                  defaultValue={weekData?.page14?.sector1Period ?? ""}
                  onChange={(e) => {
                    onPageDataUpdated(
                      "page14",
                      "sector1Period",
                      e.target.value
                    );
                    setSector1Period(e.target.value);
                    sector1PeriodChanged(e.target.value)
                  }}
                >
                  <option value="weekly">Haftalık</option>
                  <option value="daily">Günlük</option>
                </select>
                <input
                  style={{ padding: 10 }}
                  type="text"
                  defaultValue={sector1Value}
                  onChange={(e) => {
                    setSector1Value(e.target.value);
                    onPageDataUpdated("page14", "sector1Value", e.target.value);
                  }}
                />
              </div>
              <div>
                <select
                  style={{ padding: 10 }}
                  onChange={(e) => {
                    onPageDataUpdated("page14", "sector2", e.target.value);
                    setSector2(e.target.value);
                    const sector = details.find(
                      (item: any) => item.symbol === e.target.value
                    );
                    if (sector) {
                      setSector2Value(sector.perClose);
                    }
                    onSectorsChanged([sector1, e.target.value, sector3]);

                  }}
                  defaultValue={weekData?.page14?.sector2 ?? ""}
                >
                  <option value="">(Seçiniz)</option>

                  {Object.keys(Sectors).map((item: string) => (
                    <option value={item}>{(Sectors as any)[item]}</option>
                  ))}
                </select>
                <select
                  style={{ padding: 10 }}
                  defaultValue={weekData?.page14?.sector2Period ?? ""}
                  onChange={(e) => {
                    onPageDataUpdated(
                      "page14",
                      "sector2Period",
                      e.target.value
                    );
                    setSector2Period(e.target.value);
                    sector2PeriodChanged(e.target.value)

                  }}
                >
                  <option value="weekly">Haftalık</option>
                  <option value="daily">Günlük</option>
                </select>
                <input
                  style={{ padding: 10 }}
                  type="text"
                  defaultValue={sector2Value}
                  onChange={(e) => {
                    setSector2Value(e.target.value);
                    onPageDataUpdated("page14", "sector2Value", e.target.value);
                  }}
                />
              </div>
              <div>
                <select
                  defaultValue={weekData?.page14?.sector3 ?? ""}
                  style={{ padding: 10 }}
                  onChange={(e) => {
                    onPageDataUpdated("page14", "sector3", e.target.value);
                    setSector3(e.target.value);
                    const sector = details.find(
                      (item: any) => item.symbol === e.target.value
                    );
                    if (sector) {
                      setSector3Value(sector.perClose);
                    }
                    onSectorsChanged([sector1, sector2, e.target.value]);
                  }}
                >
                  <option value="">(Seçiniz)</option>

                  {Object.keys(Sectors).map((item: string) => (
                    <option value={item}>{(Sectors as any)[item]}</option>
                  ))}
                </select>
                <select
                  style={{ padding: 10 }}
                  defaultValue={weekData?.page14?.sector3Period ?? ""}
                  onChange={(e) => {
                    onPageDataUpdated(
                      "page14",
                      "sector3Period",
                      e.target.value
                    );
                    setSector3Period(e.target.value);
                    sector3PeriodChanged(e.target.value)

                  }}
                >
                  <option value="weekly">Haftalık</option>
                  <option value="daily">Günlük</option>
                </select>
                <input
                  style={{ padding: 10 }}
                  type="text"
                  defaultValue={sector3Value}
                  onChange={(e) => {
                    setSector3Value(e.target.value);
                    onPageDataUpdated("page14", "sector3Value", e.target.value);
                  }}
                />
              </div>
            </div>
            <div
              className="print-only-block"
              style={{
                color: "#fff",
                fontSize: 30,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {comments
                ?.split("\n")
                .filter((item: string) => item.trim() !== "")
                .map((item: string) => (
                  <div>{item}</div>
                ))}
            </div>
            <div
              className="print-only-block"
              style={{
                color: "#fff",
                fontSize: 30,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              {sector1 &&
                sector1 !== "" &&
                sector1Value &&
                sector1Value != "" && (
                  <div
                    className={
                      sector1Value > 0
                        ? "weekly-positive-sector"
                        : sector1Value < 0
                        ? "weekly-negative-sector"
                        : ""
                    }
                  >
                    {sector1} -{" "}
                    <span className="weekly-sector-perf">
                      %{parseFloat(sector1Value)?.toFixed(2)}
                    </span>
                  </div>
                )}
              {sector2 &&
                sector2 !== "" &&
                sector2Value &&
                sector2Value != "" && (
                  <div
                    className={
                      sector2Value > 0
                        ? "weekly-positive-sector"
                        : sector2Value < 0
                        ? "weekly-negative-sector"
                        : ""
                    }
                  >
                    {sector2} -{" "}
                    <span className="weekly-sector-perf">
                      %{parseFloat(sector2Value)?.toFixed(2)}
                    </span>
                  </div>
                )}
              {sector3 &&
                sector3 !== "" &&
                sector3Value &&
                sector3Value != "" && (
                  <div
                    className={
                      sector3Value > 0
                        ? "weekly-positive-sector"
                        : sector3Value < 0
                        ? "weekly-negative-sector"
                        : ""
                    }
                  >
                    {sector3} -{" "}
                    <span className="weekly-sector-perf">
                      %{parseFloat(sector3Value)?.toFixed(2)}
                    </span>
                  </div>
                )}
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            gap: 10,
            flexDirection: "column",
            backgroundColor: "#fff",
            width: "50%",
            height: "100%",
            padding: "10px 10px",
          }}
        >
          {sector1 !== "" && (
            <SectorChart period={sector1Period} sector={sector1} />
          )}
          {sector2 !== "" && (
            <SectorChart period={sector2Period} sector={sector2} />
          )}
          {sector3 !== "" && (
            <SectorChart period={sector3Period} sector={sector3} />
          )}
        </div>
      </div>
    </WeeklyPage>
  );
};
