import ReactEchartsCore from "echarts-for-react/lib/core";
import * as echarts from "echarts/core";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import {
  BrushComponent,
  DatasetComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
  DataZoomSliderComponent,
  GridComponent,
  GridSimpleComponent,
  LegendComponent,
  MarkLineComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import 'moment/locale/tr'
import { GaugeChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import { SentimentEndeksLabel } from "../../ata/imkb";
import { query, collection, orderBy, limit, getDocs } from "firebase/firestore";
import React from "react";
import { fireStore } from "../../../../firebase";
moment.locale('tr')

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  DataZoomComponent,
  DatasetComponent,
  DataZoomSliderComponent,
  GridSimpleComponent,
  DataZoomInsideComponent,
  VisualMapComponent,
  VisualMapContinuousComponent,
  VisualMapPiecewiseComponent,
  BrushComponent,
  LegendComponent,
  MarkLineComponent,
  CanvasRenderer,
  LineChart,
  GaugeChart,
  BarChart,
]);

export const Osilator = (props: any) => {
  const echartsRef = useRef(null);
  const updateCallbacRef = useRef<((newBar: any) => void) | null>(null);
  const updater = (symbol: any, resol: any, updateCallback: any) => {
    updateCallbacRef.current = updateCallback;
  };
  const [options, setOptions] = useState<any>(null);
  const [data, setData] = useState<any>(null);
  const [markers, setMarkers] = useState<any>(null);
  const [lastSentiment, setLastSentiment] = useState<any>(null);
  const [lastPrice, setlastPrice] = useState<any>(null);

  const getChartOptions = (forceMarkers?: any) => {
    if (!data) return;
    return {
        grid: {
          left: "2%",
          right: "6%",
        },
        color: ["#ff0000", "#1d9afb"],
        xAxis: {
          type: "category",
          axisLabel: {
            color: "#ddd" ,
          },
          splitLine: {
            show: true,
            lineStyle: {
              color:  "#242b3e",
            },
          },
        },

        yAxis: [
          {
            type: "value",
            min: -20,
            max: 20,
            boundaryGap: [0, "100%"],
            axisLabel: {
              color: "#ddd" ,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: "#242b3e" ,
              },
            },
          },
          {
            min: "dataMin",
            max: "dataMax",
            type: "value",
            boundaryGap: [0, "100%"],
            axisLabel: {
              color: "#ddd",
            },
            splitLine: {
              show: true,
              lineStyle: {
                color:"#242b3e" ,
              },
            },
          },
        ],
        brush: {
          xAxisIndex: "all",
          brushLink: "all",
          toolbox: ["rect", "polygon", "lineX", "lineY", "keep", "clear"],
          brushStyle: {
            color: "rgba(120,140,180,0.4)",
          },
          outOfBrush: {
            colorAlpha: 0.3,
          },
        },

        legend: {
          icon: "circle",
          top: 5,
          textStyle: {
            color: "#ddd" ,
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },
        visualMap: {
          dimension: 1,
          seriesIndex: 0,
          show: false,
          pieces: [
            {
              lt: 0,
              gt: -100,
              color: "#ff0000",
            },
            {
              gt: 0,
              lte: 100,
              color: "rgb(64, 183, 77)",
            },
          ],
        },
        series: [
          {
            name: "Osilatör",
            type: "line",
            lineStyle: {},
            showSymbol: false,
            hoverAnimation: true,
            data: data.sentiment,
          },
          {
            lineStyle: { color: "#1d9afb" },

            name: "Endeks",
            type: "line",
            showSymbol: false,
            hoverAnimation: true,
            yAxisIndex: 1,
            data: data.index,
          },
        ],
      }
  };
  const markerFormatter = (value: any) => {
    return parseFloat(value.value).toFixed(2);
  };
  const updateMarkers = () => {
    const newMarkers: any = [];
    if (lastSentiment) {
      newMarkers.push({
        name: "destek",
        yAxis: lastSentiment.value[1],
        lineStyle: {
            width:0
        },
        label: {
          formatter: markerFormatter,
          backgroundColor: "#2266f9",
        },
      });
    }
    if (props.marker1 != null && props.marker1 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker1,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker2 != null && props.marker2 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker2,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker3 != null && props.marker3 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker3,
        label: {
          formatter: markerFormatter,
        },
      });
    if (props.marker4 != null && props.marker4 !== "")
      newMarkers.push({
        name: "destek",
        yAxis: props.marker4,
        label: {
          formatter: markerFormatter,
        },
      });

    setMarkers(newMarkers);
    setOptions(getChartOptions());
    setTimeout(() => {
      const echartCurrent: any = echartsRef.current;

      if (echartCurrent) {
        echartCurrent
          .getEchartsInstance()
          .setOption(getChartOptions(newMarkers), true);
      }
    }, 100);
  };
  useEffect(() => {
    updateMarkers();
  }, [props.marker1, props.marker2, props.marker3, props.marker4,lastSentiment]);
  useEffect(() => {
    const q = query(
      collection(fireStore, "OS1"),
      orderBy("dateTime", "desc"),
      limit(280)
    );

    getDocs(q).then((querySnapshot: any) => {
      var sentimentData: Object[] = [];
      var indexData: Object[] = [];

      querySnapshot.forEach((doc: any) => {
        const dateVal = moment
          .unix(doc.data().dateTime.seconds)
          .format("YYYY/MM/DD HH:mm");
        sentimentData.push({
          name: "osilator",
          value: [dateVal, doc.data().osilator],
          date: doc.data().dateTime.seconds,
        });
        indexData.push({
          name: "index",
          value: [dateVal, doc.data().index_value],
          date: doc.data().dateTime.seconds,
        });
      });
      indexData = indexData.reverse();
      sentimentData = sentimentData.reverse();
      setLastSentiment(sentimentData[sentimentData.length - 1]);
      setlastPrice(indexData[indexData.length - 1]);

      setData({
        sentiment: sentimentData,
        index: indexData,
      });
      setOptions(getChartOptions());
      
    });
  }, []);
  if (!data || !data.sentiment) return null;

  return (
    <ReactEchartsCore
      echarts={echarts}
      ref={echartsRef}
      style={{
        height: "560px",
        width: "100%",
        marginBottom: 0,
        border: "1.5px solid #000",
      }}
      option={options ?? getChartOptions()}
    />
  );
};
