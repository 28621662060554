import { useEffect, useState } from "react";

export const PageIndex = ({ pageIndexes, goToPage }: any) => {
  const [pages, setPages] = useState<any>([]);
  useEffect(() => {
    if (!pageIndexes) return;
    const list = Object.keys(pageIndexes).map((key: any) => {
      return pageIndexes[key];
    });
    list.sort((a: any, b: any) => {
      if (a.pageIndex === b.pageIndex) return a.subIndex - b.subIndex;
      return a.pageIndex - b.pageIndex;
    });
    setPages(list);
  }, [pageIndexes]);
  return (
    <div className="weekly-page-index no-print">
      <div
        style={{ width: 250, height: "calc(100% - 30px)", overflowY: "scroll" }}
      >
        {pages.map((page: any, index: number) => {
          return (
            <div
              onClick={() => {
                goToPage(`page-${page.pageIndex}-${page.subIndex}`);
              }}
            >
              {/*  */}
              {index + 1} - {page.title}
            </div>
          );
        })}
      </div>
      {/*  <div
        onClick={() => {
          props.goToPage(1);
        }}
      >
        1 - Giriş
      </div>
      <div
        onClick={() => {
          props.goToPage(2);
        }}
      >
        2 - Haftalık Değerlendirme
      </div>
      <div
        onClick={() => {
          props.goToPage(3);
        }}
      >
        3 - Günlük Sentiment
      </div>
      <div
        onClick={() => {
          props.goToPage(4);
        }}
      >
        4 - Genel Sentiment Değerlendirmesi
      </div>
      <div
        onClick={() => {
          props.goToPage(5);
        }}
      >
        5 - Günlük Sentiment-Mom
      </div>
      <div
        onClick={() => {
          props.goToPage(6);
        }}
      >
        6 - Saatlik Sentiment
      </div>
      <div
        onClick={() => {
          props.goToPage(7);
        }}
      >
        7 - Osilatör
      </div>
      <div
        onClick={() => {
          props.goToPage(8);
        }}
      >
        8 - Haftalık Değerlendirme Sonuç
      </div>
      <div
        onClick={() => {
          props.goToPage(9);
        }}
      >
        9 - Bu verilere nasıl ulaşırım
      </div>
      <div
        onClick={() => {
          props.goToPage(10);
        }}
      >
        10 - Özelleştirilmiş anasayfa
      </div>
      <div
        onClick={() => {
          props.goToPage(11);
        }}
      >
        11 - Haftalık Hisse Değerlendirme
      </div>
      <div
        onClick={() => {
          props.goToPage(12);
        }}
      >
        12 - Bist 30 Haftalık Yedili
      </div>
      {props.pageSlot1?.map((page: any) => {
        return (
          <div
            onClick={() => {
              props.goToPage(page.pageIndex);
            }}
          >
            {page.pageIndex} - {page.title}
          </div>
        );
      })}
      {props.pageSlot2?.map((page: any) => {
        return (
          <div
            onClick={() => {
              props.goToPage(page.pageIndex);
            }}
          >
            {page.pageIndex} - {page.title}
          </div>
        );
      })} */}
    </div>
  );
};
