import { Card } from "@blueprintjs/core";
import { useEffect, useRef, useState } from "react";
import {
  createAddRowsComponent,
  DataSheetGrid,
  DataSheetGridRef,
  keyColumn,
  textColumn,
} from "react-datasheet-grid";
import "react-datasheet-grid/dist/style.css";
import "react-datasheet/lib/react-datasheet.css";
import { Colorizer } from "./colorizer";
const AddRows = createAddRowsComponent({
  button: "Ekle",
  unit: "satır",
});

export const BulletinTable = (props: any) => {
  const ref = useRef<DataSheetGridRef | null>(null);
  const [tableRef, setTableRef] = useState<any>(null);
  const [tableSelection, setTableSelection] = useState<any>(null);
  const [data, setData] = useState(
    Array(props.rowCount)
      .fill(0)
      .map((_, rowIndex) => {
        const row: any = {};
        props.cols.forEach((col: any, colIndex: any) => {
          if (props?.fillData?.[rowIndex]?.[col.name]) {
            row[col.name] = props.fillData[rowIndex][col.name];
            return;
          } else row[col.name] = "";
        });
        return row;
      })
  );
  const [styleData, setStyleData] = useState<any>([]);
  const colorizeData = (selection: any, color: string) => {
    for (let i = selection.min.row; i <= selection.max.row; i++) {
      for (let j = selection.min.col; j <= selection.max.col; j++) {
        if (color === "white") {
          delete styleData[i][j];
        } else {
          if (!styleData[i]) styleData[i] = {};
          styleData[i][j] = color;
        }
      }
    }
    setStyleData({ ...styleData });
    props.updateDocumentForm(props.dataName, "style", styleData);
  };
  useEffect(() => {
    if (props.initialData?.["data"]) {
      setData(props.initialData?.["data"]);
    }
    if (props.initialData?.["style"]) {
      setStyleData(props.initialData?.["style"]);
    }
  }, [props.initialData]);

  const columns: any = props.cols.map((col: any) => {
    return {
      ...keyColumn(col.name, textColumn),
      title: col.title,
      key: col.name,
      active: col.active === false ? false : true,
      disabled: col.disabled === true ? true : false,
      maxWidth: col.maxWidth,
    };
  });

  return (
    <Card style={{ width: "100%", flexGrow: 1 }}>
      <div>
        <Colorizer
          colorizeData={colorizeData}
          tableSelection={tableSelection}
          tableRef={tableRef}
        />
      </div>
      <div style={{ width: "100%" }}>
        <DataSheetGrid
          onSelectionChange={(data) => {
            if (data?.selection) {
              setTableSelection(data);
            }
          }}
          cellClassName={({ rowData, columnId, rowIndex }) => {
            let cls = "dsg-cell";

            const colIndex = props.cols.findIndex(
              (col: any) => col.name === columnId
            );
            const col = props.cols.find((col: any) => col.name === columnId);

            if (styleData[rowIndex] && styleData[rowIndex][colIndex]) {
              cls += " " + styleData[rowIndex][colIndex] + "-cell";
            } else {
              if (col?.autoColorize) {
                if (columnId) {
                  if ((rowData as any)[columnId]) {
                    let cellData = (rowData as any)[columnId]
                      .replace("%", "")
                      .replace(",", ".");
                    try {
                      const floatVal = parseFloat(cellData);
                      if (floatVal < 0) {
                        cls += " red-cell";
                      } else if (floatVal > 0) {
                        cls += " green-cell";
                      }
                    } catch (err) {}
                  }
                }
              }
            }

            return cls;
          }}
          value={data}
          onChange={(data) => {
            setData(data);
            props.updateDocumentForm(props.dataName, "data", data);
          }}
          columns={columns}
          addRowsComponent={AddRows}
        />
      </div>
    </Card>
  );
};
