import { useEffect, useState } from "react";
import { WeeklyPage } from "../components/WeeklyPage";
import { StockBar } from "../charts/stock-bar";
import { StockLine } from "../charts/stock-line";
import { TextArea } from "@blueprintjs/core";
import moment from "moment";
import {
  query,
  collection,
  where,
  limit,
  getDocs,
  orderBy,
} from "firebase/firestore";
import { fireStore } from "../../../../firebase";
import { update } from "lodash";
import { use } from "echarts";
import { previousFridayRange } from "../util";
import { useChartDateContext } from "../chart-date-context";

export const PageStockDetails = ({
  list,
  data,
  incoming,
  stock,
  details,
  pageId,
  onPageDataUpdated,
  weekData,
  pageIndex,
  subIndex,
  registerPageIndex,
}: any) => {
  const save_key = `stock_${list}_${
    incoming ? "incoming" : "outgoing"
  }_${stock}`;
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState<any>(
    weekData?.[save_key]?.comment ?? ""
  );

  const [openDate, setOpenDate] = useState(
    weekData?.[save_key]?.openDate ?? ""
  );
  const [openPrice, setOpenPrice] = useState(
    weekData?.[save_key]?.openPrice ?? ""
  );
  const [price, setPrice] = useState(weekData?.[save_key]?.price ?? "");
  const [sentiment, setSentiment] = useState(
    weekData?.[save_key]?.sentiment ?? ""
  );
  const [order, setOrder] = useState(weekData?.[save_key]?.order ?? "0");
  const [exitPrice, setExitPRice] = useState(
    weekData?.[save_key]?.exitPrice ?? "0"
  );

  const [lastClose, setLastClose] = useState(
    weekData?.[save_key]?.lastClose ?? "0"
  );
  const { chartDate } = useChartDateContext();

  const [stockData, setStockData] = useState<any>(null);
  useEffect(() => {
    registerPageIndex(
      pageIndex,
      subIndex,
      `Bist ${list} ${incoming ? "GİREN" : "ÇIKAN"} - ${stock}`
    );
  }, [pageIndex]);

  useEffect(() => {
    const { previousFriday, range } = previousFridayRange(chartDate);

    const q = query(
      collection(fireStore, "NS_LINES_" + list + "_1W_P99"),
      orderBy("dateTime", "desc"),
      where("dateTime", ">=", previousFriday.toDate()),
      where("dateTime", "<=", range.toDate()),
      where("symbol", "==", stock),

      limit(1)
    );

    getDocs(q).then((querySnapshot: any) => {
      const doc = querySnapshot.docs[0];
      const data = doc?.data();
      if (!doc) return;

      if (!weekData?.[save_key]?.exitPrice) {
        setExitPRice(details?.close ?? "");
        onPageDataUpdated(save_key, "exitPrice", details?.close ?? "");
      }

      if (!weekData?.[save_key]?.openDate) {
        let date = "";
        if (details?.entryDate) date = details?.entryDate;
        else if (data?.openDate) date = data?.openDate;
        else date = moment(data.dateTime.toDate()).format("DD.MM.YYYY");
        setOpenDate(date);
        onPageDataUpdated(save_key, "openDate", date);
      }
      if (!weekData?.[save_key]?.sentiment) {
        setSentiment(data.sentiment);
        onPageDataUpdated(save_key, "sentiment", data.sentiment ?? "");
      }
      if (!weekData?.[save_key]?.order) {
        setOrder(data.order);
        onPageDataUpdated(save_key, "order", data.order);
      }
      if (!weekData?.[save_key]?.openPrice) {
        let entry = "";
        if (details?.entry) entry = details?.entry;
        else if (data?.openPrice) entry = data?.openPrice;
        else entry = "";
        setOpenPrice(entry);
        onPageDataUpdated(save_key, "openPrice", entry);
      }
      if (!weekData?.[save_key]?.lastClose) {
        setLastClose(data.price ?? "");
        onPageDataUpdated(save_key, "lastClose", data.price);
      }
      setStockData(data);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    });
  }, [chartDate]);

  const getIndexText = (index: number) => {
    switch (index) {
      case 1:
        return "ilk";
      case 2:
        return "ikinci";
      case 3:
        return "üçüncü";
      case 4:
        return "dördüncü";
      case 5:
        return "beşinci";
      case 6:
        return "altıncı";
      case 7:
        return "yedinci";
      case 8:
        return "sekizinci";
      case 9:
        return "dokuzuncu";
      case 10:
        return "onuncu";
    }
    return "";
  };
  const generateAutoText = () => {
    let text = "";
    if (incoming) {
      if (subIndex == 0)
        text = `Bu hafta 7'li listeye ilk dahil olan hisse ${stock} oldu. `;
      else
        text = `Bu hafta 7'li listeye dahil olan ${getIndexText(
          subIndex + 1
        )} hisse ${stock} oldu. `;
    } else {
      text = `Bu hafta listeden çıkan ${getIndexText(
        subIndex + 1
      )} hisse ${stock} oldu. `;
    }
    if (stockData) {
      if (stockData.dailyChange > 0) {
        text += `${stock} sıralamada ${Math.abs(
          stockData.dailyChange
        )} basamak yükselerek, ${stockData.order}. sıraya yerleşti. `;
      } else if (stockData.dailyChange < 0) {
        text += `${stock} sıralamada ${Math.abs(
          stockData.dailyChange
        )} basamak geriledi ve ${stockData.order}. sıraya düştü. `;
      } else if (stockData.dailyChange == 0) {
        text += `${stock} sıralamasını kourudu ve ${stockData.order}. sırada yer aldı. `;
      }
    }
    text += "Sentiment Puanı:" + sentiment;
    return text;
  };
  const [autoText, setAutoText] = useState<any>(
    weekData?.[save_key]?.autoText ?? generateAutoText()
  );
  useEffect(() => {
    setAutoText(weekData?.[save_key]?.autoText ?? generateAutoText());
  }, [stockData]);
  if (loading) return null;
  return (
    <WeeklyPage pageId={`page-${pageIndex}-${subIndex}`}>
      <img className="sentiment-algo-logo" src="/images/sentiment_logo.png" />
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ width: "60%" }}>
          <div
            style={{
              color: "#fff",
              fontSize: 40,
              fontWeight: "bold",
              textAlign: "center",
              padding: 40,
              paddingBottom: 10,
            }}
          >
            BIST {list} {incoming ? "GİRENLER" : "ÇIKANLAR"} - {stock}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              padding: 20,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <StockBar symbol={stock} list={list} height={243} />
            <StockLine symbol={stock} list={list} height={243} />
          </div>
        </div>
        <div
          style={{
            width: "40%",
            backgroundColor: "#fff",
            padding: 20,
            fontSize: 22,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div style={{ marginBottom: 10, marginTop: 20 }}>
            <b>{stock}</b>
          </div>

          <div>
            Giriş Tarihi:
            <input
              className="no-print"
              defaultValue={openDate}
              onChange={(e) => {
                setOpenDate(e.target.value);
                onPageDataUpdated(save_key, "openDate", e.target.value);
              }}
            ></input>
            <span className="print-only-inline-block"> {openDate}</span>
          </div>
          <div>
            Giriş Fiyatı:
            <input
              defaultValue={openPrice}
              className="no-print"
              onChange={(e) => {
                setOpenPrice(e.target.value);
                onPageDataUpdated(save_key, "openPrice", e.target.value);
              }}
            ></input>{" "}
            <span className="print-only-inline-block"> {openPrice}</span>
          </div>
          {incoming && (
            <div>
              Son Kapanış:{" "}
              <input
                defaultValue={lastClose}
                className="no-print"
                onChange={(e) => {
                  setLastClose(e.target.value);
                  onPageDataUpdated(save_key, "lastClose", e.target.value);
                }}
              ></input>
              <span className="print-only-inline-block"> {lastClose}</span>
            </div>
          )}
          {!incoming && (
            <div>
              Çıkış Fiyatı:{" "}
              <input
                defaultValue={exitPrice}
                className="no-print"
                onChange={(e) => {
                  setExitPRice(e.target.value);
                  onPageDataUpdated(save_key, "exitPrice", e.target.value);
                }}
              ></input>
              <span className="print-only-inline-block"> {exitPrice}</span>
            </div>
          )}
          <div>
            Sentiment Puanı:{" "}
            <input
              defaultValue={sentiment}
              className="no-print"
              onChange={(e) => {
                setSentiment(e.target.value);
                onPageDataUpdated(save_key, "sentiment", e.target.value);
              }}
            ></input>
            <span className="print-only-inline-block"> {sentiment}</span>
          </div>
          <div style={{ marginBottom: 30 }}>
            Sentiment Sıralaması:{" "}
            <input
              defaultValue={order}
              className="no-print"
              onChange={(e) => {
                setOrder(e.target.value);
                onPageDataUpdated(save_key, "order", e.target.value);
              }}
            ></input>
            <span className="print-only-inline-block">{order}</span>
          </div>
          <div
            className="no-print"
            style={{
              display: "flex",
              justifyContent: "flex-end",
              fontSize: 12,
              cursor: "pointer",
            }}
            onClick={() => {
              setAutoText(generateAutoText());
              onPageDataUpdated(save_key, "autoText", generateAutoText());
            }}
          >
            Yenile
          </div>
          <TextArea
            style={{ flex: 1, maxHeight: "90px" }}
            className="no-print"
            defaultValue={autoText}
            value={autoText}
            onKeyDown={(e) => {
              if (e.ctrlKey && e.key === "Enter") {
                setAutoText(generateAutoText());
                onPageDataUpdated(save_key, "autoText", generateAutoText());
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              setAutoText(e.target.value);
              onPageDataUpdated(save_key, "autoText", e.target.value);
            }}
          />
          <TextArea
            style={{ flex: 1 }}
            className="no-print"
            defaultValue={comment}
            onChange={(e) => {
              setComment(e.target.value);
              onPageDataUpdated(save_key, "comment", e.target.value);
            }}
          />
          <div style={{ marginBottom: 15 }} className="print-only-block">
            {autoText}
          </div>
          {comment
            ?.split("\n")
            .filter((item: string) => item.trim() !== "")
            .map((line: any) => (
              <div style={{ marginBottom: 15 }} className="print-only-block">
                {line}
              </div>
            ))}
        </div>
      </div>
    </WeeklyPage>
  );
};
