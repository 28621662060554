import React, { useState } from "react";
import { InputGroup, FormGroup, Button, Card } from "@blueprintjs/core";
import axios from "axios";
import { useProvideAuth } from "../hooks/use-auth-provider";
import { auth } from "../firebase";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { userLogin } = useProvideAuth();

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const data = { username, password };
    try {
      userLogin(
        { email: username, password: password },
        async () => {
          const token=await auth.currentUser?.getIdToken();
          if(token)
          localStorage.setItem("auth_token", token)
        },
        () => {}
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{flex:1,display:"flex",justifyContent:"center",alignItems:"center",height:"100vh"}}>
    <div style={{ width: 400, margin: "auto" }}>
      <Card>
        <form onSubmit={handleSubmit}>
          <FormGroup
            label="E-mail"
            labelFor="username-input"
            labelInfo="(required)"
          >
            <InputGroup
              id="username-input"
              value={username}
              onChange={(event) => setUsername(event.target.value)}
            />
          </FormGroup>
          <FormGroup
            label="Parola"
            labelFor="password-input"
            labelInfo="(required)"
          >
            <InputGroup
              id="password-input"
              type="password"
              value={password}
              onChange={(event) => setPassword(event.target.value)}
            />
          </FormGroup>
          <Button type="submit" intent="primary" text="Oturum Aç" />
        </form>
      </Card>
    </div>
    </div>
  );
};

export default Login;
